import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { Line, LineChart } from 'recharts';

// Components
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControl, Grid, Input, TextField } from '@mui/material';
import {
	faFileCsv,
	faKeyboard,
	faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import { Loader } from '../../../../../../Components/Shared/Loader';

// Styles
import { styles } from './styles';
import { elementTypeToIconMap } from '../../../../../../services/tasks';
import CSSstyles from './styles.module.css';

// Services
import {
	createCSVTemplate,
	getFilesFromParsedCSV,
	parseCSVFile,
} from '../../../Examples/csv.services';
import { createValidatedExampleObjects } from '../../../Examples/examples.services';

// Redux
import {
	GET_EXAMPLE_FILE,
	GET_EXAMPLES,
} from '../../../../../../redux/examples.slice';
import {
	IMAGE_FILE_UPLOAD_ERROR,
	IMAGE_FILE_UPLOAD_SUCCESS,
} from '../../../../../../AlertsList/examplesAlerts';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../../../redux/alerts.slice';
import { CREATE_TASK_FILE } from '../../../../../../redux/tasks.slice';
import { CREATE_TEST, GET_TESTS } from '../../../../../../redux/testing.slice';
import { CustomPagination } from '../../../../../../Components/Shared/CustomPagination';
import { GET_INPUT_CATEGORIES } from '../../../../../../redux/schema.slice';
import { getLocalDateTime } from '../../../../../../services/date';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';
import { colors } from '../../../../../../consts/colors';
import { parseCSVTimeSeriesFile } from '../../csv.services';

export const CreateOrUpdateAITestingModal = ({
	open,
	setOpen,
	allColumns,
	schemaState,
	step = 0,
}) => {
	const dispatch = useDispatch();

	const hiddenFileInput = useRef(null);

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);
	const { currentExample: currentTestState } = useSelector(
		(state) => state.tests
	);
	const { categories: categoriesState } = useSelector((state) => state.schema);
	const { location: userLocationState } = useSelector((state) => state.user);

	const [creationStep, setCreationStep] = useState(step);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [tmpImage, setTmpImage] = useState({});
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const [csvReport, setCsvReport] = useState({});
	const [, /* localLoading */ setIsLocalLoading] = useState(false);
	const [manualElementCategories, setManualElementCategories] = useState({});
	const [manualTest, setManualTest] = useState({
		values: [],
		targets: [],
	});
	const [csvData, setCsvData] = useState([]);
	const [uploadStatus, setUploadStatus] = useState('Processing CSV file...');

	// CSV upload errors
	const [csvErrors, setCsvErrors] = useState([]);
	const [openCsvErrorModal, setOpenCsvErrorModal] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(4);

	useEffect(() => {
		if (!open) {
			setCreationStep(0);
			setFileToUpload(null);
		}

		if (allColumns.length > 0) {
			let tmp = manualTest;
			allColumns.forEach((column) => {
				if (column.field !== 'status' && column.field !== 'edit')
					if (
						column.valueType === 'generic_file' ||
						column.valueType === 'document_file' ||
						column.valueType === 'image_file' ||
						column.valueType === 'video_file' ||
						column.valueType === 'audio_file'
					) {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
									thumbnailUrl: '',
									tmpInfo: '',
									isLoading: false,
								},
							],
						};
					} else if (column.valueType === 'boolean') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: false,
								},
							],
						};
					} else if (column.valueType === 'datetime') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
								},
							],
						};
					} else {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
								},
							],
						};
					}
			});

			tmp = {
				...tmp,
				targets: schemaState.anomaly_types.map((anomaly) => ({
					element: anomaly.name,
					value: 0.0,
				})),
			};

			setManualTest(tmp);
		}
	}, [open]);

	useEffect(() => {
		setCsvReport(createCSVTemplate(allColumns));

		// todo: review if is necessary
		// if (!allColumns.length) return;
		// const categories = [];
		// const getCategoryValues = async (id, nodeType) => {
		// 	setIsLocalLoading(true);
		// 	const nodeTypeForRequest =
		// 		nodeType === 'metadata' ? nodeType : `${nodeType}s`;
		// 	const res = await requestFactory(
		// 		'get',
		// 		`/schema/${nodeTypeForRequest}/${id}/categories`,
		// 		null
		// 	);
		// 	categories.push({ field: id, categories: res });
		// 	setIsLocalLoading(false);
		// 	return {
		// 		id,
		// 		res,
		// 	};
		// };

		// allColumns.forEach((column) => {
		// 	if (column.valueType === 'Category') {
		// 		getCategoryValues(column.field, column.fieldType).then(() => {
		// 			setManualElementCategories(categories);
		// 		});
		// 	}
		// });
	}, [allColumns]);

	const handleClose = () => {
		setCreationStep(0);
		setOpen(false);
		setFileToUpload(null);
		setManualTest({
			values: [],
			targets: [],
		});
	};

	const handleAddExampleManually = () => {
		setCreationStep(1);
	};

	// const handleChangeCurrentRowStatus = (e) => {
	// 	setManualTest({ ...manualTest, labeling_status: e.target.value });
	// };

	// const handleChangemanualTestDate = (value, name) => {
	// 	setManualTest({
	// 		...manualTest,
	// 		[name]: { fieldType: manualTest[name].fieldType, value },
	// 	});
	// };

	useEffect(() => {
		if (Object.keys(tmpImage).length > 0)
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === tmpImage.element)
						return {
							element: tmpImage.element,
							value: tmpImage.value,
							thumbnailUrl: tmpImage.thumbnailUrl,
							tmpInfo: tmpImage.tmpInfo,
							isLoading: false,
						};
					return val;
				}),
			});
	}, [tmpImage]);

	const handleChangeManualTest = async ({
		e,
		usedFor,
		fileType,
		field,
		valueType,
		dateValue,
	}) => {
		let value = null;
		const { name, checked } = e.target;
		if (!dateValue) {
			value = e.target.value;
		}

		if (
			!dateValue &&
			e.target.files &&
			e.target.files[0] &&
			(fileType === 'image' || fileType === 'document')
		) {
			e.preventDefault();
			e.stopPropagation();

			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === field) return { ...val, isLoading: true };
					return val;
				}),
			});

			const file = e.target.files[0];

			const res = await dispatch(
				CREATE_TASK_FILE({
					taskId: currentTaskState.id,
					file,
					accessToken,
					dispatch,
					usedFor,
					fileType,
				})
			);

			if (res.payload) {
				const resFile = await dispatch(
					GET_EXAMPLE_FILE({
						taskId: currentTaskState.id,
						fileId: res.payload.id,
						accessToken,
						dispatch,
						thumbnail: fileType === 'image',
					})
				);

				setTmpImage({
					element: field,
					value: res.payload.id,
					thumbnailUrl: resFile.payload.download_url,
					tmpInfo: res.payload,
					isLoading: false,
				});
				dispatch(
					ADD_ALERT({ type: 'success', message: IMAGE_FILE_UPLOAD_SUCCESS })
				);
				setTimeout(() => {
					dispatch(REMOVE_ALERT(IMAGE_FILE_UPLOAD_SUCCESS));
				}, 2000);
			} else {
				dispatch(
					ADD_ALERT({ type: 'error', message: IMAGE_FILE_UPLOAD_ERROR })
				);
			}
		} else if (
			!dateValue &&
			e.target.files &&
			e.target.files[0] &&
			fileType === 'csv'
		) {
			e.preventDefault();
			e.stopPropagation();

			// Parse csv file
			const parsedData = await parseCSVTimeSeriesFile(
				e.target.files[0],
				allColumns,
				dispatch,
				setIsLoadingFile,
				setFileToUpload,
				schemaState
			);

			// Set parsed value to manual example
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === field)
						return {
							...val,
							isLoading: false,
							fileName: e.target.files[0].name,
							value: parsedData,
						};
					return val;
				}),
			});
		} else if ((value === 'on' || value === 'off') && field !== 'anomaly') {
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === field) {
						return { element: field, value: 'checked' };
					}
					return val;
				}),
			});
		} else if (field === 'anomaly') {
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === name && checked) return { ...val, value: 1.0 };
					if (val.element === name && !checked) return { ...val, value: 0.0 };
					return val;
				}),
			});
		} else if (dateValue) {
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === field) {
						return {
							element: field,
							value: getLocalDateTime(dateValue, userLocationState),
						};
					}
					return val;
				}),
			});
		} else {
			setManualTest({
				...manualTest,
				values: manualTest.values.map((val) => {
					if (val.element === field)
						return {
							element: field,
							value:
								valueType === 'number' ||
								valueType === 'integer' ||
								valueType === 'float'
									? Number(value)
									: value,
						};
					return val;
				}),
			});
		}
	};

	const handleSaveCreate = async () => {
		if (creationStep === 0) {
			setCreationStep(1);
		}
		if (creationStep === 1) {
			setCreationStep(0);
			setOpen(false);
			setFileToUpload(null);

			// CREATE TEST MANUALLY
			const tmpTest = manualTest.values
				.filter((val) => {
					if (val.value === '' || val.value === null || val.value === undefined)
						return false;
					return val;
				})
				.map((val) => ({ element: val.element, value: val.value }));

			const formatedTest = {
				id: uuidv4(),
				uuid: uuidv4(),
				inputs: [],
				metadata: [],
				targets: manualTest.targets,
			};

			allColumns.forEach((column) => {
				let tmp = tmpTest.find(
					(prediction) => prediction.element === column.field
				);

				if (tmp) {
					let tmpCat = '';
					if (column.fieldType === 'input') {
						if (column.valueType === 'category') {
							categoriesState.forEach((category) => {
								if (category.categories.find((cat) => cat.id === tmp.value))
									tmpCat = category.categories.find(
										(cat) => cat.id === tmp.value
									).name;
							});
							formatedTest.inputs.push({
								...tmp,
								value: tmpCat,
							});
						} else formatedTest.inputs.push(tmp);
					}
					if (column.fieldType === 'metadata') {
						if (column.valueType === 'category') {
							categoriesState.forEach((category) => {
								if (category.categories.find((cat) => cat.id === tmp.value))
									tmpCat = category.categories.find(
										(cat) => cat.id === tmp.value
									).name;
							});
							formatedTest.metadata.push({
								...tmp,
								value: tmpCat,
							});
						} else formatedTest.metadata.push(tmp);
					}

					tmpCat = '';
				}

				tmp = null;
			});

			await dispatch(
				CREATE_TEST({
					taskId: currentTaskState.id,
					newTests: { batch: [{ values: tmpTest }] },
					accessToken,
					dispatch,
					schemaState,
				})
			);
			setTimeout(() => {}, 1000);

			dispatch(
				GET_TESTS({
					taskId: currentTaskState.uuid,
					accessToken,
					dispatch,
					schemaState,
				})
			);

			handleClose();
		}
	};

	const handleClickUploadFile = () => {
		hiddenFileInput.current.click();
	};

	const handleChangeUploadCSV = async (event) => {
		setIsLoadingFile(true);
		setFileToUpload(event.target.files[0]);
		const newExamples = createValidatedExampleObjects(
			await parseCSVFile(
				event.target.files[0],
				allColumns,
				dispatch,
				setIsLoadingFile,
				setFileToUpload,
				schemaState,
				currentTaskState,
				accessToken,
				csvData,
				setCsvData
			),
			schemaState,
			dispatch
		);

		const res = await getFilesFromParsedCSV(
			newExamples,
			schemaState,
			dispatch,
			currentTaskState,
			accessToken,
			setUploadStatus,
			setCsvErrors
		);

		if (!res.errors) {
			await dispatch(
				CREATE_TEST({
					taskId: currentTaskState.id,
					newTests: { batch: [{ values: res }] },
					accessToken,
					dispatch,
				})
			);

			await dispatch(
				GET_TESTS({
					taskId: currentTaskState.id,
					accessToken,
					dispatch,
					schemaState,
				})
			);
			setIsLoadingFile(false);
			handleClose();
		} else {
			setIsLoadingFile(false);
			setCsvErrors(res.errors);
			setOpenCsvErrorModal(true);
		}
	};

	const handleResetInputFile = (e) => {
		e.target.value = null;
	};

	const handleChangePage = async ({ newPage, column }) => {
		setPage(newPage);
		await dispatch(
			GET_INPUT_CATEGORIES({
				accessToken,
				taskId: currentTaskState.uuid,
				dispatch,
				inputId: column.uuid,
				inputName: column.name,
				page: parseInt(newPage + 1),
			})
		);
	};

	return (
		<>
			<StandardModal
				open={open}
				setOpen={setOpen}
				title="Create test:"
				content={
					<>
						{creationStep === 0 && (
							<Box sx={styles.dialogContentContainer}>
								<Box sx={styles.createTypeContainer}>
									<div style={styles.csvUploadColumn}>
										<button
											type="button"
											onClick={handleAddExampleManually}
											className={CSSstyles.createExampleButton}
										>
											Create manually
											<FontAwesomeIcon icon={faKeyboard} />
										</button>
									</div>
									<div style={styles.csvUploadColumn}>
										{isLoadingFile && (
											<button
												type="button"
												onClick={handleClickUploadFile}
												className={CSSstyles.createExampleButton}
											>
												{/* Preparing file... */}
												{uploadStatus}
												<Loader size="L" local />
											</button>
										)}
										{!isLoadingFile && fileToUpload === null && (
											<>
												<button
													type="button"
													onClick={handleClickUploadFile}
													className={CSSstyles.createExampleButton}
												>
													Add CSV file
													<FontAwesomeIcon size="lg" icon={faFileCsv} />
												</button>
												<input
													type="file"
													ref={hiddenFileInput}
													onChange={handleChangeUploadCSV}
													style={{ display: 'none' }}
												/>
											</>
										)}
										{!isLoadingFile && fileToUpload !== null && (
											<button
												type="button"
												className={CSSstyles.createExampleButton}
											>
												Selected file:
												{fileToUpload.name} upload finished
												<FontAwesomeIcon icon={faCloudArrowUp} />
											</button>
										)}
										{/* <input type="file"
              className={classes.createExampleButton}
              value={
                <>
                  Add CSV file
                  <FontAwesomeIcon icon={faFileCsv} />
                </>
              }
            /> */}
										{!isLoadingFile &&
											fileToUpload === null &&
											Object.keys(csvReport).length > 0 && (
												<div className={CSSstyles.Export_btn}>
													<StandardButton
														type="textButton"
														value={
															// <CSVLink {...csvReport}>Export CSV template</CSVLink>
															<CSVLink
																// data={csvReport.data}
																filename={csvReport.filename}
																headers={csvReport.headers}
																data={[]}
															>
																Export CSV template
															</CSVLink>
														}
													/>
												</div>
											)}
									</div>
								</Box>
							</Box>
						)}

						{creationStep === 1 && (
							<Box sx={styles.exampleColumnsContainer}>
								<Box sx={styles.exampleColumnsRow}>
									<Box sx={styles.nameColumn}>
										<div className={CSSstyles.create_data_labels}>
											ANOMALIES
										</div>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											width: '75%',
											alignItems: 'center',
											gap: '36px',
										}}
									>
										{schemaState.anomaly_types?.map((anomaly) => (
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<FormControl>
													<Checkbox
														id="anomalies"
														name={anomaly.name}
														color="primary"
														checked={
															manualTest.targets.find(
																(target) => target.element === anomaly.name
															).value === 1.0
														}
														onChange={(e) =>
															handleChangeManualTest({
																e,
																field: 'anomaly',
															})
														}
													/>
												</FormControl>
												<AnomalyChip
													tooltip
													name={anomaly.name}
													color={
														manualTest.targets.find(
															(target) => target.element === anomaly.name
														).value === 1.0
															? anomaly.color
															: colors.ligthGray
													}
												/>
											</Box>
										))}
									</Box>
								</Box>
								{allColumns.map(
									(column) =>
										column.field !== 'airelease' &&
										column.field !== 'predictedAnomalies' &&
										column.field !== 'anomalies' &&
										column.fieldType !== 'output' && (
											<Box key={column.id} sx={styles.exampleColumnsRow}>
												<Box sx={styles.nameColumn}>
													{column.fieldType &&
														column.fieldType !== 'status' &&
														column.valueType &&
														elementTypeToIconMap[column.valueType](
															column.fieldType
														)}
													<div className={CSSstyles.create_data_labels}>
														{column.fieldType !== 'status' && column.name}
													</div>
												</Box>
												<Box sx={styles.inputColumn}>
													{
														// NUMBER TYPE
														column.valueType === 'number' && (
															<Input
																className={CSSstyles.common_input_type}
																type="number"
																value={
																	manualTest.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(e) =>
																	handleChangeManualTest({
																		e,
																		field: column.field,
																	})
																}
															/>
														)
													}
													{
														// TEXT TYPE
														column.valueType === 'text' && (
															<TextField
																multiline
																rows={5}
																maxRows={5}
																inputProps={{ maxLength: 32000 }}
																id={column.field}
																className={CSSstyles.common_input_type}
																value={
																	manualTest.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(e) =>
																	handleChangeManualTest({
																		e,
																		field: column.field,
																	})
																}
															/>
														)
													}
													{
														// CATEGORY TYPE
														column.valueType === 'category' && (
															<Select
																id="Category"
																sx={styles.typeCategory}
																value={
																	manualTest.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(e) =>
																	handleChangeManualTest({
																		e,
																		field: column.field,
																	})
																}
															>
																{categoriesState &&
																	categoriesState
																		.find((c) => c.id === column.uuid)
																		?.categories?.map((category) => (
																			<MenuItem
																				value={category.name}
																				key={category.id}
																			>
																				<em>
																					{category.display_name ||
																						category.name}
																				</em>
																			</MenuItem>
																		))}
																{categoriesState &&
																	categoriesState.find(
																		(c) => c.id === column.uuid
																	) && (
																		<Grid
																			conteiner
																			style={{
																				display: 'flex',
																				justifyContent: 'center',
																			}}
																		>
																			<CustomPagination
																				total={
																					categoriesState.find(
																						(c) => c.id === column.uuid
																					)?.total_count
																				}
																				rowsPerPage={rowsPerPage}
																				page={page}
																				handleChangePage={(e, value) => {
																					handleChangePage({
																						newPage: value,
																						column,
																					});
																				}}
																				rowsPerPageOptions={[4]}
																				simple
																			/>
																		</Grid>
																	)}
															</Select>
														)
													}
													{
														// DOCUMENT FILE TYPE
														column.valueType === 'document' && (
															<div style={styles.uploadContainer}>
																{manualTest.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
																		value={
																			manualTest.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload document'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualTest({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'document',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={CSSstyles.data_preview}>
																	Can't load document preview
																</div>
															</div>
														)
													}
													{
														// IMAGE TYPE
														column.valueType === 'image' && (
															<div style={styles.uploadContainer}>
																{manualTest.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept="image/.jpg, image/.png"
																		value={
																			manualTest.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload image'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualTest({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'image',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={CSSstyles.data_preview}>
																	{manualTest.values.find(
																		(element) =>
																			element.element === column.field
																	)?.thumbnailUrl ? (
																		<img
																			alt={
																				manualTest.values.find(
																					(element) =>
																						element.element === column.field
																				)?.value
																			}
																			src={
																				// manualTest.values.find(
																				// 	(element) =>
																				// 		element.element === column.field
																				// )?.value &&
																				manualTest.values.find(
																					(element) =>
																						element.element === column.field
																				)?.thumbnailUrl !== '' &&
																				// ? () =>
																				// 		getThumbnailImage(
																				// 			manualTest.values.find(
																				// 				(element) =>
																				// 					element.element ===
																				// 					column.field
																				// 			)?.value
																				// 		)
																				// :
																				manualTest.values.find(
																					(element) =>
																						element.element === column.field
																				)?.thumbnailUrl
																			}
																			style={styles.imagePreview}
																		/>
																	) : (
																		`Can't load image preview`
																	)}
																</div>
															</div>
														)
													}
													{
														// TIME SERIES TYPE
														column.valueType === 'time_series' && (
															<div style={styles.uploadContainer}>
																{manualTest.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept=".csv"
																		value={
																			manualTest.values.find(
																				(element) =>
																					element.element === column.field
																			)?.fileName || 'Upload CSV'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualTest({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'csv',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={(e) =>
																			handleResetInputFile(e, 'time_serie')
																		}
																	/>
																)}
																<div style={styles.csvDataPreview}>
																	{manualTest.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value && (
																		<LineChart
																			width={200}
																			height={50}
																			data={
																				manualTest.values.find(
																					(element) =>
																						element.element === column.field
																				)?.value
																			}
																		>
																			<Line
																				dataKey={(v) => v}
																				stroke="#8884d8"
																				strokeWidth={1}
																				dot={false}
																			/>
																		</LineChart>
																	)}
																</div>
															</div>
														)
													}
												</Box>
											</Box>
										)
								)}
							</Box>
						)}
					</>
				}
				actions={
					<>
						{creationStep === 1 && (
							<StandardButton
								loading={manualTest.values.some((element) => element.isLoading)}
								value="Create test"
								handleClick={handleSaveCreate}
							/>
						)}
						<StandardButton value="Close" handleClick={handleClose} close />
					</>
				}
			/>

			{openCsvErrorModal && (
				<StandardModal
					open={openCsvErrorModal}
					setOpen={setOpenCsvErrorModal}
					title="CSV upload errors report:"
					content={
						<ul>
							{csvErrors.map((error) => (
								<li key={uuidv4()}>{error}</li>
							))}
						</ul>
					}
					actions={
						<StandardButton value="Close" handleClick={handleClose} close />
					}
				/>
			)}
		</>
	);
};

CreateOrUpdateAITestingModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	allColumns: PropTypes.array,
	schemaState: PropTypes.object,
	step: PropTypes.number,
};
