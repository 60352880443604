import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
// import { ConfusionMatrix } from 'react-confusion-matrix';
import { ConfusionMatrix } from '../../../../../../Components/Shared/Charts/ConfusionMatrix';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import StandardBarChart from '../../../../../../Components/Shared/Charts/StandardBarChart';

// Consts
import StandardPieChart from '../../../../../../Components/Shared/Charts/StandardPieChart';
import { colors } from '../../../../../../consts/colors';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';
import { CustomTooltip } from '../../../../../../Components/Shared/CustomTooltip';

export const MetricsModal = ({ open, setOpen }) => {
	const { statsAndMetrics: statsAndMetricsState } = useSelector(
		(state) => state.tests
	);
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const [confusionMatrixTab, setConfusionMatrixTab] = useState('complete');

	const handleClose = () => {
		setOpen(!open);
	};

	const handleChangeConfusionMatrixTab = (tab) => {
		setConfusionMatrixTab(tab);
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Stats and Metrics:"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						alignItems: 'start',
						padding: '0 !important',
						margin: '0 !important',
						gap: 12,
					}}
				>
					{statsAndMetricsState.num_predictions === 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							Create some tests first to get stats and metrics from them.
						</Grid>
					)}
					{statsAndMetricsState.num_predictions !== 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<h3
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span style={{ paddingRight: '6px' }}>
										Anomaly Detection Percentage
									</span>
									<CustomTooltip title="Accumulated anomaly vs. normal detection rates from ongoing inferences.">
										<HelpIcon
											sx={{
												transform: 'scale(1) !important',
											}}
										/>
									</CustomTooltip>
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<StandardPieChart
									data={[
										{
											name: 'Without anomalies',
											value:
												statsAndMetricsState.num_predictions -
												statsAndMetricsState.num_anomalous,
										},
										{
											name: 'With anomalies',
											value: statsAndMetricsState.num_anomalous,
										},
									]}
								/>
							</Grid>
						</Grid>
					)}
					{statsAndMetricsState?.num_anomalies?.length > 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Anomalies by Type</span>
								<CustomTooltip title="Accumulated counts of each anomaly type detected in inferences, where each bar represents the count of a specific anomaly type.">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							<Grid
								container
								sx={{
									height: '200px',
								}}
							>
								<StandardBarChart
									data={anomalyTypesState.map((anomaly) => ({
										name: anomaly.name,
										value:
											statsAndMetricsState.num_anomalies.find(
												(anom) => anom.anomaly_type === anomaly.uuid
											)?.count || 0,
									}))}
									anomalyColor={anomalyTypesState.map(
										(anomaly) => anomaly.color
									)}
								/>
							</Grid>
						</Grid>
					)}
					{statsAndMetricsState?.metrics?.acc_anomalies?.length > 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Performance Table</span>
								<CustomTooltip title="Provides a measure of the AI release's success in correctly detecting anomalies across types. It lists the number of detections and the accuracy rate, indicating the proportion of true positives (correct identifications) among them.">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							<TableContainer
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Table
									aria-label="simple table"
									sx={{
										width: 'auto',
										minWidth: { xs: '300px', md: '500px' },
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell
												sx={{
													borderBottom: '1px solid gray',
												}}
											/>
											<TableCell
												sx={{
													border: '1px solid gray',
													fontWeight: 'bold',
													backgroundColor: '#e0e0e0',
												}}
												align="left"
											>
												Count
											</TableCell>
											<TableCell
												sx={{
													border: '1px solid gray',
													fontWeight: 'bold',
													backgroundColor: '#e0e0e0',
												}}
												align="left"
											>
												Accuracy (%)
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{statsAndMetricsState.metrics.acc_anomalies.map(
											(anomaly) => (
												<TableRow key={anomaly.anomaly_type}>
													<TableCell
														sx={{
															border: '1px solid gray',
															fontWeight: 'bold',
														}}
													>
														<AnomalyChip
															tooltip
															color={
																anomalyTypesState.find(
																	(anom) => anom.uuid === anomaly.anomaly_type
																)?.color
															}
															name={
																statsAndMetricsState.anomaly_types.find(
																	(anom) => anom.uuid === anomaly.anomaly_type
																)?.name
															}
														/>
													</TableCell>
													<TableCell
														sx={{ border: '1px solid gray' }}
														align="left"
													>
														{statsAndMetricsState.num_anomalies.find(
															(anom) =>
																anom.anomaly_type === anomaly.anomaly_type
														)?.count || 'N/A'}
													</TableCell>
													<TableCell
														sx={{ border: '1px solid gray' }}
														align="left"
													>
														{`${(anomaly.accuracy * 100).toFixed(2)} %`}
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					)}
					{statsAndMetricsState?.metrics?.cm_anomalies &&
						Object.keys(statsAndMetricsState?.metrics?.cm_anomalies).length >
							0 && (
							<Grid
								container
								sx={{
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								{confusionMatrixTab === 'grouped' ? (
									<h3
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<span style={{ paddingRight: '6px' }}>
											Grouped Confusion Matrix
										</span>
										<CustomTooltip title="Illustrates AI release's accuracy in real-world scenarios, categorizing detections into anomalies or normal. Columns represent model predictions, rows indicate actual outcomes, providing insight into true versus false detections.">
											<HelpIcon
												sx={{
													transform: 'scale(1) !important',
												}}
											/>
										</CustomTooltip>
									</h3>
								) : (
									<h3
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<span style={{ paddingRight: '6px' }}>
											Complete Confusion Matrix
										</span>
										<CustomTooltip title="Displays AI release's real-world accuracy, with columns for predictions and rows for actual outcomes. Cell values reveal the count of each prediction type against the truth.">
											<HelpIcon
												sx={{
													transform: 'scale(1) !important',
												}}
											/>
										</CustomTooltip>
									</h3>
								)}
								<Grid
									container
									sx={{
										width: { xs: '100%', md: '50%' },
										marginBottom: '12px',
										'& > *': {
											padding: '6px 12px',
										},
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										sx={{
											fontWeight: 'bold',
											cursor: 'pointer',
											color:
												confusionMatrixTab === 'complete'
													? colors.buttonPrimaryColor
													: 'black',
											borderBottom:
												confusionMatrixTab === 'complete'
													? `5px solid ${colors.buttonPrimaryColor}`
													: 'none',
										}}
										onClick={() => handleChangeConfusionMatrixTab('complete')}
									>
										Complete
									</Grid>

									<Grid
										item
										sx={{
											fontWeight: 'bold',
											cursor: 'pointer',
											color:
												confusionMatrixTab === 'grouped'
													? colors.buttonPrimaryColor
													: 'black',
											borderBottom:
												confusionMatrixTab === 'grouped'
													? `5px solid ${colors.buttonPrimaryColor}`
													: 'none',
										}}
										onClick={() => handleChangeConfusionMatrixTab('grouped')}
									>
										Grouped
									</Grid>
								</Grid>
								<Grid item xs={12}>
									{confusionMatrixTab === 'complete' && (
										<ConfusionMatrix
											sideBar={false}
											data={Object.keys(
												statsAndMetricsState.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														statsAndMetricsState.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)
												.map((row) =>
													Object.keys(
														statsAndMetricsState.metrics.cm_anomalies[row]
													)
														.filter((x) => x !== 'normal')
														.concat(
															Object.keys(
																statsAndMetricsState.metrics.cm_anomalies
															).filter((x) => x === 'normal')
														)
														.map(
															(col) =>
																statsAndMetricsState.metrics.cm_anomalies[row][
																	col
																]
														)
												)}
											labels={Object.keys(
												statsAndMetricsState.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														statsAndMetricsState.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)}
											anomaliesColor={Object.keys(
												statsAndMetricsState.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														statsAndMetricsState.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)
												.map(
													(anomaly) =>
														anomalyTypesState.find(
															(anom) =>
																anom.name === anomaly ||
																anom.display_name === anomaly
														)?.color
												)}
										/>
									)}
									{confusionMatrixTab === 'grouped' && (
										<ConfusionMatrix
											sideBar={false}
											data={[
												[
													statsAndMetricsState.metrics.cm_anomalous
														.true_positives,
													statsAndMetricsState.metrics.cm_anomalous
														.false_negatives,
												],
												[
													statsAndMetricsState.metrics.cm_anomalous
														.false_positives,
													statsAndMetricsState.metrics.cm_anomalous
														.true_negatives,
												],
											]}
											grouped
										/>
									)}
								</Grid>
							</Grid>
						)}
				</Grid>
			}
			actions={<StandardButton value="Close" handleClick={handleClose} close />}
		/>
	);
};

MetricsModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};
