import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfToday, format } from 'date-fns';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import { DateTimePicker } from '@mui/x-date-pickers';
import {
	Checkbox,
	Collapse,
	Container,
	FormControl,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { PieChart, Cached } from '@mui/icons-material';
// import { DateRangePicker } from 'react-date-range';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import { CustomPagination } from '../../../../Components/Shared/CustomPagination';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import { PredictionViewModal } from './components/PredictionViewModal';
import { EditViewModal } from './components/EditViewModal';
import { QueryBuilderModal } from '../Examples/components/QueryBuilderModal';
import { MetricsModal } from './components/MetricsModal';
import { AIPredictionsTable } from './components/AIPredictionsTable';
import AnomalyChip from '../../../../Components/Shared/AnomalyChip';

// Styles
import { styles } from './styles';

// Redux
import {
	GET_EXAMPLE_FILE,
	SET_EXAMPLE_DOCUMENTS_BUFFER,
	SET_EXAMPLE_IMAGES_BUFFER,
} from '../../../../redux/examples.slice';
import {
	GET_PREDICTIONS,
	GET_PREDICTION_STATS,
	SET_CURRENT_PREDICTION,
} from '../../../../redux/predictions.slice';

// Services
import { getColumns, getRows } from './aipredictions.services';
import { getTextColorBasedOnBackgroundColor } from '../../../../services/getTextColorBasedOnBackgroundColor';
import {
	getLastMonth,
	getLastWeek,
	getThisMonth,
	getThisWeek,
	getToday,
	getYesterday,
} from '../../../../services/date';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { CreateOrUpdateAIPredictionModal } from './components/CreateOrUpdateAIPredictionModal';
import { colors } from '../../../../consts/colors';

export function TaskAIPredictions() {
	const dispatch = useDispatch();

	const {
		examples: examplesState,
		imagesBuffer: imagesBufferState,
		documentsBuffer: documentsBufferState,
	} = useSelector((state) => state.examples);
	const {
		AIReleases: AIReleasesState,
		productionAIRelease: productionAIReleaseState,
	} = useSelector((state) => state.aireleases);
	const schemaState = useSelector((state) => state.schema);
	const { categories: categoriesState } = useSelector((state) => state.schema);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	const {
		predictions: predictionsState,
		totalPredictions: totalPredictionsState,
	} = useSelector((state) => state.predictions);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [rowsPerPageOptions] = useState([25, 50, 100]);
	const [currentRowId, setCurrentRowId] = useState('');
	const [selectedRows, setSelectedRows] = useState([]);
	const [currentCellId, setCurrentCellId] = useState('');
	const [currentColumns, setCurrentColumns] = useState([]);
	const [allColumns, setAllColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [orderedBy, setOrderedBy] = useState([null, null]);
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const [showFilters, setShowFilters] = useState(false);

	// todo: when multiple query suported
	// const [query, setQuery] = useState([]);
	const [query, setQuery] = useState({ field: '', operator: '==', value: '' });
	const [filters, setFilters] = useState({
		query: '',
		order: '',
		order_by: '',
		ai_model: '',
		period: '',
		'created_at[min]': null,
		'created_at[max]': endOfToday(),
		// TODO: change to array when multiple anomalies supported
		anomalies: '',
		// anomalies: [],
	});
	const [openStatusTooltip, setOpenStatusTooltip] = useState(false);

	// Modals
	const [openCreatePredictionModal, setOpenCreatePredictionModal] =
		useState(false);
	const [openEditPredictionModal, setOpenEditPredictionModal] = useState(false);
	const [openPredictionViewModal, setOpenPredictionViewModal] = useState(true);
	const [openEditViewModal, setOpenEditViewModal] = useState(false);
	const [openQueryBuilderModal, setOpenQueryBuilderModal] = useState(false);
	const [openStatsModal, setOpenStatsModal] = useState(false);

	const handleOpenStatusTooltip = () => {
		setOpenStatusTooltip(!openStatusTooltip);
	};

	const getPredictionsWithFilters = async () => {
		await dispatch(
			GET_PREDICTIONS({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				page: page + 1,
				per_page: rowsPerPage,
				query: filters.query,
				order: filters.order,
				order_by: filters.order_by,
				ai_model: filters.ai_model,
				'created_at[min]': format(
					new Date(filters['created_at[min]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				'created_at[max]': format(
					new Date(filters['created_at[max]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				schemaState,
			})
		);

		await dispatch(
			GET_PREDICTION_STATS({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				query: filters.query,
				order: filters.order,
				order_by: filters.order_by,
				ai_model: filters.ai_model,
				'created_at[min]': format(
					new Date(filters['created_at[min]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				'created_at[max]': format(
					new Date(filters['created_at[max]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
			})
		);
	};

	useEffect(() => {
		if (currentTaskState.uuid && accessToken && !predictionsState.isLoading)
			getPredictionsWithFilters();
	}, [filters, page, rowsPerPage]);

	useEffect(() => {
		if (productionAIReleaseState?.uuid) {
			setFilters({
				...filters,
				ai_model: productionAIReleaseState.uuid,
			});
		}
	}, [productionAIReleaseState]);

	const handleChangeFilters = (e) => {
		const { id, name, value, checked } = e.target;

		setFilters({ ...filters, [name]: value });
	};

	const setImagesOnBuffer = async () => {
		const imageElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'image') {
						predictionsState.forEach((prediction) => {
							prediction.inputs.forEach((predictionInput) => {
								if (predictionInput.element === input.name)
									if (predictionInput.value !== '') {
										const checkIfInBuffer = imagesBufferState.find(
											(image) => image.elementId === predictionInput.value
										);
										if (!checkIfInBuffer) imageElements.push(predictionInput);
									}
							});
						});
					}
				});
			}
		}

		if (imageElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					imageElements.map(async (element) => {
						const resThumbnail = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
								thumbnail: true,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							// image: resThumbnail.payload.download_url,
							thumbnail: resThumbnail.payload.download_url,
						};
					})
				).then((result) => result)),
			];

			dispatch(SET_EXAMPLE_IMAGES_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_IMAGES_BUFFER());
	};

	const setDocumentsOnBuffer = async () => {
		const documentElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'document') {
						examplesState.forEach((example) => {
							example.inputs.forEach((exampleInput) => {
								if (exampleInput.element === input.name)
									if (exampleInput.value !== '')
										documentElements.push(exampleInput);
							});
						});
					}
				});
			}
		}

		if (documentElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					documentElements.map(async (element) => {
						const resDocument = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							url: resDocument.payload.download_url,
						};
					})
				).then((result) => result)),
			];
			dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER());
	};

	useEffect(() => {
		setAllColumns([]);
		setCurrentColumns([]);
		setOpenEditViewModal(false);
		dispatch(SET_CURRENT_PREDICTION({}));
		if (
			localStorage.getItem(`${currentTaskState.uuid}-aipredictioncolumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-aipredictioncolumns`)
				.length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) =>
					localStorage
						.getItem(`${currentTaskState.uuid}-aipredictioncolumns`)
						.includes(col.field)
				)
			);
		}
	}, []);

	useEffect(() => {
		setImagesOnBuffer();
		setDocumentsOnBuffer();
	}, [predictionsState]);

	useEffect(() => {
		if (currentRowId !== '') {
			dispatch(SET_CURRENT_PREDICTION(currentRowId));
			setOpenPredictionViewModal(true);
		} else {
			setCurrentRowId('');
			dispatch(SET_CURRENT_PREDICTION({}));
			setOpenPredictionViewModal(false);
		}
	}, [currentRowId]);

	useEffect(() => {
		if (!openPredictionViewModal) {
			setCurrentRowId('');
			setCurrentCellId('');
			dispatch(SET_CURRENT_PREDICTION({}));
		}
	}, [openPredictionViewModal]);

	useEffect(() => {
		// todo
		// const getCategories = async () => {};
		if (
			!imagesBufferState.imagesBufferIsLoading &&
			accessToken &&
			currentTaskState.uuid
		) {
			getColumns({
				setAllColumns,
				styles,
				schemaState,
				predictionsState,
				currentTaskState,
				categoriesState,
				accessToken,
				setOpenEditPredictionModal,
				setCurrentRowId,
				dispatch,
				imagesBufferState,
				documentsBufferState,
				HasAccess,
				openStatusTooltip,
				handleOpenStatusTooltip,
			});
			getRows({
				setRows,
				schemaState,
				predictionsState,
				AIReleasesState,
			});
		}
	}, [schemaState, predictionsState, imagesBufferState, documentsBufferState]);

	useEffect(() => {
		setCurrentColumns(allColumns);

		if (
			localStorage.getItem(`${currentTaskState.uuid}-aipredictioncolumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-aipredictioncolumns`)
				.length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) => {
					const tmp = localStorage.getItem(
						`${currentTaskState.uuid}-aipredictioncolumns`
					);
					if (tmp.includes(col.field)) return col;
					return false;
				})
			);
		} else {
			setCurrentColumns(allColumns);
		}
	}, [allColumns]);

	const openCreatePredictionView = () => {
		setOpenCreatePredictionModal(true);
	};

	const openEditExampleView = () => {
		setOpenEditViewModal(true);
	};

	const openQueryBuilder = () => {
		setOpenQueryBuilderModal(true);
	};

	const openStats = () => {
		setOpenStatsModal(!openStatsModal);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
	};

	useEffect(() => {
		if (!openStatsModal) {
			setCurrentRowId('');
			setSelectedRows([]);
			dispatch(SET_CURRENT_PREDICTION({}));
		}
	}, [openStatsModal]);

	// const handleChangeRelease = (e) => {
	// 	const { value } = e.target;
	// 	setSelectedRelease(value);
	// };

	const handleChangeOrderBy = (e) => {
		const { value } = e.target;
		const tmpValues = value.split(',');
		setOrderedBy(value);
		setFilters({ ...filters, order_by: tmpValues[0], order: tmpValues[1] });
	};

	const handleChangeByPeriod = (e) => {
		const { value } = e.target;
		switch (value) {
			case 'TODAY':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getToday(),
				});
				break;
			case 'YESTERDAY':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getYesterday(),
				});
				break;
			case 'THIS_WEEK':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getThisWeek().start,
					'created_at[max]': getThisWeek().end,
				});
				break;
			case 'LAST_WEEK':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getLastWeek().start,
					'created_at[max]': getLastWeek().end,
				});
				break;
			case 'THIS_MONTH':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getThisMonth().start,
					'created_at[max]': getThisMonth().end,
				});
				break;
			case 'LAST_MONTH':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getLastMonth().start,
					'created_at[max]': getLastMonth().end,
				});
				break;
			default:
				return null;
		}
	};

	const handleChangeFromDateRange = (date) => {
		setFilters({ ...filters, 'created_at[min]': date, period: '' });
	};

	const handleChangeToDateRange = (date) => {
		setFilters({ ...filters, 'created_at[max]': date, period: '' });
	};

	const handleOpenStatsModal = () => {
		setOpenStatsModal(!openStatsModal);
	};

	const handleChangeAnomalyFilter = (e) => {
		const anomaly = e.target.value;
		setFilters({ ...filters, anomalies: anomaly, query: `${anomaly}=1` });
	};

	// TODO: filter by multiple anomalies when supported in API
	// const handleChangeAnomalyFilter = (e) => {
	// 	const anomalies = e.target.value;
	// 	setFilters({ ...filters, anomalies });
	// };

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			{/* <QueryBuilderComponent
				updateQuery={updateQuery}
				openCreateExampleView={openCreateExampleView}
				setOpenCreateExampleModal={setOpenCreateExampleModal}
			/> */}
			<Container maxWidth={false} sx={{ padding: '0px !important' }}>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						gap: 1,
						fontWeight: 'bold',
						alignItems: 'center',
						justifyContent: 'start',
						marginBottom: '12px',
					}}
				>
					<Grid item sx={{ minWidth: '150px' }}>
						<StandardButton
							value="Select filters"
							handleClick={() => setShowFilters(!showFilters)}
							icon={<TuneIcon />}
						/>
					</Grid>
					<Grid
						item
						sx={{
							width: '100%',
							outline: '2px solid #d3eafa',
							borderRadius: '6px',
							padding: '6px 6px',
							gap: 1,
							display: 'flex',
							flexWrap: 'wrap',
							'& > *': {
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							},
						}}
					>
						<Grid
							item
							sx={{
								display: 'flex',
								fontWeight: 'bold',
								alignItems: 'center',
							}}
						>
							Current filters:
						</Grid>
						{filters.ai_model !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								Prod. release:{' '}
								{AIReleasesState.find(
									(release) => release.uuid === filters.ai_model
								)?.version || ''}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({
											...filters,
											ai_model: '',
										})
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.anomalies !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '12px',
									padding: '0px 6px',
									color: getTextColorBasedOnBackgroundColor(
										schemaState.anomaly_types.find(
											(anom) =>
												anom.name === filters.anomalies ||
												anom.display_name === filters.anomalies
										).color
									),
									backgroundColor: schemaState.anomaly_types.find(
										(anom) =>
											anom.name === filters.anomalies ||
											anom.display_name === filters.anomalies
									).color,
								}}
							>
								{filters.anomalies}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({
											...filters,
											anomalies: filters.anomalies.filter(
												(l) => l !== filters.anomalies
											),
										})
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{
							// TODO: Filter for multiple anomalies when ready on API
						}
						{/* {filters.anomalies.length > 0 &&
							filters.anomalies.map((anomaly) => (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '12px',
										padding: '0px 6px',
										color: getTextColorBasedOnBackgroundColor(
											schemaState.anomaly_types.find(
												(anom) =>
													anom.name === anomaly || anom.display_name === anomaly
											).color
										),
										backgroundColor: schemaState.anomaly_types.find(
											(anom) =>
												anom.name === anomaly || anom.display_name === anomaly
										).color,
									}}
								>
									{anomaly}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												anomalies: filters.anomalies.filter(
													(l) => l !== anomaly
												),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							))} */}
						{filters.period === '' &&
							filters['created_at[min]'] &&
							filters['created_at[max]'] && (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										outline: '2px solid #d3eafa',
										borderRadius: '12px',
										padding: '0px 6px',
										backgroundColor: '#d3eafa',
									}}
								>
									from:{' '}
									{format(
										new Date(filters['created_at[min]']),
										"yyyy-MM-dd'T'HH:mm:ss"
									)}{' '}
									- to:{' '}
									{format(
										new Date(filters['created_at[max]']),
										"yyyy-MM-dd'T'HH:mm:ss"
									)}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												period: '',
												'created_at[min]': null,
												'created_at[max]': endOfToday(),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							)}
						{filters.period && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								{filters.period}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, period: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order_by !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								Order by: {filters.order_by}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order_by: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								Order: {filters.order}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{/* {filters.query !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								{`${filters.query.split('=')[0]} = ${
									filters.query.split('=')[1]
								}`}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => {
										setFilters({ ...filters, query: '' });
										setQuery({ field: '', operator: '==', value: '' });
									}}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)} */}
					</Grid>
				</Grid>
				<Collapse
					in={showFilters}
					timeout="auto"
					unmountOnExit
					sx={{ width: '100%', marginBottom: '24px' }}
				>
					<Grid container>
						<Grid
							item
							xs={12}
							sx={{
								outline: '2px solid #d3eafa',
								borderRadius: '12px',
								marginBottom: '12px',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									padding: '12px',
									marginBottom: '12px',
								}}
							>
								<Grid
									item
									xs={12}
									md={8}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By date:
									</Grid>
									<Grid item xs={12} sx={{ display: 'flex', gap: 1 }}>
										<Grid xs={6}>
											<FormControl fullWidth>
												<DateTimePicker
													label={'"From"'}
													// className={`${Date} ${styles.common_input_type}`}
													name="fromDate"
													value={filters.from}
													onChange={(newValue) =>
														handleChangeFromDateRange(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} helperText={null} />
													)}
													inputFormat="YYYY/MM/DD hh:mm A"
												/>
											</FormControl>
										</Grid>
										<Grid xs={6}>
											<FormControl fullWidth>
												<DateTimePicker
													label={'"To"'}
													// className={`${Date} ${styles.common_input_type}`}
													name="toDate"
													value={filters.to}
													onChange={(newValue) =>
														handleChangeToDateRange(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} helperText={null} />
													)}
													inputFormat="YYYY/MM/DD hh:mm A"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									md={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By period:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<FormControl fullWidth>
											<Select
												id="periodFilter"
												name="periodFilter"
												value={filters.period}
												onChange={handleChangeByPeriod}
											>
												<MenuItem value="TODAY">Today</MenuItem>
												<MenuItem value="YESTERDAY">Yesterday</MenuItem>
												<MenuItem value="THIS_WEEK">This Week</MenuItem>
												<MenuItem value="LAST_WEEK">Last Week</MenuItem>
												<MenuItem value="THIS_MONTH">This Month</MenuItem>
												<MenuItem value="LAST_MONTH">Last Month</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									padding: '12px',
									marginBottom: '12px',
								}}
							>
								<Grid
									item
									xs={12}
									md={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Production release:
									</Grid>
									<Grid item xs={12} sx={{ display: 'flex', gap: 1 }}>
										<FormControl fullWidth>
											<Select
												id="ai_model"
												name="ai_model"
												value={filters.ai_model}
												onChange={handleChangeFilters}
											>
												{AIReleasesState &&
													AIReleasesState.length > 0 &&
													AIReleasesState.map((release) => (
														<MenuItem value={release.uuid}>
															{release?.version || ''}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By anomaly:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											alignItems: 'center',
										}}
									>
										<FormControl fullWidth>
											<Select
												value={filters.anomalies}
												onChange={handleChangeAnomalyFilter}
												sx={{ maxHeight: '55px' }}
												renderValue={(selected) => (
													<Grid item sx={{ display: 'flex', gap: 1 }}>
														{schemaState.anomaly_types.find(
															(anom) => anom.name === selected
														) && (
															<AnomalyChip
																style={{ height: '10px !important' }}
																name={
																	schemaState.anomaly_types.find(
																		(anom) => anom.name === selected
																	).name
																}
																color={
																	schemaState.anomaly_types.find(
																		(anom) => anom.name === selected
																	).color
																}
															/>
														)}
													</Grid>
												)}
											>
												{schemaState.anomaly_types &&
													schemaState.anomaly_types.length > 0 &&
													schemaState.anomaly_types.map((anomaly) => (
														<MenuItem
															key={anomaly.uuid}
															value={anomaly.display_name || anomaly.name}
														>
															<Checkbox
																checked={filters.anomalies.includes(
																	anomaly.display_name || anomaly.name
																)}
															/>
															<AnomalyChip
																name={anomaly.display_name || anomaly.name}
																color={anomaly.color}
															/>
														</MenuItem>
													))}
											</Select>
										</FormControl>
										{
											// TODO: Selector for multiple anomalies when ready on API
										}
										{/* <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={filters.anomalies}
                      onChange={handleChangeAnomalyFilter}
                      sx={{ maxHeight: '55px' }}
                      renderValue={(selected) => (
                        <Grid item sx={{ display: 'flex', gap: 1 }}>
                          {selected.map(
                            (anomaly) =>
                              schemaState.anomaly_types.find(
                                (anom) => anom.name === anomaly
                              ) && (
                                <AnomalyChip
                                  style={{ height: '10px !important' }}
                                  name={
                                    schemaState.anomaly_types.find(
                                      (anom) => anom.name === anomaly
                                    ).name
                                  }
                                  color={
                                    schemaState.anomaly_types.find(
                                      (anom) => anom.name === anomaly
                                    ).color
                                  }
                                />
                              )
                          )}
                        </Grid>
                      )}
                    >
                      {schemaState.anomaly_types &&
                        schemaState.anomaly_types.length > 0 &&
                        schemaState.anomaly_types.map((anomaly) => (
                          <MenuItem
                            key={anomaly.uuid}
                            value={anomaly.display_name || anomaly.name}
                          >
                            <Checkbox
                              checked={filters.anomalies.includes(
                                anomaly.display_name || anomaly.name
                              )}
                            />
                            <AnomalyChip
                              name={anomaly.display_name || anomaly.name}
                              color={anomaly.color}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								outline: '2px solid #d3eafa',
								borderRadius: '12px',
								padding: '12px',
								marginBottom: '12px',
							}}
						>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Edit columns view:
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Query builder:
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Order by:
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<StandardButton
											value="Open editor"
											type="textButton"
											handleClick={openEditExampleView}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<StandardButton
											value="Open builder"
											type="textButton"
											handleClick={openQueryBuilder}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<FormControl fullWidth sx={{}}>
											<Select
												id="orderBy"
												name="orderBy"
												value={orderedBy}
												onChange={handleChangeOrderBy}
											>
												<MenuItem value="created_at,asc">
													Created at: from oldest to newest
												</MenuItem>
												<MenuItem value="created_at,desc">
													Created at: from newest to oldest
												</MenuItem>
												{/* <MenuItem value="modified_at,asc">
                    Modified at: from oldest to newest
                  </MenuItem>
                  <MenuItem value="modified_at,desc">
                    Modified at: from newest to oldest
                  </MenuItem>
                  <MenuItem value="activity_at,asc">
                    Activity at: from oldest to newest
                  </MenuItem>
                  <MenuItem value="activity_at,desc">
                    Activity at: from newest to oldest
                  </MenuItem> */}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
				<Grid
					container
					spacing={1}
					sx={{
						fontSize: 'small',
						marginTop: '24px',
						marginBottom: '24px',
					}}
				>
					<Grid
						item
						xs={12}
						md={9}
						sx={{
							display: 'flex',
							justifyContent: { xs: 'flex-start' },
							gap: 1,
							flexWrap: 'wrap',
						}}
					>
						<Grid item>
							<HasAccess
								roles={defaultRoles}
								permissions="task.create"
								renderAuthFailed={
									<StandardButton
										type="disabled"
										value="Create prediction"
										icon={<AddIcon />}
									/>
								}
							>
								<StandardButton
									type={
										!Object.keys(productionAIReleaseState).length > 0
											? 'disabled'
											: ''
									}
									value="Create prediction"
									handleClick={openCreatePredictionModal}
									icon={<AddIcon />}
								/>
							</HasAccess>
						</Grid>
						<Grid item>
							<StandardButton
								value="Open stats"
								handleClick={handleOpenStatsModal}
								icon={<PieChart />}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						sx={{
							display: 'flex',
							justifyContent: { xs: 'flex-start', md: 'flex-end' },
							flexWrap: 'wrap',
						}}
					>
						<StandardButton
							value="Fetch predictions"
							handleClick={getPredictionsWithFilters}
							icon={<Cached />}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						sx={{
							width: { xs: '100%', md: '150px' },
							display: 'flex',
							gap: 1,
							justifyContent: { xs: 'flex-start' },
							fontSize: '13px',
							fontWeight: 600,
							letterSpacing: '0.14px',
							color: '#1492E6',
						}}
					>
						Icons color legend:
					</Grid>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							display: 'flex',
							gap: 4,
							color: '#545454',
							fontSize: '13px',
							fontWeight: 600,
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskInput,
									marginRigth: '12px',
								}}
							/>
							Inputs
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskMetaData,
									marginRigth: '12px',
								}}
							/>
							Metadata
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskTarget,
									marginRigth: '12px',
								}}
							/>
							Predicted anomalies
						</div>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ marginBottom: '12px' }}>
					<div style={styles.examplesFound}>
						<div style={styles.examplesFoundTitle}>Predictions:</div>
						<div style={styles.examplesFoundCount}>
							{totalPredictionsState} predictions found
						</div>
					</div>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={styles.tableContainer}>
							<AIPredictionsTable
								rows={rows}
								currentColumns={currentColumns}
								setCurrentCellId={setCurrentCellId}
								setCurrentRowId={setCurrentRowId}
								currentRowId={currentRowId}
								openEditExampleModal={openEditPredictionModal}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								filters={filters}
								setFilters={setFilters}
							/>
						</Box>
					</Grid>
					<CustomPagination
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						total={totalPredictionsState}
					/>
				</Grid>
			</Container>
			{openCreatePredictionModal && (
				<CreateOrUpdateAIPredictionModal
					open={openCreatePredictionModal}
					setOpen={setOpenCreatePredictionModal}
					type="create"
					allColumns={allColumns}
					schemaState={schemaState}
					setCurrentRowId={setCurrentRowId}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openPredictionViewModal && (
				<PredictionViewModal
					open={openPredictionViewModal}
					setOpen={setOpenPredictionViewModal}
					currentRowId={currentRowId}
					setCurrentRowId={setCurrentRowId}
					currentCellId={currentCellId}
					setCurrentCellId={setCurrentCellId}
					allColumns={allColumns}
					rows={rows}
					setSelectedRows={setSelectedRows}
					styles={styles}
				/>
			)}
			{openEditViewModal && (
				<EditViewModal
					open={openEditViewModal}
					setOpen={setOpenEditViewModal}
					allColumns={allColumns}
					currentColumns={currentColumns}
					setCurrentColumns={setCurrentColumns}
				/>
			)}
			{openQueryBuilderModal && (
				<QueryBuilderModal
					open={openQueryBuilderModal}
					setOpen={setOpenQueryBuilderModal}
					allColumns={allColumns}
					query={query}
					setQuery={setQuery}
					filters={filters}
					setFilters={setFilters}
				/>
			)}
			{openStatsModal && (
				<MetricsModal open={openStatsModal} setOpen={setOpenStatsModal} />
			)}
		</HasAccess>
	);
}
