import { FormControlLabel, Select, Switch, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

// Components
import { ImageFileEditor } from './Editors/ImageFile.editor';
import { TextEditor } from './Editors/Text.editor';
import { UploadButton } from '../../../../Components/Shared/Buttons/UploadButton';
import { Loader } from '../../../../Components/Shared/Loader';
import { CategoryEditor } from './Editors/Category.editor';
import { DocumentFileEditor } from './Editors/DocumentFile.editor';
import { AnomalyEditor } from './Editors/Anomaly.editor';
import { TimeSerieEditor } from './Editors/TimeSeries.editor';
import { StatusEditor } from './Editors/Status.editor';

export const getElementToEdit = {
	number: ({
		styles,
		currentElement,
		currentCellId,
		currentRowId,
		currentElementValueType,
		currentValue,
		setCurrentValue,
		name,
		disabled,
	}) => (
		<TextEditor
			styles={styles}
			currentElement={currentElement}
			currentCellId={currentCellId}
			currentRowId={currentRowId}
			currentElementValueType={currentElementValueType}
			currentValue={currentValue}
			setCurrentValue={setCurrentValue}
			name={name}
			disabled={disabled}
		/>
	),
	text: ({
		styles,
		currentElement,
		currentCellId,
		currentRowId,
		currentElementValueType,
		currentValue,
		setCurrentValue,
		name,
		disabled,
		handleOpenFileViewer,
		predictionsView,
	}) => (
		<TextEditor
			styles={styles}
			currentElement={currentElement}
			currentCellId={currentCellId}
			currentRowId={currentRowId}
			currentElementValueType={currentElementValueType}
			currentValue={currentValue}
			setCurrentValue={setCurrentValue}
			name={name}
			disabled={disabled}
			predictionsView={predictionsView}
			handleOpenFileViewer={handleOpenFileViewer}
		/>
	),
	category: ({
		styles,
		currentElement,
		currentCellId,
		currentElementType,
		currentValue,
		setCurrentValue,
		categoriesState,
		name,
		disabled,
	}) => {
		const currentCategories = categoriesState.find(
			(cat) => cat.name === currentCellId
		);

		if (currentCategories) {
			return (
				<CategoryEditor
					styles={styles}
					currentElement={currentElement}
					currentCellId={currentCellId}
					currentElementType={currentElementType}
					currentValue={currentValue}
					setCurrentValue={setCurrentValue}
					name={name}
					currentCategories={currentCategories}
					disabled={disabled}
				/>
			);
		}
	},
	document: ({
		currentElement,
		currentCellId,
		allColumns,
		setCurrentValue,
		predictionsView,
	}) =>
		currentElement && Object.keys(currentElement).length > 0 ? (
			<DocumentFileEditor cellValue={currentElement.value} />
		) : (
			<UploadButton
				type="document"
				currentCellId={currentCellId}
				allColumns={allColumns}
				setCurrentValue={setCurrentValue}
				predictionsView={predictionsView}
			/>
		),
	image: ({
		currentElement,
		currentCellId,
		currentRowId,
		allColumns,
		setCurrentValue,
		disabled = false,
		handleOpenFileViewer,
		predictionsView,
		testView,
	}) =>
		currentElement && Object.keys(currentElement).length > 0 ? (
			<ImageFileEditor
				currentElement={currentElement}
				currentCellId={currentCellId}
				currentRowId={currentRowId}
				disabled={disabled}
				handleOpenFileViewer={handleOpenFileViewer}
				predictionsView={predictionsView}
				testView={testView}
			/>
		) : (
			<UploadButton
				type="image"
				currentCellId={currentCellId}
				allColumns={allColumns}
				setCurrentValue={setCurrentValue}
			/>
		),
	anomalies: ({
		currentElement,
		currentCellId,
		currentRowId,
		currentElementValueType,
		currentValue,
		setCurrentValue,
		name,
		disabled,
		predictionsView,
	}) => (
		<AnomalyEditor
			currentElement={currentElement}
			currentCellId={currentCellId}
			currentRowId={currentRowId}
			currentElementValueType={currentElementValueType}
			currentValue={currentValue}
			setCurrentValue={setCurrentValue}
			name={name}
			disabled={disabled}
			predictionsView={predictionsView}
		/>
	),
	time_series: ({
		currentElement,
		currentCellId,
		currentRowId,
		currentElementValueType,
		currentValue,
		setCurrentValue,
		name,
		disabled,
		predictionsView,
		testView,
		handleOpenFileViewer,
	}) => (
		<TimeSerieEditor
			currentElement={currentElement}
			currentCellId={currentCellId}
			currentRowId={currentRowId}
			currentElementValueType={currentElementValueType}
			currentValue={currentValue}
			setCurrentValue={setCurrentValue}
			name={name}
			disabled={disabled}
			handleOpenFileViewer={handleOpenFileViewer}
			predictionsView={predictionsView}
			testView={testView}
		/>
	),
	status_label: ({
		styles,
		currentElement,
		currentCellId,
		currentRowId,
		currentElementValueType,
		currentValue,
		setCurrentValue,
		name,
		disabled,
		predictionsView,
	}) => (
		<StatusEditor
			styles={styles}
			currentElement={currentElement}
			currentCellId={currentCellId}
			currentRowId={currentRowId}
			currentElementValueType={currentElementValueType}
			currentValue={currentValue}
			setCurrentValue={setCurrentValue}
			name={name}
			disabled={disabled}
			predictionsView={predictionsView}
		/>
	),
};
