import React from 'react';
import PropTypes from 'prop-types';

// Components
import { TextField } from '@mui/material';

// Styles
import { styles } from './styles';

// import styles from './styles.module.css';

export const TextInput = ({ ...props }) => (
	<TextField
		variant="outlined"
		style={styles.companyNameInput}
		size="small"
		hiddenLabel
		onChange={props.onChange}
		{...props}
	/>
);

TextInput.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func,
};
