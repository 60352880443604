import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Box } from '@mui/system';
import {
	Collapse,
	IconButton,
	List,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';

// Styles
import { styles } from './styles';
import { colors } from '../../../../../../consts/colors';

// Utils
import {
	addObjectSelectionFromShapeChips,
	onDeleteShape,
} from '../../Editors/ImageFileEditor.services';
import {
	imageShapeToPolygonThumbnail,
	imageShapeToThumbnail,
} from '../../../../../../services/imageShapeToThumbnail';
import { Loader } from '../../../../../../Components/Shared/Loader';

const ChipShapesList = ({
	currentCellId,
	selectedShape,
	setSelectedShape,
	editor,
	handleDeleteAnomaly,
	currentElementShapes,
	imageUrl,
	onDeleteShapeProps,
	setIsLoading,
}) => {
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const [showAnomalyList, setShowAnomalyList] = useState(true);
	// const [thumbnails, setThumbnails] = useState([]);

	useEffect(() => {
		if (showAnomalyList && currentElementShapes?.length > 0) {
			currentElementShapes.forEach((shape) => {
				imageShapeToThumbnail({ shape, imageUrl });

				// setThumbnails([
				// 	...thumbnails,
				// 	{ [`shape-${shape.uuid}`]: imageShapeToPolygonThumbnail({ shape }) },
				// ]);
			});
		}
	}, [currentElementShapes, showAnomalyList, onDeleteShape]);

	useEffect(() => {
		addObjectSelectionFromShapeChips({
			editor,
			selectedShape,
		});
	}, [selectedShape]);

	const handleSelectShape = (shapeUUID) => {
		setSelectedShape(shapeUUID);
	};

	const handleDeleteShape = (shapeUUID) => {
		onDeleteShape({
			...onDeleteShapeProps,
			selectedShapeOutput: shapeUUID,
		});
	};

	return (
		<List
			component="nav"
			subheader={
				<Box
					onClick={() => setShowAnomalyList(!showAnomalyList)}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					{showAnomalyList ? <ExpandLess /> : <ExpandMore />}
					<Typography
						variant="body1"
						component="div"
						sx={{
							width: '100%',
							fontWeight: 'bold',
							color: 'text.primary',
						}}
					>
						Shapes list:
					</Typography>
				</Box>
			}
			sx={{
				mt: 2,
			}}
		>
			<Collapse in={showAnomalyList} timeout="auto" unmountOnExit>
				{currentElementShapes && currentElementShapes.length > 0 && (
					<Box sx={styles.shapesChipsContainer}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow
										sx={{
											borderBottom: `2px solid ${colors.lightBorderColor}`,
										}}
									>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Anomaly preview
										</TableCell>
										{/* <TableCell
											className={classes.tableTitle}
											align="center"
											width={100}
										>
											Shape preview
										</TableCell> */}
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Anomalies
										</TableCell>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Delete
										</TableCell>
									</TableRow>
								</TableHead>
								{currentElementShapes.map((shape) => (
									<TableBody>
										<TableRow
											key={shape.uuid}
											sx={{
												cursor: 'pointer',
												backgroundColor:
													selectedShape === shape.uuid ||
													selectedShape === shape.id
														? colors.ligthBlue
														: 'none',
												'&:hover': {
													backgroundColor: colors.ligthBlue,
												},
											}}
											onClick={() => handleSelectShape(shape.uuid)}
										>
											<TableCell sx={styles.tableTitle} align="center">
												{imageUrl && shape.polygon && (
													<canvas id={shape.uuid} width={50} height={50} />
												)}
											</TableCell>
											{/* <TableCell className={classes.tableTitle} align="center">
												{imageUrl && shape.polygon && (
													<svg
														id={`shape-${shape.uuid}`}
														width={50}
														height={50}
													/>
												)}
											</TableCell> */}
											<TableCell
												sx={{
													...styles.tableTitle,
													...styles.tableAnomalyType,
												}}
												align="center"
											>
												{shape.outputs &&
													shape.outputs.length > 0 &&
													shape.outputs.map(
														(output) =>
															anomalyTypesState?.find(
																(anomaly) =>
																	anomaly.name.toLowerCase() === output.element
															)?.threshold <= output.value && (
																<AnomalyChip
																	name={output.element}
																	color={
																		anomalyTypesState?.find(
																			(anomaly) =>
																				anomaly.name.toLowerCase() ===
																				output.element
																		)?.color
																	}
																	tooltip
																/>
															)
													)}
											</TableCell>
											<TableCell sx={styles.tableTitle} align="center">
												<IconButton
													style={{ margin: 0, padding: 0 }}
													onClick={() => handleDeleteShape(shape.uuid)}
												>
													<DeleteForeverOutlinedIcon sx={{ height: '20px' }} />
												</IconButton>
											</TableCell>
										</TableRow>
									</TableBody>
								))}
							</Table>
						</TableContainer>
					</Box>
				)}
			</Collapse>
		</List>
	);
};

export default ChipShapesList;

ChipShapesList.propTypes = {
	currentCellId: PropTypes.string,
	selectedShape: PropTypes.string,
	setSelectedShape: PropTypes.func,
	handleDeleteAnomaly: PropTypes.func,
	editor: PropTypes.object,
	currentElementShapes: PropTypes.array,
	imageUrl: PropTypes.string,
	onDeleteShapeProps: PropTypes.object,
	setIsLoading: PropTypes.bool,
};
