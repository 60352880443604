import PhotoIcon from '@mui/icons-material/Photo';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HexagonIcon from '@mui/icons-material/Hexagon';
import PinIcon from '@mui/icons-material/Pin';
import TimelineIcon from '@mui/icons-material/Timeline';

import { colors } from '../consts/colors';

const dataTypeToColorMap = {
	input: colors.taskInput,
	target: colors.taskOutput,
	metadata: colors.taskMetaData,
	group: colors.taskGroups,
	anomaly: colors.taskOutput,
};

export const elementTypeToIconMap = {
	integer: (type) => (
		<PinIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	number: (type) => (
		<PinIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	boolean: (type) => (
		<PinIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	datetime: (type) => (
		<CalendarMonthIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	float: (type) => (
		<PinIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	text: (type) => (
		<FontDownloadIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	category: (type) => (
		<CategoryIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	document: (type) => (
		<InsertDriveFileIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	image: (type) => (
		<PhotoIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	shape: (type) => (
		<HexagonIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
	time_series: (type) => (
		<TimelineIcon
			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
		/>
	),
};

export const dataSelectorToNodeType = {
	input: 'inputs',
	target: 'targets',
	'meta-dat': 'metadata',
	group: 'groups',
};

// export const elementTypeToIconMap = {
// 	GenericFile: (type) => (
// 		<InsertDriveFileIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	ImageFile: (type) => (
// 		<PhotoIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Char: (type) => (
// 		<ArticleIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	JSON: (type) => (
// 		<ArticleIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	GenericIPAddress: (type) => (
// 		<ArticleIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	URL: (type) => (
// 		<ArticleIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	AudioFile: (type) => (
// 		<AudioFileIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	VideoFile: (type) => (
// 		<VideocamIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Text: (type) => (
// 		<FontDownloadIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Email: (type) => (
// 		<FontDownloadIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Integer: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Float: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	SmallInteger: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	BigInteger: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	// TODO: not included for now
// 	Binary: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Slug: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Decimal: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	PositiveBigInteger: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	PositiveInteger: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	PositiveSmallInteger: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Boolean: (type) => (
// 		<LooksOneIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Category: (type) => (
// 		<CategoryIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Date: (type) => (
// 		<CalendarMonthIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	DateTime: (type) => (
// 		<CalendarMonthIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Time: (type) => (
// 		<CalendarMonthIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Duration: (type) => (
// 		<CalendarMonthIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	Shape: (type) => (
// 		<HexagonIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	null: (type) => (
// 		<HexagonIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// 	undefined: (type) => (
// 		<HexagonIcon
// 			sx={{ fill: dataTypeToColorMap[type], color: dataTypeToColorMap[type] }}
// 		/>
// 	),
// };
