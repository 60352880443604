import { Grid, Input, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../../../consts/colors';
import StandardButton from '../../../../../Components/Shared/Buttons/StandardButton';
// import { UPDATE_EXAMPLE } from '../../../../../redux/examples.slice';

export const TextEditor = ({
	styles,
	currentElement,
	currentElementValueType,
	currentValue,
	setCurrentValue = null,
	name = null,
	disabled = false,
	predictionsView = false,
	handleOpenFileViewer,
}) => {
	useEffect(() => {
		if (setCurrentValue)
			if (currentElement) setCurrentValue(currentElement.value);
	}, [currentElement || currentValue]);

	const handleChange = (e) => {
		if (setCurrentValue)
			if (!name) setCurrentValue(e.target.value);
			else setCurrentValue(name, e.target.value);
	};

	if (currentElementValueType === 'number' && currentValue !== '') {
		return (
			<Input
				sx={styles.textField}
				value={currentValue || currentElement}
				onChange={handleChange}
				type="number"
				disabled={disabled}
			/>
		);
	}

	return (
		<>
			{predictionsView && (
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '0.8rem',
						color: colors.blue,
						marginBottom: '2rem',
					}}
				>
					<StandardButton
						value="Back to table"
						handleClick={handleOpenFileViewer}
					/>
				</Grid>
			)}
			<TextField
				multiline
				rows={!predictionsView ? 5 : 15}
				maxRows={10}
				inputProps={{ maxLength: 32000 }}
				value={currentValue || currentElement.value}
				onChange={handleChange}
				disabled={disabled}
			/>
		</>
	);
};

TextEditor.propTypes = {
	styles: PropTypes.object,
	currentElement: PropTypes.object,
	currentElementValueType: PropTypes.string,
	currentValue: PropTypes.any,
	setCurrentValue: PropTypes.func,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	predictionsView: PropTypes.bool,
	handleOpenFileViewer: PropTypes.func,
};
