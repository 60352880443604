/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Box, FormControl, Grid } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';

import { onAddBoxShape, onDrawPolygon } from '../ImageFileEditor.services';

import { UPDATE_SHAPE } from '../../../../../../redux/examples.slice';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';
import { colors } from '../../../../../../consts/colors';

const SelectTargetModal = ({
	onClose,
	selectedShape,
	selectedShapeOutput,
	setSelectedShapeOutput,
	open,
	editor,
	boxShape,
	scalingFactor,
	containerWidth,
	containerHeight,
	currentElement,
	isPolygonMode,
	setIsPolygonMode,
	isDrawingPolygon,
	fabric,
	currentRowId,
	zoom,
	isShapeUpdate,
	setIsShapeUpdate,
	selectedOutputs,
	setSelectedOutputs,
	imgWidth,
	imgHeight,
}) => {
	const dispatch = useDispatch();

	const { accessToken } = useSelector((state) => state.user);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);
	const {
		examples: examplesState,
		currentExample: currentExampleState,
		currentShapes: currentShapesState,
	} = useSelector((state) => state.examples);

	const [isLoading, setIsLoading] = useState(false);

	const [currentShape, setCurrentShape] = useState({});

	useEffect(() => {
		setSelectedOutputs([]);
	}, []);

	useEffect(() => {
		if (currentShape !== '')
			setCurrentShape(
				currentShapesState.find((shape) => shape.id === selectedShape)
			);
		setSelectedOutputs(
			anomalyTypesState.map((anomaly) => ({
				element: anomaly.name,
				value:
					currentShapesState
						.find((shape) => shape.id === selectedShape)
						?.outputs?.find((output) => output.element === anomaly.name)
						?.value || 0,
			}))
		);
	}, [selectedShape, currentShape]);

	const handleCancel = () => {
		setIsShapeUpdate(false);
		onClose(false);
		setIsLoading(false);
	};

	const handleOk = () => {
		setIsLoading(true);
		if (selectedShapeOutput.name !== '') {
			if (isPolygonMode) {
				onDrawPolygon({
					editor,
					isDrawingPolygon,
					fabric,
				});
			} else {
				onAddBoxShape({
					editor,
					fabric,
					selectedShapeOutput,
					scalingFactor,
					containerWidth,
					containerHeight,
					currentElement,
					dispatch,
					currentRowId,
					taskId: currentTaskState.uuid,
					exampleId: currentExampleState.uuid,
					accessToken,
					zoom,
					currentExampleState,
					examplesState,
					selectedOutputs,
					imgWidth,
					imgHeight,
				});
			}
			handleCancel();
		}
	};

	const handleUpdate = async () => {
		setIsLoading(true);
		await dispatch(
			UPDATE_SHAPE({
				taskId: currentTaskState.id,
				exampleId: currentExampleState.id,
				shapeId: currentShape.id,
				newShape: {
					...currentShape,
					outputs: selectedOutputs.map((output) => ({
						element: output.element,
						value: output.value,
					})),
				},
				dispatch,
				accessToken,
			})
		);
		handleCancel();
	};

	const handleChangeAnomaly = ({ e }) => {
		const { checked, name } = e.target;

		setSelectedOutputs(
			selectedOutputs.map((output) =>
				output.element === name
					? { ...output, value: checked ? 1.0 : 0.0 }
					: { ...output, value: 0.0 }
			)
		);
	};

	return (
		<StandardModal
			maxWidth="sm"
			open={open}
			setOpen={onClose}
			title="Select shape"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center',
						'& > *': {
							minWidth: '100px',
						},
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<FormControl>
							<RadioGroup
								name="radio-buttons-group"
								onChange={(e) =>
									handleChangeAnomaly({
										e,
									})
								}
							>
								{anomalyTypesState.map((anomaly) => (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<FormControlLabel
											name={anomaly.name}
											value={anomaly.name}
											control={<Radio />}
										/>
										<AnomalyChip
											tooltip
											name={anomaly.name}
											color={
												selectedOutputs.find(
													(output) =>
														output.value === 1 &&
														output.element === anomaly.name
												)?.value > 0
													? anomaly.color
													: colors.ligthGray
											}
										/>
									</div>
								))}
							</RadioGroup>
						</FormControl>
					</Box>
				</Grid>
			}
			actions={
				<>
					<StandardButton handleClick={handleOk} value="Create" />
					<StandardButton close handleClick={handleCancel} value="Cancel" />
				</>
			}
		/>
	);
};

SelectTargetModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedShape: PropTypes.string,
	selectedShapeOutput: PropTypes.object,
	setSelectedShapeOutput: PropTypes.func,
	selectedOutputs: PropTypes.array,
	setSelectedOutputs: PropTypes.func,
	editor: PropTypes.object,
	boxShape: PropTypes.object,
	scalingFactor: PropTypes.number,
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number,
	currentElement: PropTypes.object,
	isPolygonMode: PropTypes.bool,
	setIsPolygonMode: PropTypes.func,
	isDrawingPolygon: PropTypes.object,
	fabric: PropTypes.object,
	currentRowId: PropTypes.string,
	zoom: PropTypes.any,
	isShapeUpdate: PropTypes.bool,
	setIsShapeUpdate: PropTypes.func,
	imgWidth: PropTypes.number,
	imgHeight: PropTypes.number,
};

export default SelectTargetModal;
