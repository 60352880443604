/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Box from '@mui/material/Box';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';

// Styles
import { styles } from './styles';

import { getElementToEdit } from '../../elementEditor.service';
import {
	GET_EXAMPLES,
	UPDATE_EXAMPLE,
} from '../../../../../../redux/examples.slice';

// Redux

export const EditElementModal = ({
	open,
	setOpen,
	currentCellId,
	currentRowId,
	setCurrentRowId,
	setCurrentCellId,
	allColumns,
	disabled = false,
	testView,
	setSelectedRows,
}) => {
	const dispatch = useDispatch();

	const { isLoading: isLoadingSchemaState, categories: categoriesState } =
		useSelector((state) => state.schema);
	const schemaState = useSelector((state) => state.schema);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { examples: examplesState, currentExample: currentExampleState } =
		useSelector((state) => state.examples);
	const { tests: testsState, currentTest: currentTestState } = useSelector(
		(state) => state.tests
	);
	const { accessToken } = useSelector((state) => state.user);

	const [currentElement, setCurrentElement] = useState({});
	const [currentElementValueType, setCurrentElementValueType] = useState('');
	const [currentValue, setCurrentValue] = useState('');
	const [currentElementType, setCurrentElementType] = useState('');

	useEffect(() => {
		if (Object.keys(schemaState).length > 0 && currentCellId !== '') {
			// Check inputs
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.name === currentCellId) {
						setCurrentElementValueType(input.type);
						setCurrentElementType('inputs');
					}
				});
			}
			// Check metadata
			if (schemaState.metadata && schemaState.metadata.length > 0) {
				schemaState.metadata.forEach((meta) => {
					if (meta.name === currentCellId) {
						setCurrentElementValueType(meta.type);
						setCurrentElementType('metadata');
					}
				});
			}
			// Check outputs
			if (schemaState.outputs && schemaState.outputs.length > 0) {
				schemaState.outputs.forEach((output) => {
					if (output.name === currentCellId) {
						setCurrentElementValueType(output.type);
						setCurrentElementType('outputs');
					}
				});
			}
			// Check anomalies
			if (schemaState.anomaly_types && schemaState.anomaly_types.length > 0) {
				if (currentCellId === 'anomalies') {
					setCurrentElementValueType('anomalies');
					setCurrentElementType('anomaly');
				}
			}

			// EXAMPLES
			if (currentExampleState.uuid) {
				if (
					currentExampleState.inputs &&
					currentExampleState.inputs.find(
						(input) => input.name === currentCellId
					)
				)
					currentExampleState.inputs.forEach((input) => {
						if (input.name === currentCellId) setCurrentElement(input);
					});

				if (
					currentExampleState.metadata &&
					currentExampleState.metadata.find(
						(meta) => meta.name === currentCellId
					)
				)
					currentExampleState.metadata.forEach((meta) => {
						if (meta.name === currentCellId) setCurrentElement(meta);
					});

				if (
					currentExampleState.outputs &&
					currentExampleState.outputs.find(
						(output) => output.name === currentCellId
					)
				)
					currentExampleState.outputs.forEach((output) => {
						if (output.name === currentCellId) setCurrentElement(output);
					});

				if (
					currentExampleState.anomaly_types &&
					currentExampleState.anomaly_types.length > 0 &&
					currentCellId === 'anomalies'
				) {
					setCurrentElement(currentExampleState.anomaly_types);
				}
			}

			// TESTS
			if (currentTestState.uuid) {
				if (
					currentTestState.inputs &&
					currentTestState.inputs.find((input) => input.name === currentCellId)
				)
					currentTestState.inputs.forEach((input) => {
						if (input.name === currentCellId) setCurrentElement(input);
					});

				if (
					currentTestState.metadata &&
					currentTestState.metadata.find((meta) => meta.name === currentCellId)
				)
					currentTestState.metadata.forEach((meta) => {
						if (meta.name === currentCellId) setCurrentElement(meta);
					});

				if (
					currentTestState.targets &&
					currentTestState.targets.find(
						(target) => target.name === currentCellId
					)
				)
					currentTestState.targets.forEach((target) => {
						if (target.name === currentCellId) setCurrentElement(target);
					});

				if (
					currentTestState.outputs &&
					currentTestState.outputs.length > 0 &&
					currentCellId === 'anomalies'
				) {
					setCurrentElement(currentExampleState.outputs);
				}
			}
		}
	}, [currentCellId]);

	useEffect(() => {
		if (currentCellId !== '' && currentRowId !== '') {
			// EXAMPLES
			if (currentExampleState.uuid) {
				examplesState.forEach((example) => {
					if (example.id === currentRowId) {
						// Check inputs
						if (
							example.inputs &&
							example.inputs.length > 0 &&
							example.inputs.find((input) => input.element === currentCellId)
						) {
							setCurrentElement(
								example.inputs.find((input) => input.element === currentCellId)
							);
						}
						// Check metadata
						if (
							example.metadata &&
							example.metadata.length > 0 &&
							example.metadata.find((meta) => meta.element === currentCellId)
						) {
							setCurrentElement(
								example.metadata.find((meta) => meta.element === currentCellId)
							);
						}
						// Check outputs
						if (
							example.outputs &&
							example.outputs.length > 0 &&
							example.outputs.find((output) => output.element === currentCellId)
						) {
							setCurrentElement(
								example.outputs.find(
									(output) => output.element === currentCellId
								)
							);
						}
						// Check anomalies
						if (
							example.anomaly_types &&
							example.anomaly_types.length > 0 &&
							example.anomaly_types.find(
								(anomaly) => anomaly.element === currentCellId
							)
						) {
							setCurrentElement(example.anomaly_types);
						}
					}
				});
			}

			// TESTS
			if (currentTestState.uuid) {
				testsState.forEach((test) => {
					if (test.id === currentRowId) {
						// Check inputs
						if (
							test.inputs &&
							test.inputs.length > 0 &&
							test.inputs.find((input) => input.element === currentCellId)
						) {
							setCurrentElement(
								test.inputs.find((input) => input.element === currentCellId)
							);
						}
						// Check metadata
						if (
							test.metadata &&
							test.metadata.length > 0 &&
							test.metadata.find((meta) => meta.element === currentCellId)
						) {
							setCurrentElement(
								test.metadata.find((meta) => meta.element === currentCellId)
							);
						}
						// Check targets
						if (currentCellId === 'anomalies') {
							setCurrentElement(test.targets);
						}
						// Check anomalies
						if (
							test.anomaly_types &&
							test.anomaly_types.length > 0 &&
							test.anomaly_types.find(
								(anomaly) => anomaly.element === currentCellId
							)
						) {
							setCurrentElement(test.anomaly_types);
						}
					}
				});
			}
		}
	}, [currentCellId, currentRowId]);

	const handleClose = () => {
		setCurrentCellId('');
		setCurrentRowId('');
		setOpen(false);
		setCurrentValue('');
		setSelectedRows([]);
	};

	const handleSave = async () => {
		let tmpUpdate = {};

		tmpUpdate = {
			labeling_status: currentExampleState.labeling_status,
			values: [],
		};

		if (currentCellId !== 'status_label' && currentCellId !== 'anomalies')
			tmpUpdate = {
				values: [
					{
						element: currentCellId,
						value:
							currentElementValueType === 'integer'
								? parseInt(currentValue)
								: currentElementValueType === 'float'
								? parseFloat(currentValue)
								: currentValue,
					},
				],
			};

		if (currentCellId === 'status_label')
			tmpUpdate = { ...tmpUpdate, labeling_status: currentValue };
		const tmpExample = examplesState.find(
			(example) => example.id === currentRowId
		);

		if (tmpExample) {
			// INPUTS
			if (currentExampleState.inputs && currentExampleState.inputs.length > 0) {
				currentExampleState.inputs.forEach((input) => {
					if (input.element !== currentCellId) {
						tmpUpdate = {
							...tmpUpdate,
							values: [...tmpUpdate.values, input],
						};
					}
				});
			}

			// METADATA
			if (
				currentExampleState.metadata &&
				currentExampleState.metadata.length > 0
			) {
				currentExampleState.metadata.forEach((meta) => {
					if (meta.element !== currentCellId) {
						tmpUpdate = {
							...tmpUpdate,
							values: [...tmpUpdate.values, meta],
						};
					}
				});
			}

			// OUTPUTS
			if (
				currentExampleState.outputs &&
				currentExampleState.outputs.length > 0
			) {
				currentExampleState.outputs.forEach((output) => {
					if (currentCellId !== 'anomalies') {
						tmpUpdate = {
							...tmpUpdate,
							values: [...tmpUpdate.values, output],
						};
					} else {
						tmpUpdate = {
							...tmpUpdate,
							values: [...tmpUpdate.values, ...currentValue],
						};
					}
				});
			}
		}

		function getUniqueListBy(arr, key) {
			return [...new Map(arr.map((item) => [item[key], item])).values()];
		}

		const tmp = getUniqueListBy(tmpUpdate.values, 'element');

		await dispatch(
			UPDATE_EXAMPLE({
				taskId: currentTaskState.id,
				exampleId: currentRowId,
				examplesToUpdate: { ...tmpUpdate, values: tmp },
				dispatch,
				accessToken,
			})
		);

		await dispatch(
			GET_EXAMPLES({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				schemaState,
			})
		);

		handleClose();
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title={
				!testView &&
				`Edit element: ${
					(currentElement.element && `(${currentElement.element})`) || ''
				}`
			}
			content={
				<Box sx={styles.dialogContentContainer}>
					{
						// STATUS LABEL
						currentCellId === 'status_label' &&
							currentElement &&
							getElementToEdit['status_label']({
								styles,
								currentElement,
								currentCellId,
								currentRowId,
								currentElementValueType,
								currentValue,
								setCurrentValue,
								disabled,
							})
					}
					{
						// NUMBER TYPE
						currentElementValueType === 'number' &&
							currentElement &&
							getElementToEdit[currentElementValueType]({
								styles,
								currentElement,
								currentCellId,
								currentRowId,
								currentElementValueType,
								currentValue,
								setCurrentValue,
								disabled,
							})
					}
					{
						// TEXT TYPE
						currentElementValueType === 'text' &&
							currentElement &&
							getElementToEdit[currentElementValueType]({
								styles,
								currentElement,
								currentCellId,
								currentRowId,
								currentElementValueType,
								currentValue,
								setCurrentValue,
								disabled,
							})
					}
					{
						// CATEGORY TYPE
						currentElementValueType === 'category' &&
							currentElement &&
							getElementToEdit[currentElementValueType]({
								styles,
								currentElement,
								currentCellId,
								currentRowId,
								categoriesState,
								currentElementType,
								currentValue,
								setCurrentValue,
								isLoadingSchemaState,
								disabled,
							})
					}
					{
						// DOCUMENT FILE TYPE
						currentElementValueType === 'document' &&
							currentElement &&
							getElementToEdit[currentElementValueType]({
								currentElement,
								currentCellId,
								currentRowId,
								allColumns,
								setCurrentValue,
								disabled,
							})
					}
					{
						// IMAGE TYPE
						currentElementValueType === 'image' &&
							currentElement &&
							getElementToEdit[currentElementValueType]({
								currentElement,
								currentCellId,
								currentRowId,
								allColumns,
								setCurrentValue,
								disabled,
								testView,
							})
					}
					{
						// ANOMALY TYPE
						currentElementValueType === 'anomalies' &&
							getElementToEdit[currentElementValueType]({
								currentElement,
								currentCellId,
								currentRowId,
								allColumns,
								currentValue,
								setCurrentValue,
								disabled,
							})
					}
					{
						// TIME SERIE TYPE
						currentElementValueType === 'time_series' &&
							getElementToEdit[currentElementValueType]({
								currentElement,
								currentCellId,
								currentRowId,
								allColumns,
								currentValue,
								setCurrentValue,
								disabled,
								testView,
							})
					}
				</Box>
			}
			actions={
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'right',
						gap: '12px',
					}}
				>
					{!disabled &&
						currentElementValueType !== 'time_series' &&
						currentElementValueType !== 'image' &&
						currentElementValueType !== 'document' && (
							<StandardButton
								value="Save"
								type="filled"
								handleClick={handleSave}
								close
							/>
						)}
					<StandardButton value="Close" handleClick={handleClose} close />
				</div>
			}
		/>
	);
};

EditElementModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	currentCellId: PropTypes.string,
	setCurrentCellId: PropTypes.func,
	currentRowId: PropTypes.string,
	setCurrentRowId: PropTypes.func,
	allColumns: PropTypes.any,
	disabled: PropTypes.bool,
	testView: PropTypes.bool,
	setSelectedRows: PropTypes.array,
};
