import React from 'react';
import PropTypes from 'prop-types';

// import { PieChart, Pie, Cell } from 'recharts';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
// import { getRandomColor } from '../../../../services/getRandomColor';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const COLORS = ['#c8e6c9', '#e57373'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index,
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="black"
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const StandardPieChart = (props) => {
	const { data } = props;

	return (
		<Grid
			container
			sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
		>
			<PieChart width={300} height={200}>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={80}
					fill="#8884d8"
					dataKey="value"
					isAnimationActive
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Tooltip />
			</PieChart>
			<Grid
				item
				xs={6}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						gap: 3,
						alignItems: 'center',
						fontSize: '1.2rem',
						fontWeight: 'bold',
					}}
				>
					Total: {data.reduce((partialSum, a) => partialSum + a.value, 0)}
				</div>
				<div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
					<div
						style={{
							width: '15px',
							height: '15px',
							backgroundColor: '#c8e6c9',
						}}
					/>
					<div>Without anomalies</div>
				</div>
				<div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
					<div
						style={{
							width: '15px',
							height: '15px',
							backgroundColor: '#e57373',
						}}
					/>
					<div>With anomalies</div>
				</div>
			</Grid>
		</Grid>
	);
};

StandardPieChart.propTypes = {
	data: PropTypes.array,
};

export default StandardPieChart;
