import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import MaintenanceIcon from '@mui/icons-material/Build';
import maintenanceImage from '../../assets/media/maintenance.svg';

// Styles
import { styles } from './styles';
import { colors } from '../../consts/colors';

function MaintenancePage() {
	return (
		<Container>
			<Paper elevation={3} sx={styles.maintenance}>
				<MaintenanceIcon sx={{ fontSize: 64, color: colors.blue }} />
				<Typography variant="h4" color={colors.blue} gutterBottom>
					Website Maintenance
				</Typography>
				<Typography variant="body1" paragraph>
					Scheduled maintenance is currently in progress Please check back
					later.
				</Typography>
				<Typography variant="body1" paragraph>
					We apologize for any inconvenience.
				</Typography>
				<Typography variant="body1" paragraph>
					If you need immediate assistance, contact us at{' '}
					<a href="mailto:support@neuraptic.ai">support@neuraptic.ai</a>.
				</Typography>
				<img
					src={maintenanceImage}
					alt="Maintenance"
					style={styles.maintenanceImage}
				/>
			</Paper>
		</Container>
	);
}

export default MaintenancePage;
