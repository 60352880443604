import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { formatQuery } from 'react-querybuilder';

import requestFactory from '../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from './alerts.slice';
import {
	EXAMPLE_CREATED,
	SHAPE_UPDATED_SUCCESS,
} from '../AlertsList/examplesAlerts';
import { SET_LOADING_PROGRESS } from './loaders.slice';
import { getRandomAnomalies } from '../services/getRandomAnomalies';

// CREATE

export const CREATE_EXAMPLE = createAsyncThunk(
	'examples/CREATE_EXAMPLE',
	async ({ taskId, newExample, accessToken, dispatch }) => {
		console.log('examples/CREATE_EXAMPLE');
		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/examples`,
			data: newExample,
			accessToken,
			dispatch,
		});

		if (res) {
			dispatch(ADD_ALERT({ type: 'success', message: EXAMPLE_CREATED }));
			setTimeout(() => {
				dispatch(REMOVE_ALERT(EXAMPLE_CREATED));
			}, 2000);
		}
	}
);

export const CREATE_SHAPE = createAsyncThunk(
	'examples/CREATE_SHAPE',
	async ({ taskId, exampleId, accessToken, dispatch, newShape }) => {
		console.log('examples/CREATE_SHAPE');

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/examples/${exampleId}/shapes`,
			accessToken,
			dispatch,
			data: newShape,
		});

		if (res) return res;
	}
);

export const CREATE_SLICE = createAsyncThunk(
	'examples/CREATE_SLICE',
	async ({ taskId, exampleId, accessToken, dispatch, newSlice }) => {
		console.log('examples/CREATE_SLICE');

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/examples/${exampleId}/slices`,
			accessToken,
			dispatch,
			data: newSlice,
		});

		if (res) return res;
	}
);

// export const UPLOAD_FILE = createAsyncThunk(
// 	'examples/UPLOAD_FILE',
// 	async ({ taskHost, file }) => {
// 		console.log('examples/UPLOAD_FILE');
// 		const res = await requestFactory(
// 			'post',
// 			`/files/start_upload`,
// 			{
// 				file_name: file.name,
// 				file_type: file.type,
// 			},
// 			taskHost
// 		)
// 			.then((response) => {
// 				const formData = new FormData();
// 				Object.keys(response.fields).forEach((key) => {
// 					formData.append(key, response.fields[key]);
// 				});
// 				formData.append('file', file);

// 				fetch(response.url, {
// 					method: 'post',
// 					// mode: 'no-cors',
// 					body: formData,
// 				})
// 					.then((responseAWS) => console.log(responseAWS))
// 					.catch((error) => {
// 						console.log(error);
// 					});
// 			})
// 			.catch((error) => {
// 				console.log(error);
// 			});

// 		return res;
// 	}
// );

// GET
export const GET_EXAMPLES = createAsyncThunk(
	'examples/GET_EXAMPLES',
	async (props) => {
		console.log('examples/GET_EXAMPLES');

		// todo: query builder (Elastic Search)
		// const test = {match_all:{}} // 400 VALUE MUST BE A VALID JSON
		// const test = JSON.stringify(query) // 500 ERROR
		// const test = query; // 500 ERROR
		// const test = toString(query) // 400 VALUE MUST BE A VALID JSON
		// const test = new URLSearchParams(query) // 400 VALUE MUST BE A VALID JSON
		// const test = new URLSearchParams(query).toString() // 400 VALUE MUST BE A VALID JSON
		// const test = JSON.parse(query) // 400 VALUE MUST BE A VALID JSON
		// const test = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');

		// Get props, remove unnecesary ones for query and prepare the array
		const tmpProps = [];
		let isPage = false;
		let isPerPage = false;
		const { dispatch, schemaState } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				prop !== 'schemaState' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				if (prop === 'page') isPage = true;
				if (prop === 'per_page') isPerPage = true;
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';
		// Set the default options
		if (isPage)
			query += `page=${tmpProps.find((prop) => prop['page'])['page']}`;
		else query += 'page=1';
		if (isPerPage)
			query += `&per_page=${
				tmpProps.find((prop) => prop['per_page'])['per_page']
			}`;
		else query += '&per_page=25';
		query += '&total_count=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query'
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'get',
			url: `/tasks/${props.taskId}/examples${query}`,
			accessToken: props.accessToken,
			dispatch: props.dispatch,
		});
		if (res) return { res, schemaState };

		dispatch(
			SET_LOADING_PROGRESS({
				progress: 'error',
				buffer: `Doesn't have permission to access examples`,
			})
		);
	}
);

export const GET_EXAMPLE_FILE = createAsyncThunk(
	'examples/GET_EXAMPLE_FILE',
	async ({ taskId, fileId, accessToken, dispatch, thumbnail }) => {
		console.log('examples/GET_EXAMPLE_FILE');

		let tmpThumbnail = '';

		if (thumbnail) {
			tmpThumbnail = `?thumbnail=${thumbnail}`;
		}

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/files/${fileId}${tmpThumbnail}`,
			accessToken,
			dispatch,
		});
		if (res) return res;
	}
);

export const GET_SHAPES = createAsyncThunk(
	'examples/GET_SHAPES',
	async ({ taskId, exampleId, accessToken, dispatch }) => {
		console.log('examples/GET_SHAPES');

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/examples/${exampleId}/shapes`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

// UPDATE

export const UPDATE_EXAMPLE = createAsyncThunk(
	'examples/UPDATE_EXAMPLE',
	async ({ taskId, exampleId, examplesToUpdate, dispatch, accessToken }) => {
		console.log('examples/UPDATE_EXAMPLE');
		const res = await requestFactory({
			type: 'PUT',
			url: `/tasks/${taskId}/examples/${exampleId}`,
			data: examplesToUpdate,
			dispatch,
			accessToken,
		});

		if (res) return res;
	}
);

export const UPDATE_SHAPE = createAsyncThunk(
	'examples/UPDATE_SHAPE',
	async ({ taskId, exampleId, shapeId, newShape, dispatch, accessToken }) => {
		console.log('examples/UPDATE_SHAPE');
		const res = await requestFactory({
			type: 'PUT',
			url: `/tasks/${taskId}/examples/${exampleId}/shapes/${shapeId}`,
			accessToken,
			dispatch,
			data: newShape,
		});

		if (res) {
			dispatch(ADD_ALERT({ type: 'success', message: SHAPE_UPDATED_SUCCESS }));
			setTimeout(() => {
				dispatch(REMOVE_ALERT(SHAPE_UPDATED_SUCCESS));
			}, 2000);
		}

		if (res) return { res, shapeId };
	}
);

// DELETE

export const DELETE_EXAMPLE = createAsyncThunk(
	'examples/DELETE_EXAMPLE',
	async ({ currentRowId, taskId, dispatch, accessToken }) => {
		console.log('examples/DELETE_EXAMPLE');

		await requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/examples/${currentRowId}`,
			dispatch,
			accessToken,
		});

		return currentRowId;
	}
);

export const DELETE_SHAPE = createAsyncThunk(
	'examples/DELETE_SHAPE',
	async ({ taskId, exampleId, accessToken, dispatch, shapeId }) => {
		console.log('examples/DELETE_SHAPE');

		await requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/examples/${exampleId}/shapes/${shapeId}`,
			accessToken,
			dispatch,
		});

		return shapeId;
	}
);

export const DELETE_SLICE = createAsyncThunk(
	'examples/DELETE_SLICE',
	async ({ taskId, exampleId, accessToken, dispatch, sliceUUID }) => {
		console.log('examples/DELETE_SLICE');

		await requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/examples/${exampleId}/slices/${sliceUUID}`,
			accessToken,
			dispatch,
		});

		return { exampleId, sliceUUID };
	}
);

export const examplesSlice = createSlice({
	name: 'examples',
	initialState: {
		isLoading: true,
		currentExample: {},
		tmpExample: {},
		examples: [],
		totalExamples: null,
		currentShapes: [],
		fileToUpload: {
			isLoading: false,
		},
		imagesBufferIsLoading: true,
		imagesBuffer: [],
		documentsBufferIsLoading: true,
		documentsBuffer: [],
	},
	reducers: {
		// SHAPES
		SET_CURRENT_SHAPES: (state, { payload = {} }) => {
			state.currentShapes = payload;
		},

		// SET
		SET_CURRENT_EXAMPLE: (state, { payload }) => {
			console.log('SET_CURRENT_EXAMPLE');
			state.currentExample = payload;
		},
		SET_TMP_EXAMPLE: (state, { payload }) => {
			state.tmpExample = payload;
		},
		SET_EXAMPLE_IMAGES_BUFFER: (state, { payload }) => {
			if (payload) state.imagesBuffer = payload;
			state.imagesBufferIsLoading = false;
		},
		SET_EXAMPLE_DOCUMENTS_BUFFER: (state, { payload }) => {
			if (payload) state.documentsBuffer = payload;
			state.documentsBufferIsLoading = false;
		},
		RESET_EXAMPLES_SLICE: (state) => {
			state.currentExample = {};
			state.tmpExample = {};
			state.examples = [];
			state.totalExamples = 0;
			state.currentShapes = [];
			state.fileToUpload = {
				isLoading: false,
			};
		},
	},
	extraReducers: (builder) => {
		// CREATE

		builder.addCase(CREATE_SHAPE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_SHAPE.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.currentShapes = [...state.currentShapes, payload];
		});
		builder.addCase(CREATE_SHAPE.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(CREATE_SLICE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_SLICE.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			if (payload)
				if (state.currentExample.slices) {
					state.currentExample = {
						...state.currentExample,
						slices: [...state.currentExample.slices, payload],
					};
				} else {
					state.currentExample = { ...state.currentExample, slices: [payload] };
				}
		});
		builder.addCase(CREATE_SLICE.rejected, (state) => {
			state.isLoading = false;
		});

		// builder.addCase(UPLOAD_FILE.pending, (state) => {
		// 	state.fileToUpload.isLoading = false;
		// });
		// builder.addCase(UPLOAD_FILE.fulfilled, (state, { payload }) => {
		// 	state.fileToUpload.isLoading = true;

		// 	setTimeout(() => {}, 2000);
		// });
		// builder.addCase(UPLOAD_FILE.rejected, (state) => {
		// 	state.fileToUpload.isLoading = false;
		// });

		// GET

		builder.addCase(GET_EXAMPLES.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_EXAMPLES.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			state.examples = payload.res.data;
			state.totalExamples = payload.res.total_count;
		});
		builder.addCase(GET_EXAMPLES.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(GET_SHAPES.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_SHAPES.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.currentShapes = payload;
		});
		builder.addCase(GET_SHAPES.rejected, (state) => {
			state.isLoading = false;
		});

		// UPDATE

		builder.addCase(UPDATE_EXAMPLE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(UPDATE_EXAMPLE.fulfilled, (state, { payload }) => {
			if (payload) {
				const tmpExamples = state.examples.map((example) => {
					if (example.id === payload.id) return payload;
					return example;
				});

				state.currentExample = payload;
				state.examples = tmpExamples;
				state.isLoading = false;
			}
		});
		builder.addCase(UPDATE_EXAMPLE.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(UPDATE_SHAPE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(UPDATE_SHAPE.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			// todo: update shapes state only when edit finish or change shape
			state.currentExample = {
				...state.currentExample,
				shapes: state.currentExample.shapes
					? state.currentExample.shapes.map((shape) => {
							if (shape.id === payload.shapeId) {
								console.log('***** shape modified');
								console.log('***** old');
								console.log(shape);
								console.log('***** new');
								console.log(payload.res);
								return payload.res;
							}
							return shape;
					  })
					: [],
			};

			// state.currentExample.map((example) => {
			// 	if (
			// 		example.shapes?.length > 0 &&
			// 		example.shapes?.find((shape) => shape.id === payload.shapeId)
			// 	) {
			// 		console.log('***** shape found');
			// 		console.log(example);
			// 		console.log(
			// 			example.shapes?.find((shape) => shape.id === payload.shapeId)
			// 		);
			// 		return {
			// 			...example,
			// 			shapes: example.shapes.map((shape) => {
			// 				if (shape.id === payload.shapeId) {
			// 					console.log('***** shape modified');
			// 					console.log('***** old');
			// 					console.log(shape);
			// 					console.log('***** new');
			// 					console.log(payload.res);
			// 					return payload.res;
			// 				}
			// 				return shape;
			// 			}),
			// 		};
			// 	}
			// 	return example;
			// });

			state.currentShapes = state.currentShapes.map((shape) => {
				if (shape.id === payload.shapeId) return payload.res;
				return shape;
			});
		});
		builder.addCase(UPDATE_SHAPE.rejected, (state) => {
			state.isLoading = false;
		});

		// DELETE

		builder.addCase(DELETE_EXAMPLE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(DELETE_EXAMPLE.fulfilled, (state, { payload }) => {
			console.log(payload);
			state.examples = state.examples.filter(
				(example) => example.id !== payload
			);
		});
		builder.addCase(DELETE_EXAMPLE.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(DELETE_SHAPE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(DELETE_SHAPE.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.currentShapes = state.currentShapes.filter(
				(shape) => shape.id !== payload
			);
		});
		builder.addCase(DELETE_SHAPE.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(DELETE_SLICE.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(DELETE_SLICE.fulfilled, (state, { payload }) => {
			const { sliceUUID, exampleId } = payload;
			state.isLoading = false;
			state.currentExample.slices = state.currentExample.slices.filter(
				(slice) => slice.uuid !== sliceUUID
			);

			state.examples = state.examples.map((example) => {
				if (example.uuid === exampleId || example.id === exampleId) {
					return {
						...example,
						slices: example.slices.filter((slice) => slice.uuid !== sliceUUID),
					};
				}
				return example;
			});
		});
		builder.addCase(DELETE_SLICE.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const {
	SET_CURRENT_EXAMPLE,
	SET_CURRENT_SHAPES,
	SET_TMP_EXAMPLE,
	RESET_EXAMPLES_SLICE,
	SET_TMP_FILE_IN_TMP_EXAMPLE,
	SET_EXAMPLE_IMAGES_BUFFER,
	SET_EXAMPLE_DOCUMENTS_BUFFER,
	SET_CATEGORIES,
} = examplesSlice.actions;

export default examplesSlice.reducer;
