import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Grid } from '@mui/material';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';

// Styles
import { styles } from './styles';

const AccessDenied = () => (
	<div style={styles.mainContainer}>
		<Grid container style={styles.accessDeniedContainer}>
			{/* <h3 style={styles.accessDeniedTitle}>UNAUTHORIZED</h3> */}
			<LockPersonOutlinedIcon sx={{ fontSize: '48px' }} />
			<p>
				You don't have enough permissions to access this resource. Contact your
				administrator for more information
			</p>
		</Grid>
	</div>
);

export default AccessDenied;
