import { Box, Typography } from '@mui/material';
import { pdfjs, Document, Page } from 'react-pdf';
import { Line, LineChart } from 'recharts';

// Styles
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Components
import { Loader } from '../../../../Components/Shared/Loader';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 	'pdfjs-dist/build/pdf.worker.min.js',
// 	import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const createCell = {
	number: ({ styles, cellValue }) => {
		const { rowCell } = styles;

		return <Typography sx={rowCell}>{cellValue}</Typography>;
	},
	text: ({ styles, cellValue }) => {
		const { rowCell } = styles;

		return <Typography sx={rowCell}>{cellValue}</Typography>;
	},
	category: ({ styles, cellValue, categoriesState, params }) => {
		const { rowCell } = styles;

		if (categoriesState && categoriesState.length > 0) {
			const tmpCats = categoriesState.find(
				(cat) => cat.name === params.field
			)?.categories;
			let tmpValue = [];
			if (tmpCats && tmpCats.length > 0)
				tmpValue = tmpCats.find(
					(cat) => cat.display_name === cellValue || cat.name === cellValue
				);

			if (tmpValue && tmpValue.display_name)
				return <Typography sx={rowCell}>{tmpValue.display_name}</Typography>;
			if (tmpValue && tmpValue.name)
				return <Typography sx={rowCell}>{tmpValue.name}</Typography>;
		}
		return <Typography sx={rowCell}>{cellValue}</Typography>;
	},
	document: ({ cellValue, documentsBufferState }) => {
		if (
			documentsBufferState &&
			documentsBufferState.length > 0 &&
			documentsBufferState.find((element) => element.elementId === cellValue)
		) {
			return (
				<Box
					sx={{
						display: 'flex',
						backgroundColor: 'white',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Document
						file={
							documentsBufferState.find(
								(element) => element.elementId === cellValue
							).url
						}
					>
						<Page pageNumber={1} />
					</Document>
				</Box>
			);
		}
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	image: ({ cellValue, cellName, imagesBufferState }) => {
		if (
			imagesBufferState &&
			imagesBufferState.length > 0 &&
			imagesBufferState.find((element) => element.elementId === cellValue)
		)
			return (
				<img
					src={
						imagesBufferState.find((element) => element.elementId === cellValue)
							.thumbnail
					}
					alt={cellName}
					style={{ width: '100%' }}
				/>
			);
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	time_series: ({ cellValue, cellName }) => (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<LineChart width={100} height={50} data={cellValue}>
				<Line dataKey={(v) => v} stroke="#8884d8" strokeWidth={1} dot={false} />
			</LineChart>
		</div>
	),
};
