import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Input,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Pagination,
	Select,
	Slider,
	TextField,
	Typography,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
// import TasksRequests from '../../../utils/api/tasks';
// import { useTask } from '../../tasks/TaskContext';w
import CreateOrUpdateCategoryDialog from './CreateOrUpdateCategoryDialog';

import requestFactory from '../../../../services/request.factory';
import {
	CREATE_INPUT_CATEGORY,
	CREATE_SCHEMA_ELEMENT,
	DELETE_CATEGORY,
	DELETE_INPUT_CATEGORY,
	GET_ANOMALIES,
	GET_INPUTS,
	GET_INPUT_CATEGORIES,
	GET_METADATA,
} from '../../../../redux/schema.slice';
import { CustomPagination } from '../../../Shared/CustomPagination';
import StandardButton from '../../../Shared/Buttons/StandardButton';
import { Loader } from '../../../Shared/Loader';

const elementTypes = {
	image: 'Image',
	document: 'Document',
	text: 'Text',
	number: 'Number',
	category: 'Category',
	time_series: 'Time series',
};

const thresholdMarks = [
	{
		value: 0,
		label: '0',
	},
	{
		value: 0.1,
	},
	{
		value: 0.2,
	},
	{
		value: 0.3,
	},
	{
		value: 0.4,
	},
	{
		value: 0.5,
		label: '0.5',
	},
	{
		value: 0.6,
	},
	{
		value: 0.7,
	},
	{
		value: 0.8,
	},
	{
		value: 0.9,
	},
	{
		value: 1,
		label: '1',
	},
];
const thresholdMax = 1;
const thresholdMin = 0.0001;

function thresholdValue(value) {
	return `${value}°C`;
}

function CreateOrUpdateElementDialog(props) {
	const { openDialog, setOpenDialog, mode, elementType, prevElement } = props;

	const dispatch = useDispatch();

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { isLoading: isLoadingSchemaState, categories: categoriesState } =
		useSelector((state) => state.schema);
	const { accessToken } = useSelector((state) => state.user);

	const [newElement, setNewElement] = useState({
		display_name: '',
		description: '',
		name: '',
		type: '',
		multi_value: 'none',
		nullable: false,
		required: true,
		categories: [],
		total_count: 0,
	});

	const [anomalyColor, setAnomalyColor] = useState('#e57373');
	const [anomalyThreshold, setAnomalyThreshold] = useState(0.5);
	const [elementCategories, setElementCategories] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(4);
	const [showCategories, setShowCategories] = useState(true);
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
	const [openCreateCategoryDialog, setOpenCreateCategoryDialog] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showColorPicker, setShowColorPicker] = useState(false);

	const getCategories = async () => {
		await dispatch(
			GET_INPUT_CATEGORIES({
				accessToken,
				taskId: currentTaskState.uuid,
				dispatch,
				inputId: prevElement.uuid,
				inputName: prevElement.name,
			})
		);
	};

	useEffect(() => {
		if (prevElement && prevElement.type === 'category') getCategories();
	}, [prevElement]);

	useEffect(() => {
		if (
			prevElement &&
			prevElement.type === 'category' &&
			categoriesState &&
			categoriesState.length > 0 &&
			categoriesState.find((category) => category.id === prevElement.uuid)
		) {
			setElementCategories(
				categoriesState.find((category) => category.id === prevElement.uuid)
					.categories
			);
			setNewElement({
				...newElement,
				total_count: categoriesState.find(
					(category) => category.id === prevElement.uuid
				).total_count,
			});
		}
	}, [prevElement, categoriesState]);

	const handleChange = (e) => {
		const { name, value, checked } = e.target;
		if (name === 'nullable' || name === 'required')
			setNewElement({ ...newElement, [name]: checked });
		else setNewElement({ ...newElement, [name]: value });
	};

	useEffect(() => {
		if (mode === 'update') {
			setNewElement({
				display_name: prevElement.display_name,
				description: prevElement.description,
				name: prevElement.name,
				type: prevElement.type,
				multi_value: prevElement.multi_value,
				nullable: prevElement.nullable,
				required: prevElement.required,
				categories: elementCategories || [],
				total_count:
					categoriesState.find((category) => category.id === prevElement.uuid)
						?.total_count || 0,
			});
			if (elementType === 'anomaly_types') {
				setAnomalyColor(prevElement.color);
				setAnomalyThreshold(prevElement.threshold);
			}
		}
	}, [
		prevElement,
		mode,
		currentTaskState.id,
		elementType,
		page,
		elementCategories,
	]);

	const handleCreateElement = async () => {
		setIsLoading(true);
		let tmpElement = {};
		Object.keys(newElement).forEach((element) => {
			if (
				element !== 'categories' &&
				newElement[element] !== 'none' &&
				element !== 'total_count' &&
				element !== 'type'
			)
				tmpElement[element] = newElement[element];
			if (element === 'type' && elementType !== 'anomaly_types')
				tmpElement[element] = newElement[element];
		});

		if (elementType === 'anomaly_types')
			tmpElement = {
				...tmpElement,
				color: anomalyColor.replace('#', ''),
				threshold: anomalyThreshold,
			};

		const res = await dispatch(
			CREATE_SCHEMA_ELEMENT({
				element: tmpElement,
				taskId: currentTaskState.uuid,
				elementType,
				accessToken,
				dispatch,
			})
		).catch(() => setIsLoading(false));

		if (res?.payload?.res.uuid && elementCategories.length > 0) {
			await Promise.all(
				elementCategories.map(async (category) => {
					dispatch(
						CREATE_INPUT_CATEGORY({
							taskId: currentTaskState.uuid,
							elementId: res.payload.res.uuid,
							category,
							accessToken,
							dispatch,
						})
					);
				})
			);

			const resCat = await dispatch(
				GET_INPUT_CATEGORIES({
					accessToken,
					taskId: currentTaskState.uuid,
					dispatch,
					inputId: res.payload.res.uuid,
					inputName: res.payload.res.name,
				})
			);

			setElementCategories(resCat.payload.res.data);
		} else {
			setIsLoading(false);
		}

		switch (elementType) {
			case 'inputs':
				await dispatch(
					GET_INPUTS({
						accessToken,
						taskId: currentTaskState.uuid,
						dispatch,
					})
				);
				break;
			case 'anomaly_types':
				await dispatch(
					GET_ANOMALIES({
						accessToken,
						taskId: currentTaskState.uuid,
						dispatch,
					})
				);
				break;
			case 'metadata_fields':
				await dispatch(
					GET_METADATA({
						accessToken,
						taskId: currentTaskState.uuid,
						dispatch,
					})
				);
				break;
			default:
				return;
		}

		setIsLoading(false);
		setOpenDialog(false);
		setAnomalyColor('#0073ff');
	};

	const handleUpdateElement = async () => {
		setIsLoading(true);

		let tmpElement = {};
		Object.keys(newElement).forEach((element) => {
			if (element !== 'categories' && element !== 'total_count')
				if (element !== 'multi_value' && newElement[element] !== 'none')
					tmpElement = { ...tmpElement, [element]: newElement[element] };
		});

		if (elementType === 'anomaly_types') {
			tmpElement = {
				...tmpElement,
				threshold: anomalyThreshold,
				color: anomalyColor,
			};
		}

		console.log(tmpElement);

		let tmpElementType = elementType;

		if (elementType === 'anomaly_types') tmpElementType = 'anomaly-types';
		if (elementType === 'metadata_fields') tmpElementType = 'metadata-fields';

		const res = await requestFactory({
			type: 'PUT',
			url: `/tasks/${currentTaskState.uuid}/${tmpElementType}/${prevElement.uuid}`,
			data: tmpElement,
			accessToken,
			dispatch,
		});
		if (res)
			switch (elementType) {
				case 'inputs':
					await dispatch(
						GET_INPUTS({
							accessToken,
							taskId: currentTaskState.uuid,
							dispatch,
						})
					);
					break;
				case 'anomaly_types':
					await dispatch(
						GET_ANOMALIES({
							accessToken,
							taskId: currentTaskState.uuid,
							dispatch,
						})
					);
					break;
				case 'metadata_fields':
					await dispatch(
						GET_METADATA({
							accessToken,
							taskId: currentTaskState.uuid,
							dispatch,
						})
					);
					break;
				default:
					return;
			}
		setIsLoading(false);
		setOpenDialog(false);
	};

	const handleCreateCategory = async (category) => {
		if (mode === 'create') {
			setElementCategories([...elementCategories, category]);
		}
		if (mode === 'update') {
			await dispatch(
				CREATE_INPUT_CATEGORY({
					taskId: currentTaskState.uuid,
					elementId: prevElement.uuid,
					category,
					accessToken,
					dispatch,
				})
			);

			await dispatch(
				GET_INPUT_CATEGORIES({
					accessToken,
					taskId: currentTaskState.uuid,
					dispatch,
					inputId: prevElement.uuid,
					inputName: prevElement.name,
				})
			);
		}
		setOpenCreateCategoryDialog(false);
	};

	const handleDeleteCategory = async (category) => {
		// if (mode === 'create') {
		setElementCategories(
			elementCategories.filter((c) => c.name !== category.name)
		);
		// }
		if (mode === 'update') {
			await dispatch(
				DELETE_INPUT_CATEGORY({
					taskId: currentTaskState.uuid,
					elementType,
					elementId: prevElement.uuid,
					categoryId: category.uuid,
					accessToken,
					dispatch,
				})
			);

			dispatch(
				DELETE_CATEGORY({
					elementId: prevElement.uuid,
					categoryId: category.uuid,
				})
			);

			// const res = await dispatch(
			// 	GET_INPUT_CATEGORIES({
			// 		accessToken,
			// 		taskId: currentTaskState.uuid,
			// 		dispatch,
			// 		inputId: prevElement.uuid,
			// 		inputname: prevElement.name,
			// 	})
			// );
			// setElementCategories(res.payload.res.data);
		}

		setOpenCreateCategoryDialog(false);
	};

	const handleChangePage = async (e, newPage) => {
		setPage(newPage);
		await dispatch(
			GET_INPUT_CATEGORIES({
				accessToken,
				taskId: currentTaskState.uuid,
				dispatch,
				inputId: prevElement.uuid,
				inputName: prevElement.name,
				page: parseInt(newPage + 1),
			})
		);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				scroll="body"
			>
				<DialogTitle>
					{elementType === 'inputs' && 'Input:'}
					{elementType === 'anomaly_types' && 'Anomaly:'}
					{elementType === 'metadata_fields' && 'Metadata:'}
					{mode === 'create' ? ' Create new element' : ' Modify element'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{mode === 'create' ? (
							<>
								You're trying to add a new element to the {elementType} of the
								task. Please fill in the following fields (all fields showing as
								red are required), and click on the "Create" button.
							</>
						) : (
							<>
								You're trying to modify an element of the task. Please fill in
								the following fields (all fields showing as red are required),
								and click on the "update" button.
							</>
						)}
					</DialogContentText>
					<TextField
						id="name"
						label="Name"
						name="name"
						value={newElement.name}
						fullWidth
						required
						sx={{
							mt: 2,
						}}
						onChange={handleChange}
					/>
					{/* <TextField
						id="display_name"
						label="Display name"
						name="display_name"
						value={newElement.display_name}
						sx={{
							mt: 2,
						}}
						onChange={handleChange}
						fullWidth
					/> */}
					{elementType !== 'anomaly_types' && (
						<TextField
							id="description"
							label="Description"
							name="description"
							value={newElement.description}
							sx={{
								mt: 2,
							}}
							onChange={handleChange}
							fullWidth
						/>
					)}
					{elementType === 'anomaly_types' && (
						<>
							<TextField
								id="display_name"
								label="Display name"
								name="display_name"
								value={newElement.display_name}
								sx={{
									mt: 2,
								}}
								onChange={handleChange}
								fullWidth
							/>
							<Box
								onClick={() => {
									setShowColorPicker(true);
								}}
								sx={{
									p: '5px',
									mt: 2,
									border: '1px solid #e0e0e0',
									width: '100px',
									height: '30px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box
									sx={{
										backgroundColor: anomalyColor,
										borderRadius: '5px',
										width: '95px',
										height: '15px',
									}}
								/>
							</Box>
							{showColorPicker && (
								<Box>
									<Box
										onClick={() => {
											setShowColorPicker(false);
										}}
										sx={{
											position: 'fixed',
											top: '0',
											left: '0',
											right: '0',
											bottom: '0',
										}}
									/>
									<SketchPicker
										color={anomalyColor}
										onChange={(color) => {
											setAnomalyColor(color.hex);
										}}
									/>
								</Box>
							)}
						</>
					)}

					{elementType !== 'anomaly_types' && (
						<FormControl
							fullWidth
							sx={{
								mt: 2,
							}}
						>
							<InputLabel id="elementDataTypeLabel">Data Type *</InputLabel>
							<Select
								id="type"
								labelId="elementDataTypeLabel"
								name="type"
								label="Data Type"
								disabled={mode === 'update'}
								value={newElement.type}
								onChange={handleChange}
							>
								{elementType !== 'metadata_fields'
									? Object.entries(elementTypes).map(
											([elementTypeName, elementTypeDisplayName]) => (
												<MenuItem key={elementTypeName} value={elementTypeName}>
													{elementTypeDisplayName}
												</MenuItem>
											)
									  )
									: Object.entries(elementTypes)
											.filter(
												([elementTypeName, elementTypeDisplayName]) =>
													elementTypeName === 'number' ||
													elementTypeName === 'text'
											)
											.map(([elementTypeName, elementTypeDisplayName]) => (
												<MenuItem key={elementTypeName} value={elementTypeName}>
													{elementTypeDisplayName}
												</MenuItem>
											))}
							</Select>
						</FormControl>
					)}
					{newElement.type === 'category' && (
						<>
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => {
									setOpenCreateCategoryDialog(true);
								}}
								fullWidth
								sx={{
									marginTop: '12px',
								}}
							>
								Create new category
							</Button>
							<List
								component="nav"
								subheader={
									<Box
										onClick={() => setShowCategories(!showCategories)}
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%',
										}}
									>
										{showCategories ? <ExpandLess /> : <ExpandMore />}
										<Typography
											variant="body1"
											component="div"
											sx={{
												width: '100%',
												fontWeight: 'bold',
												color: 'text.primary',
											}}
										>
											Categories list
										</Typography>
									</Box>
								}
								sx={{
									mt: 2,
								}}
							>
								<Collapse in={showCategories} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{!isLoadingSchemaState &&
											elementCategories &&
											elementCategories.map((category, index) => (
												<ListItem key={index}>
													<ListItemAvatar>
														<Avatar
															sx={{
																backgroundColor:
																	category.color && category.color.includes('#')
																		? category.color
																		: `#${category.color}`,
															}}
														>
															{category.display_name?.charAt(0) ||
																category.name.charAt(0)}
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary={category.display_name || category.name}
													/>
													<ListItemSecondaryAction>
														<IconButton
															edge="end"
															aria-label="delete"
															color="primary"
															onClick={() => handleDeleteCategory(category)}
														>
															<DeleteIcon />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											))}
										{isLoadingSchemaState && <Loader size="L" />}
									</List>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center',
											mt: '10px',
											mr: '10px',
										}}
									>
										<CustomPagination
											total={
												newElement.total_count !== 0
													? newElement.total_count
													: elementCategories.length
											}
											rowsPerPage={rowsPerPage}
											page={page}
											handleChangePage={handleChangePage}
											handleChangeRowsPerPage={handleChangeRowsPerPage}
											rowsPerPageOptions={[4]}
											simple
										/>
									</Box>
								</Collapse>
							</List>
						</>
					)}
					{/* <FormControl
						fullWidth
						sx={{
							mt: 2,
						}}
					>
						<InputLabel id="multiValueLabel">Multi value</InputLabel>
						<Select
							id="multiValue"
							name="multi_value"
							labelId="multiValueLabel"
							label="Multi value"
							value={newElement.multi_value}
							onChange={handleChange}
						>
							<MenuItem value="none">
								<em>None</em>
							</MenuItem>
							<MenuItem value="unordered">Unordered</MenuItem>
							<MenuItem value="ordered">Ordered</MenuItem>
							<MenuItem value="time_based">Time Based</MenuItem>
						</Select>
					</FormControl> */}
					<List
						component="nav"
						subheader={
							<Box
								onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								{showAdvancedSettings ? <ExpandLess /> : <ExpandMore />}
								<Typography
									variant="body1"
									component="div"
									sx={{
										width: '100%',
										fontWeight: 'bold',
										color: 'text.primary',
									}}
								>
									Advanced settings
								</Typography>
							</Box>
						}
						sx={{
							mt: 2,
						}}
					>
						<Collapse in={showAdvancedSettings} timeout="auto" unmountOnExit>
							{elementType !== 'anomaly_types' && (
								<>
									<FormControlLabel
										control={<Checkbox />}
										label="Nullable"
										name="nullable"
										checked={newElement.nullable}
										onChange={handleChange}
										sx={{
											mt: 2,
											width: '100%',
										}}
									/>
									<FormControlLabel
										control={<Checkbox />}
										label="Required"
										name="required"
										checked={newElement.required}
										onChange={handleChange}
										sx={{
											mt: 2,
											width: '100%',
										}}
									/>
								</>
							)}
							{elementType === 'anomaly_types' && (
								<Grid
									container
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '24px',
									}}
								>
									<Grid
										item
										xs={3}
										sx={{
											display: 'flex',
											justifyContent: 'left',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										Threshold:
									</Grid>
									<Grid item xs={9} sx={{ display: 'flex' }}>
										<Input
											value={anomalyThreshold}
											type="number"
											InputProps={{ min: 0.0001, max: 1 }}
											onChange={(e) => {
												let { value } = e.target;

												if (value > thresholdMax) value = thresholdMax;
												if (value < thresholdMin) value = thresholdMin;

												setAnomalyThreshold(value);
											}}
										/>
										<Slider
											id="threshold"
											label="Threshold"
											name="threshold"
											defaultValue={0.5}
											value={anomalyThreshold}
											onChange={(e) => setAnomalyThreshold(e.target.value)}
											getAriaValueText={thresholdValue}
											min={thresholdMin}
											step={thresholdMin}
											max={thresholdMax}
											marks={thresholdMarks}
										/>
									</Grid>
								</Grid>
							)}
						</Collapse>
					</List>
				</DialogContent>
				<DialogActions>
					<StandardButton
						handleClick={() => setOpenDialog(false)}
						value="Cancel"
						close
					/>
					<StandardButton
						handleClick={
							mode === 'create' ? handleCreateElement : handleUpdateElement
						}
						value={mode === 'create' ? 'Create' : 'Update'}
						loading={isLoading}
					/>
				</DialogActions>
			</Dialog>
			<CreateOrUpdateCategoryDialog
				openCreateOrUpdateCategoryDialog={openCreateCategoryDialog}
				handleCloseCreateOrUpdateCategoryDialog={setOpenCreateCategoryDialog}
				handleCreateCategoryElement={handleCreateCategory}
				mode={mode}
				elementCategories={elementCategories}
				setElementCategories={setElementCategories}
			/>
		</>
	);
}

CreateOrUpdateElementDialog.propTypes = {
	openDialog: PropTypes.bool,
	setOpenDialog: PropTypes.func,
	mode: PropTypes.string,
	elementType: PropTypes.string,
	prevElement: PropTypes.object,
	// onUpdateSchema: PropTypes.func,
	// setIsLoading: PropTypes.func,
	// setSnackbarOpen: PropTypes.func,
	// setSnackbarMessage: PropTypes.func,
	// setSnackbarSeverity: PropTypes.func,
};

export default CreateOrUpdateElementDialog;
