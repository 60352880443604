import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { CustomTooltip } from '../CustomTooltip';

// Services
import { getTextColorBasedOnBackgroundColor } from '../../../services/getTextColorBasedOnBackgroundColor';

// Styles
import { styles } from './styles';

function AnomalyChip(props) {
	const { color = '#ff0000', name, tooltip = false } = props;

	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	return (
		color &&
		(tooltip ? (
			<CustomTooltip title={name}>
				<button
					type="button"
					style={{
						...styles.shapeChip,
						backgroundColor: color,
						color: getTextColorBasedOnBackgroundColor(color),
					}}
				>
					{anomalyTypesState.find((anomaly) => anomaly.name === name)
						? anomalyTypesState.find((anomaly) => anomaly.name === name)
								.display_name ||
						  anomalyTypesState.find((anomaly) => anomaly.name === name).name
						: name}
				</button>
			</CustomTooltip>
		) : (
			<button
				type="button"
				style={{
					...styles.shapeChip,
					backgroundColor: color,
					color: getTextColorBasedOnBackgroundColor(color),
				}}
			>
				{anomalyTypesState.find((anomaly) => anomaly.name === name)
					? anomalyTypesState.find((anomaly) => anomaly.name === name)
							.display_name ||
					  anomalyTypesState.find((anomaly) => anomaly.name === name).name
					: name}
			</button>
		))
	);
}

AnomalyChip.propTypes = {
	color: PropTypes.string,
	name: PropTypes.string,
	tooltip: PropTypes.bool,
};

export default AnomalyChip;
