import PropTypes from 'prop-types';

// Components
import { Grid, CssBaseline } from '@mui/material';

// Styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styles } from './styles';

// Consts
import { statuses } from '../../../consts/status';
import { CustomTooltip } from '../CustomTooltip';

const theme = createTheme();

function StatusBar({ status = null, code = '', name = '', description = '' }) {
	return (
		<ThemeProvider theme={theme}>
			<CustomTooltip
				title={status ? statuses[status]?.description : description}
			>
				<Grid
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					<CssBaseline />
					<div
						style={{
							...styles.statusContainer,
							backgroundColor: statuses[status || code]?.color,
						}}
					>
						{status ? statuses[status]?.name : name}
					</div>
				</Grid>
			</CustomTooltip>
		</ThemeProvider>
	);
}

StatusBar.propTypes = {
	status: PropTypes.string,
	code: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
};

export default StatusBar;
