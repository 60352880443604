import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfToday, format } from 'date-fns';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import {
	Checkbox,
	Collapse,
	Container,
	FormControl,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';
import { DateTimePicker } from '@mui/x-date-pickers';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import { ExamplesTable } from './components/ExamplesTable';
import { CustomPagination } from '../../../../Components/Shared/CustomPagination';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import { CommentsModal } from './components/CommentsModal';
import { EditElementModal } from './components/EditElementModal';
import { DeleteExampleModal } from './components/DeleteExampleModal';
import { CreateOrUpdateExampleModal } from './components/CreateOrUpdateExampleModal';
import { EditViewModal } from './components/EditViewModal';
import { QueryBuilderModal } from './components/QueryBuilderModal';
import AnomalyChip from '../../../../Components/Shared/AnomalyChip';

// Styles
import { styles } from './styles';

// Redux
import {
	GET_EXAMPLES,
	GET_EXAMPLE_FILE,
	SET_CURRENT_EXAMPLE,
	SET_EXAMPLE_DOCUMENTS_BUFFER,
	SET_EXAMPLE_IMAGES_BUFFER,
} from '../../../../redux/examples.slice';

// Services
import { getColumns, getRows } from './examples.services';
import {
	getLastMonth,
	getLastWeek,
	getThisMonth,
	getThisWeek,
	getToday,
	getYesterday,
} from '../../../../services/date';
import { getTextColorBasedOnBackgroundColor } from '../../../../services/getTextColorBasedOnBackgroundColor';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { colors } from '../../../../consts/colors';

export function TaskExamples() {
	const dispatch = useDispatch();

	const {
		examples: examplesState,
		imagesBuffer: imagesBufferState,
		documentsBuffer: documentsBufferState,
	} = useSelector((state) => state.examples);
	const { totalExamples: totalExamplesState } = useSelector(
		(state) => state.examples
	);
	const schemaState = useSelector((state) => state.schema);
	const { categories: categoriesState } = useSelector((state) => state.schema);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [rowsPerPageOptions] = useState([25, 50, 100]);
	const [currentRowId, setCurrentRowId] = useState('');
	const [selectedRows, setSelectedRows] = useState([]);
	const [currentCellId, setCurrentCellId] = useState('');
	const [currentColumns, setCurrentColumns] = useState([]);
	const [allColumns, setAllColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [orderedBy, setOrderedBy] = useState([null, null]);
	const [showFilters, setShowFilters] = useState(false);

	// todo: when multiple query suported
	// const [query, setQuery] = useState([]);
	const [query, setQuery] = useState({ field: '', operator: '==', value: '' });
	const [filters, setFilters] = useState({
		only_with_comments: false,
		labeling_status: [],
		query: '',
		order: '',
		order_by: '',
		period: '',
		'created_at[min]': null,
		'created_at[max]': endOfToday(),
		// TODO: change to array when multiple anomalies supported
		anomalies: '',
		// anomalies: [],
	});
	const [openStatusTooltip, setOpenStatusTooltip] = useState(false);

	// Modals
	const [openDeleteExampleModal, setOpenDeleteExampleModal] = useState(false);
	const [openCreateExampleModal, setOpenCreateExampleModal] = useState(false);
	const [openEditExampleModal, setOpenEditExampleModal] = useState(false);
	const [openEditElementModal, setOpenEditElementModal] = useState(false);
	const [openEditViewModal, setOpenEditViewModal] = useState(false);
	const [openQueryBuilderModal, setOpenQueryBuilderModal] = useState(false);
	const [openCommentsModal, setOpenCommentsModal] = useState(false);
	const [openStatsModal, setOpenStatsModal] = useState(false);

	const handleOpenStatusTooltip = () => {
		setOpenStatusTooltip(!openStatusTooltip);
	};

	const getExamplesWithFilters = async () => {
		await dispatch(
			GET_EXAMPLES({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				page: page + 1,
				per_page: rowsPerPage,
				labeling_status:
					filters.labeling_status.length > 0
						? filters['labeling_status'].join('|')
						: '',
				only_with_comments: filters.only_with_comments,
				query: filters.query,
				order: filters.order,
				order_by: filters.order_by,
				'created_at[min]': format(
					new Date(filters['created_at[min]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				'created_at[max]': format(
					new Date(filters['created_at[max]']),
					"yyyy-MM-dd'T'HH:mm:ss"
				),
				schemaState,
			})
		);
	};

	useEffect(() => {
		if (currentTaskState.uuid && accessToken && !examplesState.isLoading)
			getExamplesWithFilters();
	}, [filters, page, rowsPerPage]);

	const handleChangeFilters = (e) => {
		const { id, name, value, checked } = e.target;

		if (value === 'on') {
			if (id === 'only_with_comments')
				setFilters({ ...filters, [name]: checked });
		} else {
			setFilters({ ...filters, [name]: value });
		}
	};

	const setImagesOnBuffer = async () => {
		const imageElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'image') {
						examplesState.forEach((example) => {
							example.inputs.forEach((exampleInput) => {
								if (exampleInput.element === input.name)
									if (exampleInput.value !== '') {
										const checkIfInBuffer = imagesBufferState.find(
											(image) => image.elementId === exampleInput.value
										);
										if (!checkIfInBuffer) imageElements.push(exampleInput);
									}
							});
						});
					}
				});
			}
		}

		if (imageElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					imageElements.map(async (element) => {
						const resThumbnail = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
								thumbnail: true,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							// image: resThumbnail.payload.download_url,
							thumbnail: resThumbnail.payload.download_url,
						};
					})
				).then((result) => result)),
			];

			dispatch(SET_EXAMPLE_IMAGES_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_IMAGES_BUFFER());
	};

	const setDocumentsOnBuffer = async () => {
		const documentElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'document') {
						examplesState.forEach((example) => {
							example.inputs.forEach((exampleInput) => {
								if (exampleInput.element === input.name)
									if (exampleInput.value !== '')
										documentElements.push(exampleInput);
							});
						});
					}
				});
			}
		}

		if (documentElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					documentElements.map(async (element) => {
						const resDocument = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							url: resDocument.payload.download_url,
						};
					})
				).then((result) => result)),
			];
			dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER());
	};

	useEffect(() => {
		setAllColumns([]);
		setCurrentColumns([]);
		setOpenEditViewModal(false);
		dispatch(SET_CURRENT_EXAMPLE({}));
		if (
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`).length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) =>
					localStorage
						.getItem(`${currentTaskState.uuid}-exampleColumns`)
						.includes(col.field)
				)
			);
		}
	}, []);

	useEffect(() => {
		setImagesOnBuffer();
		setDocumentsOnBuffer();
	}, [examplesState]);

	useEffect(() => {
		if (currentCellId !== '') setOpenEditElementModal(true);
		else setOpenEditElementModal(false);
	}, [currentCellId]);

	useEffect(() => {
		if (currentRowId !== '') {
			dispatch(
				SET_CURRENT_EXAMPLE(
					examplesState.find((example) => example.id === currentRowId)
				)
			);
		}
	}, [currentRowId]);

	useEffect(() => {
		if (!openEditElementModal) {
			setCurrentRowId('');
			setCurrentCellId('');
			dispatch(SET_CURRENT_EXAMPLE({}));
		}
	}, [openEditElementModal]);

	useEffect(() => {
		// todo
		const getCategories = async () => {};
		if (
			!imagesBufferState.imagesBufferIsLoading &&
			accessToken &&
			currentTaskState.uuid
		) {
			getColumns({
				setAllColumns,
				styles,
				schemaState,
				examplesState,
				currentTaskState,
				accessToken,
				setOpenEditExampleModal,
				setCurrentRowId,
				dispatch,
				imagesBufferState,
				documentsBufferState,
				HasAccess,
				openStatusTooltip,
				handleOpenStatusTooltip,
				setOpenCommentsModal,
				categoriesState,
			});
			getRows({
				setRows,
				schemaState,
				examplesState,
				currentTaskState,
				accessToken,
				dispatch,
			});
		}
	}, [schemaState, examplesState, imagesBufferState, documentsBufferState]);

	useEffect(() => {
		setCurrentColumns(allColumns);

		if (
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`).length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) => {
					const tmp = localStorage.getItem(
						`${currentTaskState.uuid}-exampleColumns`
					);
					if (tmp.includes(col.field)) return col;
					return false;
				})
			);
		} else {
			setCurrentColumns(allColumns);
		}
	}, [allColumns]);

	const openCreateExampleView = () => {
		setOpenCreateExampleModal(true);
	};

	const openEditExampleView = () => {
		setOpenEditViewModal(true);
	};

	const openQueryBuilder = () => {
		setOpenQueryBuilderModal(true);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
	};

	useEffect(() => {
		if (!openCommentsModal) {
			setCurrentRowId('');
			setSelectedRows([]);
			dispatch(SET_CURRENT_EXAMPLE({}));
		}
	}, [openCommentsModal]);

	const handleChangeOrderBy = (e) => {
		const { value } = e.target;
		const tmpValues = value.split(',');
		setOrderedBy(value);
		setFilters({ ...filters, order_by: tmpValues[0], order: tmpValues[1] });
	};

	const handleChangeByPeriod = (e) => {
		const { value } = e.target;
		switch (value) {
			case 'TODAY':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getToday(),
				});
				break;
			case 'YESTERDAY':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getYesterday(),
				});
				break;
			case 'THIS_WEEK':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getThisWeek().start,
					'created_at[max]': getThisWeek().end,
				});
				break;
			case 'LAST_WEEK':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getLastWeek().start,
					'created_at[max]': getLastWeek().end,
				});
				break;
			case 'THIS_MONTH':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getThisMonth().start,
					'created_at[max]': getThisMonth().end,
				});
				break;
			case 'LAST_MONTH':
				setFilters({
					...filters,
					period: value,
					'created_at[min]': getLastMonth().start,
					'created_at[max]': getLastMonth().end,
				});
				break;
			default:
				return null;
		}
	};

	const handleOpenStatsModal = () => {
		setOpenStatsModal(!openStatsModal);
	};

	const handleChangeFromDateRange = (date) => {
		setFilters({ ...filters, 'created_at[min]': date, period: '' });
	};

	const handleChangeToDateRange = (date) => {
		setFilters({ ...filters, 'created_at[max]': date, period: '' });
	};

	const handleChangeAnomalyFilter = (e) => {
		const anomaly = e.target.value;
		setFilters({ ...filters, anomalies: anomaly, query: `${anomaly}=1` });
	};

	// TODO: filter by multiple anomalies when supported in API
	// const handleChangeAnomalyFilter = (e) => {
	// 	const anomalies = e.target.value;
	// 	setFilters({ ...filters, anomalies });
	// };

	const handleChangeLabelingStatus = (e) => {
		const statuses = e.target.value;
		setFilters({ ...filters, labeling_status: statuses });
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			{/* <QueryBuilderComponent
				updateQuery={updateQuery}
				openCreateExampleView={openCreateExampleView}
				setOpenCreateExampleModal={setOpenCreateExampleModal}
			/> */}
			<Container maxWidth={false} sx={{ padding: '0px !important' }}>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						gap: 1,
						fontWeight: 'bold',
						alignItems: 'center',
						justifyContent: 'start',
						marginBottom: '12px',
					}}
				>
					<Grid item sx={{ minWidth: '150px' }}>
						<StandardButton
							value="Select filters"
							handleClick={() => setShowFilters(!showFilters)}
							icon={<TuneIcon />}
						/>
					</Grid>
					<Grid
						item
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '100%',
							outline: '2px solid #d3eafa',
							borderRadius: '6px',
							padding: '6px 6px',
							gap: 1,
						}}
					>
						<Grid
							item
							sx={{
								display: 'flex',
								fontWeight: 'bold',
								alignItems: 'center',
							}}
						>
							Current filters:
						</Grid>
						{filters.anomalies !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '12px',
									padding: '0px 6px',
									color: getTextColorBasedOnBackgroundColor(
										schemaState.anomaly_types.find(
											(anom) =>
												anom.name === filters.anomalies ||
												anom.display_name === filters.anomalies
										).color
									),
									backgroundColor: schemaState.anomaly_types.find(
										(anom) =>
											anom.name === filters.anomalies ||
											anom.display_name === filters.anomalies
									).color,
								}}
							>
								{filters.anomalies}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({
											...filters,
											anomalies: filters.anomalies.filter(
												(l) => l !== filters.anomalies
											),
										})
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{
							// TODO: Filter for multiple anomalies when ready on API
						}
						{/* {filters.anomalies.length > 0 &&
							filters.anomalies.map((anomaly) => (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '12px',
										padding: '0px 6px',
										color: getTextColorBasedOnBackgroundColor(
											schemaState.anomaly_types.find(
												(anom) =>
													anom.name === anomaly || anom.display_name === anomaly
											).color
										),
										backgroundColor: schemaState.anomaly_types.find(
											(anom) =>
												anom.name === anomaly || anom.display_name === anomaly
										).color,
									}}
								>
									{anomaly}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												anomalies: filters.anomalies.filter(
													(l) => l !== anomaly
												),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							))} */}
						{filters.period === '' &&
							filters['created_at[min]'] &&
							filters['created_at[max]'] && (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										outline: '2px solid #d3eafa',
										borderRadius: '12px',
										padding: '0px 6px',
										backgroundColor: '#d3eafa',
									}}
								>
									from:{' '}
									{format(
										new Date(filters['created_at[min]']),
										"yyyy-MM-dd'T'HH:mm:ss"
									)}{' '}
									- to:{' '}
									{format(
										new Date(filters['created_at[max]']),
										"yyyy-MM-dd'T'HH:mm:ss"
									)}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												period: '',
												'created_at[min]': null,
												'created_at[max]': endOfToday(),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							)}
						{filters.period && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								{filters.period}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({
											...filters,
											period: '',
											'created_at[min]': null,
											'created_at[max]': endOfToday(),
										})
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.labeling_status.length > 0 &&
							filters.labeling_status.map((label) => (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										outline: '2px solid #d3eafa',
										borderRadius: '12px',
										padding: '0px 6px',
										backgroundColor: '#d3eafa',
									}}
								>
									{label}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												labeling_status: filters.labeling_status.filter(
													(l) => l !== label
												),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							))}
						{filters.only_with_comments && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								with comments
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({ ...filters, only_with_comments: false })
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order_by !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								order by: {filters.order_by}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order_by: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								order: {filters.order}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{/* {filters.query !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								{`${filters.query.split('=')[0]} = ${
									filters.query.split('=')[1]
								}`}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => {
										setFilters({ ...filters, query: '' });
										setQuery({ field: '', operator: '==', value: '' });
									}}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)} */}
					</Grid>
				</Grid>
				<Collapse
					in={showFilters}
					timeout="auto"
					unmountOnExit
					sx={{ width: '100%', marginBottom: '24px' }}
				>
					<Grid container>
						<Grid
							item
							xs={12}
							sx={{
								outline: '2px solid #d3eafa',
								borderRadius: '12px',
								marginBottom: '12px',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									padding: '12px',
									marginBottom: '12px',
								}}
							>
								<Grid
									item
									xs={12}
									md={8}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
										marginBottom: {
											xs: '12px',
											md: 0,
										},
									}}
								>
									<Grid
										item
										xs={3}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By date:
									</Grid>
									<Grid item xs={12} sx={{ display: 'flex', gap: 1 }}>
										<Grid xs={6}>
											<FormControl fullWidth>
												<DateTimePicker
													label={'"From"'}
													// className={`${Date} ${styles.common_input_type}`}
													name="fromDate"
													value={filters['created_at[min]']}
													onChange={(newValue) =>
														handleChangeFromDateRange(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} helperText={null} />
													)}
													inputFormat="YYYY/MM/DD hh:mm A"
												/>
											</FormControl>
										</Grid>
										<Grid xs={6}>
											<FormControl fullWidth>
												<DateTimePicker
													label={'"To"'}
													// className={`${Date} ${styles.common_input_type}`}
													name="toDate"
													value={filters['created_at[max]']}
													onChange={(newValue) =>
														handleChangeToDateRange(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} helperText={null} />
													)}
													inputFormat="YYYY/MM/DD hh:mm A"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									md={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By period:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<FormControl fullWidth>
											<Select
												id="periodFilter"
												name="periodFilter"
												value={filters.period}
												onChange={handleChangeByPeriod}
											>
												<MenuItem value="TODAY">Today</MenuItem>
												<MenuItem value="YESTERDAY">Yesterday</MenuItem>
												<MenuItem value="THIS_WEEK">This Week</MenuItem>
												<MenuItem value="LAST_WEEK">Last Week</MenuItem>
												<MenuItem value="THIS_MONTH">This Month</MenuItem>
												<MenuItem value="LAST_MONTH">Last Month</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									padding: '12px',
									marginBottom: '12px',
								}}
							>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By comments:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<FormControl>
											<Checkbox
												id="only_with_comments"
												color="primary"
												name="only_with_comments"
												checked={filters.only_with_comments}
												onChange={handleChangeFilters}
											/>
										</FormControl>
										Only examples with comments
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By status label:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											alignItems: 'center',
										}}
									>
										<FormControl fullWidth>
											<Select
												id="labeling_status"
												multiple
												value={filters.labeling_status}
												onChange={handleChangeLabelingStatus}
												renderValue={(selected) => selected.join(', ')}
											>
												<MenuItem value="labeled">
													<Checkbox
														checked={filters.labeling_status.includes(
															'labeled'
														)}
													/>
													<ListItemText primary="Labeled" />
												</MenuItem>
												<MenuItem value="unlabeled">
													<Checkbox
														checked={filters.labeling_status.includes(
															'unlabeled'
														)}
													/>
													<ListItemText primary="Unlabeled" />
												</MenuItem>
												<MenuItem value="pending_review">
													<Checkbox
														checked={filters.labeling_status.includes(
															'pending_review'
														)}
													/>
													<ListItemText primary="Pending review" />
												</MenuItem>
												<MenuItem value="rejected">
													<Checkbox
														checked={filters.labeling_status.includes(
															'rejected'
														)}
													/>
													<ListItemText primary="Rejected" />
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={4}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										paddingLeft: {
											xs: 0,
											md: '12px',
										},
									}}
								>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										By anomaly:
									</Grid>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											alignItems: 'center',
										}}
									>
										<FormControl fullWidth>
											<Select
												value={filters.anomalies}
												onChange={handleChangeAnomalyFilter}
												sx={{ maxHeight: '55px' }}
												renderValue={(selected) => (
													<Grid item sx={{ display: 'flex', gap: 1 }}>
														{schemaState.anomaly_types.find(
															(anom) => anom.name === selected
														) && (
															<AnomalyChip
																style={{ height: '10px !important' }}
																name={
																	schemaState.anomaly_types.find(
																		(anom) => anom.name === selected
																	).name
																}
																color={
																	schemaState.anomaly_types.find(
																		(anom) => anom.name === selected
																	).color
																}
															/>
														)}
													</Grid>
												)}
											>
												{schemaState.anomaly_types &&
													schemaState.anomaly_types.length > 0 &&
													schemaState.anomaly_types.map((anomaly) => (
														<MenuItem
															key={anomaly.uuid}
															value={anomaly.display_name || anomaly.name}
														>
															<Checkbox
																checked={filters.anomalies.includes(
																	anomaly.display_name || anomaly.name
																)}
															/>
															<AnomalyChip
																name={anomaly.display_name || anomaly.name}
																color={anomaly.color}
															/>
														</MenuItem>
													))}
											</Select>
										</FormControl>
										{
											// TODO: Selector for multiple anomalies when ready on API
										}
										{/* <FormControl fullWidth>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												value={filters.anomalies}
												onChange={handleChangeAnomalyFilter}
												sx={{ maxHeight: '55px' }}
												renderValue={(selected) => (
													<Grid item sx={{ display: 'flex', gap: 1 }}>
														{selected.map(
															(anomaly) =>
																schemaState.anomaly_types.find(
																	(anom) => anom.name === anomaly
																) && (
																	<AnomalyChip
																		style={{ height: '10px !important' }}
																		name={
																			schemaState.anomaly_types.find(
																				(anom) => anom.name === anomaly
																			).name
																		}
																		color={
																			schemaState.anomaly_types.find(
																				(anom) => anom.name === anomaly
																			).color
																		}
																	/>
																)
														)}
													</Grid>
												)}
											>
												{schemaState.anomaly_types &&
													schemaState.anomaly_types.length > 0 &&
													schemaState.anomaly_types.map((anomaly) => (
														<MenuItem
															key={anomaly.uuid}
															value={anomaly.display_name || anomaly.name}
														>
															<Checkbox
																checked={filters.anomalies.includes(
																	anomaly.display_name || anomaly.name
																)}
															/>
															<AnomalyChip
																name={anomaly.display_name || anomaly.name}
																color={anomaly.color}
															/>
														</MenuItem>
													))}
											</Select>
										</FormControl> */}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								outline: '2px solid #d3eafa',
								borderRadius: '12px',
								padding: '12px',
								marginBottom: '12px',
							}}
						>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Edit columns view:
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Query builder:
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontWeight: 'bold !important',
										}}
									>
										Order by:
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<StandardButton
											value="Open editor"
											type="textButton"
											handleClick={openEditExampleView}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<StandardButton
											value="Open builder"
											type="textButton"
											handleClick={openQueryBuilder}
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '12px',
										}}
									>
										<FormControl fullWidth sx={{}}>
											<Select
												id="orderBy"
												name="orderBy"
												value={orderedBy}
												onChange={handleChangeOrderBy}
											>
												<MenuItem value="created_at,asc">
													Created at: from oldest to newest
												</MenuItem>
												<MenuItem value="created_at,desc">
													Created at: from newest to oldest
												</MenuItem>
												{/* <MenuItem value="modified_at,asc">
                    Modified at: from oldest to newest
                  </MenuItem>
                  <MenuItem value="modified_at,desc">
                    Modified at: from newest to oldest
                  </MenuItem>
                  <MenuItem value="activity_at,asc">
                    Activity at: from oldest to newest
                  </MenuItem>
                  <MenuItem value="activity_at,desc">
                    Activity at: from newest to oldest
                  </MenuItem> */}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						gap: 1,
						justifyContent: { xs: 'flex-start' },
						marginTop: '24px',
						marginBottom: '24px',
					}}
				>
					<HasAccess
						roles={defaultRoles}
						permissions="task.create"
						renderAuthFailed={
							<StandardButton
								type="disabled"
								value="Create example"
								icon={<AddIcon />}
							/>
						}
					>
						<StandardButton
							value="Create example"
							handleClick={openCreateExampleView}
							icon={<AddIcon />}
						/>
					</HasAccess>
					<HasAccess
						roles={defaultRoles}
						permissions="task.delete"
						renderAuthFailed={
							<StandardButton
								type="disabled"
								value="Update example"
								icon={<EditIcon />}
							/>
						}
					>
						{currentRowId === '' ? (
							<StandardButton
								type="disabled"
								value="Update example"
								icon={<EditIcon />}
							/>
						) : (
							<StandardButton
								value="Update example"
								handleClick={() => setOpenEditExampleModal(true)}
								icon={<EditIcon />}
							/>
						)}
					</HasAccess>
					<HasAccess
						roles={defaultRoles}
						permissions="task.delete"
						renderAuthFailed={
							<StandardButton
								type="disabled"
								value="Delete example"
								icon={<DeleteIcon />}
							/>
						}
					>
						{selectedRows.length === 0 ? (
							<StandardButton
								type="disabled"
								value="Delete example"
								icon={<DeleteIcon />}
							/>
						) : (
							<StandardButton
								value={
									selectedRows.length <= 1
										? 'Delete example'
										: 'Delete examples'
								}
								handleClick={() => setOpenDeleteExampleModal(true)}
								icon={<DeleteIcon />}
							/>
						)}
					</HasAccess>
				</Grid>
				<Grid container>
					<Grid
						item
						sx={{
							width: { xs: '100%', md: '150px' },
							display: 'flex',
							gap: 1,
							justifyContent: { xs: 'flex-start' },
							fontSize: '13px',
							fontWeight: 600,
							letterSpacing: '0.14px',
							color: '#1492E6',
						}}
					>
						Icons color legend:
					</Grid>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							display: 'flex',
							gap: 4,
							color: '#545454',
							fontSize: '13px',
							fontWeight: 600,
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskInput,
									marginRigth: '12px',
								}}
							/>
							Inputs
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskMetaData,
									marginRigth: '12px',
								}}
							/>
							Metadata
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskAnomaly,
									marginRigth: '12px',
								}}
							/>
							Anomalies
						</div>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ marginBottom: '12px' }}>
					<div style={styles.examplesFound}>
						<div style={styles.examplesFoundTitle}>Examples:</div>
						<div style={styles.examplesFoundCount}>
							{totalExamplesState} examples found
						</div>
					</div>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={styles.tableContainer}>
							<ExamplesTable
								rows={rows}
								currentColumns={currentColumns}
								setCurrentCellId={setCurrentCellId}
								setCurrentRowId={setCurrentRowId}
								currentRowId={currentRowId}
								openEditExampleModal={openEditExampleModal}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								filters={filters}
								setFilters={setFilters}
							/>
						</Box>
					</Grid>
					<CustomPagination
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						total={totalExamplesState}
					/>
				</Grid>
			</Container>
			{openCreateExampleModal && (
				<CreateOrUpdateExampleModal
					open={openCreateExampleModal}
					setOpen={setOpenCreateExampleModal}
					type="create"
					allColumns={allColumns}
					schemaState={schemaState}
					setCurrentRowId={setCurrentRowId}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openEditExampleModal && (
				<CreateOrUpdateExampleModal
					open={openEditExampleModal}
					setOpen={setOpenEditExampleModal}
					type="update"
					step={1}
					allColumns={allColumns}
					currentRowId={currentRowId}
					setOpenDeleteExampleModal={setOpenDeleteExampleModal}
					setCurrentRowId={setCurrentRowId}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openEditElementModal && (
				<EditElementModal
					open={openEditElementModal}
					setOpen={setOpenEditElementModal}
					currentCellId={currentCellId}
					setCurrentCellId={setCurrentCellId}
					currentRowId={currentRowId}
					setCurrentRowId={setCurrentRowId}
					allColumns={allColumns}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openCommentsModal && (
				<CommentsModal
					open={openCommentsModal}
					setOpen={setOpenCommentsModal}
					currentRowId={currentRowId}
					currentTaskId={currentTaskState.uuid}
				/>
			)}
			{openDeleteExampleModal && (
				<DeleteExampleModal
					open={openDeleteExampleModal}
					setOpen={setOpenDeleteExampleModal}
					setOpenEditExampleModal={setOpenEditExampleModal}
					currentRowId={currentRowId}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					setCurrentRowId={setCurrentRowId}
				/>
			)}
			{openEditViewModal && (
				<EditViewModal
					open={openEditViewModal}
					setOpen={setOpenEditViewModal}
					allColumns={allColumns}
					currentColumns={currentColumns}
					setCurrentColumns={setCurrentColumns}
				/>
			)}
			{openQueryBuilderModal && (
				<QueryBuilderModal
					open={openQueryBuilderModal}
					setOpen={setOpenQueryBuilderModal}
					allColumns={allColumns}
					query={query}
					setQuery={setQuery}
					filters={filters}
					setFilters={setFilters}
				/>
			)}
		</HasAccess>
	);
}
