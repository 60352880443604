import {
	faCheck,
	faBan,
	faTag,
	faFilePen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { UPDATE_EXAMPLE } from '../../../../../redux/examples.slice';

export const StatusEditor = ({ styles, currentValue, setCurrentValue }) => {
	const { currentExample: currentExampleState } = useSelector(
		(state) => state.examples
	);

	useEffect(() => {
		setCurrentValue(currentExampleState.labeling_status);
	}, [currentExampleState]);

	const handleChangeCurrentStatus = (e) => {
		setCurrentValue(e.target.value);
	};

	return (
		<Box sx={styles.exampleColumnsContainer}>
			<Box sx={styles.exampleColumnsRow}>
				<Box sx={styles.nameColumn}>STATUS</Box>
				<Box sx={styles.inputColumn}>
					<Select
						value={currentValue || currentExampleState.labeling_status}
						onChange={handleChangeCurrentStatus}
						SelectDisplayProps={{
							style: {
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							},
						}}
					>
						<MenuItem
							value="labeled"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							}}
						>
							<FontAwesomeIcon
								icon={faCheck}
								style={{
									fontSize: '18px',
								}}
							/>
							Labeled
						</MenuItem>
						<MenuItem
							value="unlabeled"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							}}
						>
							<FontAwesomeIcon
								icon={faTag}
								style={{
									fontSize: '18px',
								}}
							/>
							Unlabeled
						</MenuItem>
						<MenuItem
							value="pending_review"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							}}
						>
							<FontAwesomeIcon
								icon={faFilePen}
								style={{
									fontSize: '18px',
								}}
							/>
							Pending review
						</MenuItem>
						<MenuItem
							value="rejected"
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '6px',
							}}
						>
							<FontAwesomeIcon
								icon={faBan}
								style={{
									fontSize: '18px',
								}}
							/>
							Rejected
						</MenuItem>
					</Select>
				</Box>
			</Box>
		</Box>
	);
};

StatusEditor.propTypes = {
	styles: PropTypes.object,
	currentValue: PropTypes.any,
	setCurrentValue: PropTypes.func,
};
