import { colors } from '../../../../../../consts/colors';

export const styles = {
	commentContainer: {
		display: 'flex',
		flexDirection: 'column !important',
		backgroundColor: colors.lightBlueButton,
		borderRadius: '0px 12px 12px 12px',
		padding: '6px',
		paddingLeft: '12px',
		marginBottom: '24px !important',
	},
};
