import React, { useEffect, useState } from 'react'; //  { useEffect }
import PropTypes from 'prop-types';

// Components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import CardItem from '../CardItem';

// Styles
import { styles } from './styles';

// Hooks
import { useWindowSize } from '../../../services/hooks/useWindowResize';

function StandardModal({
	open,
	setOpen,
	type = 'noIcon',
	title,
	content,
	actions = null,
	handleSubmit = null,
	size,
	titlelink,
	closeIcon,
	maxWidth = 'md',
}) {
	const handleClose = () => {
		setOpen(!open);
	};

	return (
		<Dialog
			sx={{
				'& .MuiDialog-paper': {
					width: '100%',
					padding: '15px 28px 30px 28px !important',
					borderRadius: '24px !important',
					boxShadow: '0px 3px 15px #0000004f !important',
				},
				...styles.modalContainer,
			}}
			maxWidth={maxWidth}
			open={open}
			onClose={setOpen}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>{content}</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	);
}

StandardModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	type: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.object.isRequired,
	actions: PropTypes.object,
	handleSubmit: PropTypes.func,
	size: PropTypes.string,
	titlelink: PropTypes.any,
	closeIcon: PropTypes.func,
	maxWidth: PropTypes.string,
};

export default StandardModal;

/* <Modal
aria-labelledby="transition-modal-title"
aria-describedby="transition-modal-description"
open={open}
onClose={handleClose}
closeAfterTransition
>
<Fade in={open}>
  <Box>
    {!!title && (
      <CardItem
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '100%',
            sm: '100%',
            md: '90%',
            lg: size || '60%',
          },
          maxHeight: '95vh',
          minWidth: '200px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '30px 40px !important',
          borderRadius: '35px !important',
          overflowY: 'auto !important',
          '&::-webkit-scrollbar-track': {
            marginTop: '35px',
            marginBottom: '35px',
          },
        }}
        type={type}
        title={title}
        titlelink={titlelink}
        closeIcon={closeIcon}
        open={open}
        setOpen={setOpen}
      >
        {handleSubmit !== null ? (
          <form onSubmit={handleSubmit}>
            <div className={classes.contentContainer}>{content}</div>
            <div className={classes.actionsContainer}>
              <button type="submit">Formik submit</button>
              {actions}
            </div>
          </form>
        ) : (
          <>
            <DialogContent>
              <div className={classes.contentContainer}>{content}</div>
            </DialogContent>
            <DialogActions>
              <div className={classes.actionsContainer}>{actions}</div>
            </DialogActions>
          </>
        )}
      </CardItem>
    )}
    {!title && (
      <CardItem
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '100%',
            sm: '100%',
            md: '90%',
            lg: size || '60%',
          },
          minWidth: '200px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '30px 40px !important',
          borderRadius: '35px !important',
        }}
        type={type}
      >
        <Dialog sx={{}}>
          <div className={classes.contentContainer}>{content}</div>
          <div className={classes.actionsContainer}>{actions}</div>
        </Dialog>
      </CardItem>
    )}
  </Box>
</Fade>
</Modal> */
