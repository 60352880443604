import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePermify } from '@permify/react-role';

// Components
import {
	DataGridPro,
	GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { Loader } from '../../../../../../Components/Shared/Loader';

// Styles
import { colors } from '../../../../../../consts/colors';

// Services
import checkIsAuthorized from '../../../../../../services/checkIsAuthorized';

export const AIPredictionsTable = ({
	rows,
	currentColumns,
	setCurrentCellId,
	setCurrentRowId,
	currentRowId,
	setSelectedRows,
	filters,
	setFilters,
	selectedRows,
}) => {
	const { isAuthorized } = usePermify();

	const totalExamples = useSelector((state) => state.examples.totalExamples);
	const { isLoading: isLoadingPredictionsState } = useSelector(
		(state) => state.predictions
	);

	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(100);
	const [canUpdate, setCanUpdate] = useState(false);
	const [sortModel, setSortModel] = useState();

	useEffect(() => {
		checkIsAuthorized({
			isAuthorized,
			setCanUpdate,
			permission: 'task.update',
		});
	}, []);

	useEffect(() => {
		if (sortModel?.length > 0) {
			const { field, sort } = sortModel[0];
			setFilters({ ...filters, order: sort, order_by: field });
		}
	}, [sortModel]);

	if (isLoadingPredictionsState) {
		return (
			<div
				style={{
					width: '100%',
					height: '500px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Loader size="L" local />;
			</div>
		);
	}

	return (
		currentColumns &&
		currentColumns.length > 0 && (
			<DataGridPro
				initialState={{
					pinnedColumns: {
						left: [
							GRID_CHECKBOX_SELECTION_COL_DEF.field,
							'airelease',
							'predictedAnomalies',
						],
					},
				}}
				getRowClassName={(params) =>
					params.row.withAnomaly && 'row_with_anomaly'
				}
				rows={rows}
				columns={currentColumns}
				checkboxSelection
				sortingMode="server"
				sortModel={sortModel}
				onSortModelChange={(model) => setSortModel(model)}
				onRowClick={(row) => setCurrentRowId(row.id)}
				rowSelection
				rowSelectionModel={selectedRows}
				onRowSelectionModelChange={(selectionModel) => {
					console.log(selectionModel);
					if (selectionModel.length > 1 || selectionModel.length === 0) {
						setCurrentRowId('');
					} else {
						setCurrentRowId(selectionModel[0]);
					}
					setSelectedRows(selectionModel);
				}}
				disableSelectionOnClick
				hideFooterPagination
				sx={{
					border: 'none',
					'.MuiDataGrid-row': {
						cursor: 'pointer',
					},
					'.MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
						outline: 'none',
						// width: '50px !important',
						minWidth: '50px !important',
						maxWidth: '50px !important',
						// backgroundColor: colors.gray,
					},
					'.MuiDataGrid-columnHeader:hover': {
						background: 'rgba(0, 0, 0, 0.04)',
					},
					'.MuiDataGrid-columnHeader:focus-within': {
						outline: 'none',
						background: 'rgba(0, 0, 0, 0.04)',
					},
					'.MuiBox-root': {
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
						fontSize: '13px',
						fontWeight: '600',
						letterSpacing: '0.14px',
						color: '#545454',
					},
					// '.MuiDataGrid-cell': {
					// 	minWidth: '140px !important',
					// 	maxWidth: '140px !important',
					// },
					'.MuiDataGrid-cellCheckbox.MuiDataGrid-cell': {
						width: '50px !important',
						minWidth: '50px !important',
						maxWidth: '50px !important',
						backgroundColor: colors.ligthGray,
					},
					'.MuiDataGrid-cell .MuiTypography-subtitle1': {
						whiteSpace: 'nowrap',
						display: 'block',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					// Personaliced classes
				}}
			/>
		)
	);
};

AIPredictionsTable.propTypes = {
	currentColumns: PropTypes.array,
	rows: PropTypes.array,
	setCurrentCellId: PropTypes.func,
	setCurrentRowId: PropTypes.func,
	currentRowId: PropTypes.string,
	setSelectedRows: PropTypes.func,
	filters: PropTypes.object,
	setFilters: PropTypes.func,
	selectedRows: PropTypes.array,
};
