export const statuses = {
	// Task
	'00000': {
		name: 'Task created',
		description: 'Waiting for enough examples to learn the task',
		color: '#c8e6c9',
	},
	'00001': {
		name: 'Deploying AI',
		description: 'Deploying a new release of the AI',
		color: '#ffe0b2',
	},
	'00002': {
		name: 'Task is active',
		description: 'Task is active',
		color: '#c8e6c9',
	},
	'00003': {
		name: 'Task paused',
		description: 'Task paused',
		color: '#ffe0b2',
	},
	'00004': {
		name: 'Resuming task',
		description: 'Resuming task',
		color: '#ffe0b2',
	},
	'00005': {
		name: 'Task suspended',
		description: 'Task suspended',
		color: '#ffe0b2',
	},
	'00006': {
		name: 'Task canceled',
		description: 'Task canceled',
		color: '#ffcdd2',
	},
	'00007': {
		name: 'Copying task',
		description: 'Copying the task',
		color: '#ffe0b2',
	},

	// Services
	service_disabled: {
		name: 'Service disabled',
		description: 'This service is disabled',
		color: '#ffcdd2',
	},

	// Inference
	'01000': {
		name: 'Inference Service stopped',
		description: 'AI is not running and will not make any prediction',
		color: '#ffe0b2',
	},
	'01001': {
		name: 'Inference Service is idle',
		description: 'AI is ready to make predictions',
		color: '#c8e6c9',
	},
	'01002': {
		name: 'Inference Service is busy',
		description: 'AI is making predictions',
		color: '#ffe0b2',
	},

	// Continual learning
	'02000': {
		name: 'Continual Learning stopped',
		description: 'AI will not retrain	',
		color: '#ffe0b2',
	},
	'02001': {
		name: 'Continual Learning is idle	',
		description: 'AI is waiting for the next retraining	',
		color: '#c8e6c9',
	},
	'02002': {
		name: 'Continual Learning starting',
		description: 'Continual Learning is starting	',
		color: '#ffe0b2',
	},
	'02003': {
		name: 'Continual Learning in progress	',
		description: 'Continual Learning is in progress	',
		color: '#ffe0b2',
	},
	'02004': {
		name: 'Continual Learning finishing	',
		description: 'The new AI model is being deployed',
		color: '#ffe0b2',
	},

	// Active learning
	'03000': {
		name: 'Active Learning stopped	',
		description: 'AI will not ask experts for data labels	',
		color: '#ffe0b2',
	},
	'03001': {
		name: 'Active Learning is idle	',
		description: 'AI is waiting for data requiring labels from experts	',
		color: '#c8e6c9',
	},
	'03002': {
		name: 'Active Learning is busy	',
		description: 'AI is asking experts for data labels	',
		color: '#ffe0b2',
	},

	// Monitoring
	'04000': {
		name: 'Monitoring Service stopped	',
		description: 'Monitoring is not running	',
		color: '#ffe0b2',
	},
	'04001': {
		name: 'Monitoring Service running	',
		description: 'Monitoring is running	',
		color: '#ffe0b2',
	},

	// Unknown
	80000: {
		name: 'Task state is unknown',
		description: 'Cannot determine task state',
		color: '#ffcdd2',
	},
	80100: {
		name: 'Inference Service state is unknown	',
		description: 'Cannot determine inference state	',
		color: '#ffcdd2',
	},
	80200: {
		name: 'Continual Learning state is unknown	',
		description: 'Cannot determine continual learning state	',
		color: '#ffcdd2',
	},
	80300: {
		name: 'Active Learning state is unknown	',
		description: 'Cannot determine active learning state	',
		color: '#ffcdd2',
	},
	80400: {
		name: 'Monitoring Service state is unknown		',
		description: 'Cannot determine monitoring state	',
		color: '#ffcdd2',
	},

	// Errors
	90000: {
		name: 'Task unknown error	',
		description: 'Unknown error with task	',
		color: '#ffcdd2',
	},
	90100: {
		name: 'Inference unknown error	',
		description: 'Unknown error during inference	',
		color: '#ffcdd2',
	},
	90101: {
		name: 'Inference environment error	',
		description: 'Error with OS, Docker, or requirements during inference	',
		color: '#ffcdd2',
	},
	90102: {
		name: 'Inference connection error	',
		description: 'Failed to connect during inference	',
		color: '#ffcdd2',
	},
	90103: {
		name: 'Inference data error	',
		description: 'Invalid data for inference	',
		color: '#ffcdd2',
	},
	90104: {
		name: 'Inference schema error	',
		description: 'Invalid schema for inference	',
		color: '#ffcdd2',
	},
	90105: {
		name: 'Inference AI model error	',
		description: 'Something is wrong with the AI model used for inference	',
		color: '#ffcdd2',
	},
	90200: {
		name: 'Continual learning unknown error	',
		description: 'Unknown error during continual learning	',
		color: '#ffcdd2',
	},
	90201: {
		name: 'Continual learning environment error	',
		description:
			'Error with OS, Docker, or requirements during continual learning	',
		color: '#ffcdd2',
	},
	90202: {
		name: 'Continual learning connection error	',
		description: 'Failed to connect during continual learning	',
		color: '#ffcdd2',
	},
	90203: {
		name: 'Continual learning data error	',
		description: 'Invalid data for continual learning	',
		color: '#ffcdd2',
	},
	90204: {
		name: 'Continual learning schema error	',
		description: 'Invalid schema for continual learning	',
		color: '#ffcdd2',
	},
	90205: {
		name: 'Continual learning AI model error	',
		description: 'Invalid AI model for continual learning	',
		color: '#ffcdd2',
	},
	90300: {
		name: 'Active learning unknown error	',
		description: 'Unknown error during active learning	',
		color: '#ffcdd2',
	},
	90301: {
		name: 'Active learning connection error	',
		description: 'Failed to connect during active learning	',
		color: '#ffcdd2',
	},
	90302: {
		name: 'Active learning data error	',
		description: 'Invalid data for active learning	',
		color: '#ffcdd2',
	},
	90400: {
		name: 'Monitoring unknown error	',
		description: 'Unknown error during monitoring	',
		color: '#ffcdd2',
	},
	90401: {
		name: 'Monitoring connection error	',
		description: 'Failed to connect during monitoring	',
		color: '#ffcdd2',
	},
	90402: {
		name: 'Monitoring data error	',
		description: 'Invalid data for monitoring	',
		color: '#ffcdd2',
	},
};
