import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	BarChart,
	Bar,
	Rectangle,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Cell,
} from 'recharts';
import { colors } from '../../../../consts/colors';

const StandardBarChart = (props) => {
	const { data, anomalyColor } = props;

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={data}
				margin={{
					top: 20,
				}}
				barSize={50}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis tick />
				<Tooltip />
				<Bar dataKey="value" label={{ position: 'top' }}>
					{data.map((entry, index) => (
						<Cell
							key={`cell-${index}`}
							fill={
								anomalyColor?.length > 0
									? anomalyColor[index]
									: colors.buttonPrimaryColor
							}
						/>
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

StandardBarChart.propTypes = {
	data: PropTypes.any,
	anomalyColor: PropTypes.any,
};

export default StandardBarChart;
