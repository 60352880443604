import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import { styles } from './styles';

const sizes = {
	S: 25,
	M: 50,
	L: 75,
};

export const Loader = ({ size, local }) => {
	if (local) {
		return <CircularProgress size={sizes[size]} />;
	}

	return (
		<Box sx={styles.container}>
			<CircularProgress size={sizes[size]} />
		</Box>
	);
};

Loader.propTypes = {
	size: PropTypes.string,
	local: PropTypes.bool,
};

Loader.defaultProps = {
	size: 'M',
	local: false,
};
