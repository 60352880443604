import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Alert } from '@mui/material';

// Redux
import { REMOVE_ALERT } from '../../../redux/alerts.slice';

// Styles
import { styles } from './styles.js';
import './styles.modules.css';

// Example to add tmp alerts for the moment
// dispatch(ADD_ALERT({ type: 'info', message: 'info test alert' }));

// Remove alert
// setTimeout(() => {
//   dispatch(REMOVE_ALERT('info test alert'));
// }, 2000);

function AlertsManager() {
	const dispatch = useDispatch();

	const alerts = useSelector((state) => state.alerts);

	const handleRemoveAlert = (message) => {
		dispatch(REMOVE_ALERT(message));
	};

	useEffect(() => {
		if (alerts.list && alerts.list.length > 0) {
			const tmpAlert = alerts.list.find((alert) => alert.type !== 'error');
			if (tmpAlert) {
				setTimeout(() => {
					handleRemoveAlert(tmpAlert.message);
				}, 5000);
			}
		}
	}, [alerts.list]);

	return (
		<div style={styles.mainAlertContainer}>
			{alerts.list &&
				alerts.list.length > 0 &&
				alerts.list.map((alert) => (
					<div key={uuidv4()} className="alert-container">
						{alert.type === 'error' ? (
							<Alert
								sx={styles[alert.type]}
								severity="error"
								onClose={() => handleRemoveAlert(alert.message)}
							>
								{alert.message}
							</Alert>
						) : (
							<Alert sx={styles[alert.type]} severity={alert.type}>
								{alert.message}
							</Alert>
						)}
					</div>
				))}
		</div>
	);

	// if (alerts.list.length > 0) {
	// 	alerts.list.forEach((alert) => {
	// 		if (alert.type === 'error') {
	// 			return (
	// 				<div key={uuidv4()} className="alert-container">
	// 					<Alert
	// 						className={classes.error}
	// 						severity="error"
	// 						onClose={() => handleRemoveAlert()}
	// 					>
	// 						<div key={uuidv4()}>{alert.message}</div>
	// 					</Alert>
	// 				</div>
	// 			);
	// 		}

	// 		if (alert.type === 'warning') {
	// 			return (
	// 				<div key={uuidv4()} className="alert-container">
	// 					<Alert className={classes.warning} severity="warning">
	// 						{alert.message}
	// 					</Alert>
	// 				</div>
	// 			);
	// 		}

	// 		if (alert.type === 'info') {
	// 			return (
	// 				<div key={uuidv4()} className="alert-container">
	// 					<Alert className={classes.info} severity="info">
	// 						{alert.message}
	// 					</Alert>
	// 				</div>
	// 			);
	// 		}

	// 		if (alert.type === 'success') {
	// 			return (
	// 				<div key={uuidv4()} className="alert-container">
	// 					<Alert className={classes.success} severity="success">
	// 						{alert.message}
	// 					</Alert>
	// 				</div>
	// 			);
	// 		}
	// 	});
	// }

	// if (alerts.error !== null) {
	// 	return (
	// 		<div key={uuidv4()} className="alert-container">
	// 			<Alert
	// 				className={classes.error}
	// 				severity="error"
	// 				onClose={() => handleRemoveAlert()}
	// 			>
	// 				<div key={uuidv4()}>{alerts.error}</div>
	// 			</Alert>
	// 		</div>
	// 	);
	// }

	// if (alerts.warning !== null) {
	// 	return (
	// 		<div key={uuidv4()} className="alert-container">
	// 			<Alert className={classes.warning} severity="warning">
	// 				{alerts.warning}
	// 			</Alert>
	// 		</div>
	// 	);
	// }

	// if (alerts.info !== null) {
	// 	return (
	// 		<div key={uuidv4()} className="alert-container">
	// 			<Alert className={classes.info} severity="info">
	// 				{alerts.info}
	// 			</Alert>
	// 		</div>
	// 	);
	// }

	// if (alerts.success !== null) {
	// 	return (
	// 		<div key={uuidv4()} className="alert-container">
	// 			<Alert className={classes.success} severity="success">
	// 				{alerts.success}
	// 			</Alert>
	// 		</div>
	// 	);
	// }
}

export default AlertsManager;
