/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import {
	Box,
	Fade,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckIcon from '@mui/icons-material/Check';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';

// Styles
import { colors } from '../../../../../../consts/colors';
import { getElementToEdit } from '../../../Examples/elementEditor.service';

import { elementTypeToIconMap } from '../../../../../../services/tasks';
import { createCell } from '../../../Examples/cellCreation.services';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';

// import { getElementToEdit } from '../../elementEditor.service';

// Redux

export const PredictionViewModal = ({
	open,
	setOpen,
	currentRowId,
	setCurrentRowId,
	currentCellId,
	setCurrentCellId,
	allColumns,
	rows,
	styles,
	setSelectedRows,
}) => {
	// Global states
	const { currentPrediction: currentPredictionState } = useSelector(
		(state) => state.predictions
	);
	const schemaState = useSelector((state) => state.schema);
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);
	const {
		imagesBuffer: imagesBufferState,
		documentsBuffer: documentsBufferState,
		documentsBufferIsLoading: onDocumentLoadSuccess,
	} = useSelector((state) => state.examples);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	// Local states
	const [currentRow, setCurrentRow] = useState({});
	const [currentElement, setCurrentElement] = useState({});
	const [currentElementValueType, setCurrentElementValueType] = useState('');
	const [sortedPredictedAnomalies, setSortedPredictedAnomalies] = useState([]);
	const [anomalyDetected, setAnomalyDetected] = useState([]);

	const [searchByAnomaly, setSearchByAnomaly] = useState('');
	const [filteredAnomalies, setFilteredAnomalies] = useState(
		schemaState.anomaly_types || []
	);

	// Modals
	const [openFileViewerModal, setOpenFileViewerModal] = useState(false);

	useEffect(() => {
		if (currentPredictionState?.outputs) {
			let sorted = [];
			const tmp = currentPredictionState?.outputs;

			if (tmp) {
				const sortable = [];

				tmp.forEach((output) => {
					sortable.push([output.element, output.value]);
				});

				sorted = sortable.sort((a, b) => b[1] - a[1]).slice(0, 5);
			}

			setSortedPredictedAnomalies(sorted);
		}
	}, [currentPredictionState?.outputs]);

	useEffect(() => {
		setAnomalyDetected(
			sortedPredictedAnomalies.filter(
				(anomaly) =>
					schemaState.anomaly_types.find((anom) => anomaly[0] === anom.name)
						?.threshold < anomaly[1]
			)
		);
	}, [sortedPredictedAnomalies]);

	useEffect(() => {
		setCurrentRow(rows.find((row) => row.id === currentRowId));
	}, [currentRowId]);

	useEffect(() => {
		const inputOrder = [
			'image',
			'document',
			'timeserie',
			'text',
			'category',
			'number',
		];

		allColumns.sort((a, b) => {
			const typeA = inputOrder.indexOf(a.valueType);
			const typeB = inputOrder.indexOf(b.valueType);

			// If types are not in the specified order, prioritize based on their index
			if (typeA === -1 && typeB === -1) {
				return (
					inputOrder.indexOf(a.valueType) - inputOrder.indexOf(b.valueType)
				);
			}

			return typeA - typeB;
		});
	}, [allColumns]);

	useEffect(() => {
		setCurrentElement({});
		if (Object.keys(schemaState).length > 0 && currentCellId !== '') {
			// Check inputs
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.name === currentCellId) {
						setCurrentElementValueType(input.type);
					}
				});
			}

			// Check metadata
			if (
				schemaState.metadata_fields &&
				schemaState.metadata_fields.length > 0
			) {
				schemaState.metadata_fields.forEach((meta) => {
					if (meta.name === currentCellId) {
						setCurrentElementValueType(meta.type);
					}
				});
			}

			// Check outputs
			if (schemaState.anomaly_types && schemaState.anomaly_types.length > 0) {
				schemaState.anomaly_types.forEach((output) => {
					if (output.name === currentCellId) {
						setCurrentElementValueType('');
						// setCurrentElementValueType(output.type);
						// setCurrentElementType('anomaly_fields');
					}
				});
			}

			if (
				currentPredictionState.inputs &&
				currentPredictionState.inputs.find(
					(input) => input.element === currentCellId
				)
			) {
				currentPredictionState.inputs.forEach((input) => {
					if (input.element === currentCellId) setCurrentElement(input);
				});
			}

			if (
				currentPredictionState.metadata_fields &&
				currentPredictionState.metadata_fields.find(
					(meta) => meta.element === currentCellId
				)
			)
				currentPredictionState.metadata_fields.forEach((meta) => {
					if (meta.element === currentCellId) setCurrentElement(meta);
				});

			// if (
			// 	currentPredictionState.anomaly_types &&
			// 	currentPredictionState.anomaly_types.find(
			// 		(anomaly) => anomaly.name === currentCellId
			// 	)
			// ) {
			// 	currentPredictionState.anomaly_types.forEach((anomaly) => {
			// 		if (anomaly.name === currentCellId) setCurrentElement(anomaly);
			// 	});
			// }
		}
	}, [currentCellId, currentRowId]);

	// useEffect(() => {
	// 	console.log(currentPredictionState?.outputs);
	// 	setSortedPredictedAnomalies(
	// 		filteredAnomalies.map((anom) =>
	// 			currentPredictionState?.outputs.map(
	// 				(anomaly) =>
	// 					anomaly.element === anom.name && [anomaly.element, anomaly.value]
	// 			)
	// 		)
	// 	);
	// }, [filteredAnomalies]);

	const handleOpenFileViewer = (columnId) => {
		setCurrentCellId(columnId || '');
		setOpenFileViewerModal(!openFileViewerModal);
	};

	const handleClose = () => {
		setCurrentCellId('');
		setCurrentRowId('');
		setSelectedRows([]);
		setOpen(false);
	};

	const highlightMatches = (item, type) => {
		const lowerCaseItem = item.toLowerCase();
		const result = [];

		const searchText = searchByAnomaly;

		let currentIndex = 0;

		for (let i = 0; i < searchText.length; i += 1) {
			const char = searchText[i];
			const index = lowerCaseItem.indexOf(char.toLowerCase(), currentIndex);

			if (index !== -1) {
				result.push(<span key={i}>{item.substring(currentIndex, index)}</span>);
				result.push(
					<span key={`${i}-highlight`} style={styles.highlight}>
						{item.substring(index, index + char.length)}
					</span>
				);
				currentIndex = index + char.length;
			}
		}

		result.push(<span key="remaining">{item.substring(currentIndex)}</span>);

		return result;
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Prediction info:"
			maxWidth="xl"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'start',
					}}
				>
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
							md={openFileViewerModal ? 4 : 6}
							sx={{
								overflowY: 'auto',
								maxHeight: '550px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'start',
								transition: 'all 1s',
								margin: '1rem 0',
								paddingRight: '12px',
							}}
						>
							{anomalyDetected.length > 0 ? (
								<h2
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center',
										fontWeight: 'bolder',
										color: colors.darkGray,
									}}
								>
									<WarningRoundedIcon
										sx={{ fontSize: '70px', color: colors.errorAlertDark }}
									/>
									{anomalyDetected.length > 1 ? 'Anomalies' : 'Anomaly'}
									{' detected!'}
								</h2>
							) : (
								<h2
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center',
										fontWeight: 'bolder',
										color: colors.darkGray,
									}}
								>
									<CheckIcon
										sx={{ fontSize: '70px', color: colors.successAlertDark }}
									/>
									No anomaly detected
								</h2>
							)}
							{anomalyDetected.length > 0 && (
								<>
									<h4 style={{ padding: '6px', margin: 0 }}>Anomalies:</h4>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											marginBottom: '12px',
										}}
									>
										{anomalyDetected.map((anomaly) => (
											<AnomalyChip
												tooltip
												name={anomaly[0]}
												color={
													schemaState.anomaly_types?.find(
														(anom) => anom.name === anomaly[0]
													)?.color
												}
											/>
										))}
									</div>
								</>
							)}

							<Grid container sx={{ padding: '6px' }}>
								<Grid item xs={3} style={{ width_: '20%' }}>
									<h4 style={{ padding: '6px', margin: 0 }}>
										Search by anomaly:
									</h4>
								</Grid>
								<Grid item xs={9} sx={styles.searchContainer}>
									<div style={styles.searchContainer}>
										<div style={styles.searchIconWrapper}>
											<SearchIcon />
										</div>
										<input
											key={uuidv4}
											type="text"
											style={styles.styledInputBase}
											// onBlur={() => setOpenSearchAnomaly(false)}
											onChange={(e) => {
												const { value } = e.target;
												let filteredItems = [];
												filteredItems = schemaState.anomaly_types.filter(
													(item) =>
														item.name
															.toLowerCase()
															.includes(value.toLowerCase())
												);

												setSearchByAnomaly(value);
												setFilteredAnomalies(filteredItems);
											}}
											value={searchByAnomaly}
										/>
									</div>
								</Grid>
							</Grid>

							<table style={{ width: '100%' }}>
								<TableRow>
									<td
										style={{
											borderBottom: `1px solid ${colors.gray}`,
											padding: '6px',
											width: '40%',
										}}
									>
										<h4 style={{ padding: '6px', margin: 0 }}>Probabilities</h4>
									</td>
									<td
										style={{
											borderBottom: `1px solid ${colors.gray}`,
											padding: '6px',
											textAlign: 'center',
											width: '30%',
										}}
									>
										<h4 style={{ padding: '6px', margin: 0 }}>Threshold</h4>
									</td>
									<td
										style={{
											// display: 'flex',
											// justifyContent: 'right',
											borderBottom: `1px solid ${colors.gray}`,
											padding: '6px',
											textAlign: 'center',
											width: '30%',
										}}
									>
										<h4 style={{ padding: '6px', margin: 0 }}>Score</h4>
									</td>
								</TableRow>
							</table>
							{sortedPredictedAnomalies &&
								sortedPredictedAnomalies.map(
									(anomaly) =>
										filteredAnomalies.find(
											(anom) => anom.name === anomaly[0]
										) && (
											<table style={{ width: '100%' }}>
												<TableRow>
													<td
														style={{
															borderBottom: `1px solid ${colors.gray}`,
															padding: '6px',
															width: '40%',
														}}
													>
														{highlightMatches(anomaly[0], 'anomalies')}
													</td>
													<td
														style={{
															borderBottom: `1px solid ${colors.gray}`,
															padding: '6px',
															textAlign: 'center',
															// display: 'flex',
															// justifyContent: 'center',
															width: '30%',
														}}
													>
														{(
															anomalyTypesState.find(
																(anom) => anom.name === anomaly[0]
															)?.threshold * 100 || ''
														).toFixed(2)}
														%
													</td>
													<td
														style={{
															// display: 'flex',
															// justifyContent: 'right',
															borderBottom: `1px solid ${colors.gray}`,
															padding: '6px',
															textAlign: 'center',
															width: '30%',
														}}
													>
														{(anomaly[1] * 100).toFixed(2)}%
													</td>
												</TableRow>
											</table>
										)
								)}
						</Grid>
						<Grid
							item
							xs={12}
							md={openFileViewerModal ? 8 : 6}
							sx={{
								overflowY: 'auto',
								maxHeight: '550px',
								transition: 'all 1s',
								display: 'flex',
								margin: '1rem 0',
								paddingLeft: '12px',
							}}
						>
							{openFileViewerModal ? (
								<Fade
									in={openFileViewerModal}
									out={!openFileViewerModal}
									timeout={500}
								>
									<Grid container xs={11}>
										<Grid
											xs={12}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'start',
												paddingBottom: '12px',
											}}
										>
											{
												// DOCUMENT FILE TYPE
												currentElementValueType === 'document' &&
													currentElement &&
													getElementToEdit[currentElementValueType]({
														currentElement,
														currentCellId,
														currentRowId,
														allColumns,
														disabled: true,
														handleOpenFileViewer,
														predictionsView: true,
													})
											}
											{
												// IMAGE TYPE
												currentElementValueType === 'image' &&
													currentElement &&
													getElementToEdit[currentElementValueType]({
														currentElement,
														currentCellId,
														currentRowId,
														allColumns,
														disabled: true,
														handleOpenFileViewer,
														predictionsView: true,
													})
											}
											{
												// TEXT TYPE
												currentElementValueType === 'text' &&
													currentElement &&
													getElementToEdit[currentElementValueType]({
														styles,
														currentElement,
														currentCellId,
														currentRowId,
														currentElementValueType,
														disabled: true,
														handleOpenFileViewer,
														predictionsView: true,
													})
											}
											{
												// TIME SERIE TYPE
												currentElementValueType === 'time_series' &&
													getElementToEdit[currentElementValueType]({
														currentElement,
														currentCellId,
														currentRowId,
														allColumns,
														disabled: true,
														predictionsView: true,
														handleOpenFileViewer,
													})
											}
										</Grid>
									</Grid>
								</Fade>
							) : (
								<TableContainer component={Paper}>
									<Table aria-label="simple table">
										<TableBody>
											{allColumns.map(
												(column) =>
													column.fieldType === 'input' && (
														<TableRow key={column.name}>
															<TableCell
																sx={{
																	border: '1px solid gray',
																}}
															>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	{elementTypeToIconMap[column.valueType](
																		'input'
																	)}
																	{column.name}
																</div>
															</TableCell>
															<TableCell
																sx={{
																	border: '1px solid gray',
																}}
															>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																	}}
																>
																	{column.valueType === 'image' ||
																	column.valueType === 'time_series' ? (
																		// <StandardButton
																		// 	value="View image"
																		// 	handleClick={() =>
																		// 		handleOpenFileViewer(column.name)
																		// 	}
																		// />
																		<Box
																			onClick={() =>
																				handleOpenFileViewer(column.name)
																			}
																			sx={{ cursor: 'pointer' }}
																		>
																			{createCell[column.valueType]({
																				styles,
																				cellValue: currentRow[column.id],
																				cellName: column.field,
																				imagesBufferState,
																				documentsBufferState,
																				onDocumentLoadSuccess,
																			})}
																		</Box>
																	) : column.valueType === 'document' ? (
																		currentRow[column.id] && (
																			<StandardButton
																				value="View document"
																				handleClick={() =>
																					handleOpenFileViewer(column.name)
																				}
																			/>
																		)
																	) : column.valueType === 'text' ? (
																		currentRow[column.id]?.length > 80 ? (
																			<Box
																				onClick={() =>
																					handleOpenFileViewer(column.name)
																				}
																				sx={{ cursor: 'pointer' }}
																			>{`${currentRow[column.id].slice(
																				0,
																				80
																			)}...`}</Box>
																		) : (
																			currentRow[column.id]
																		)
																	) : (
																		currentRow[column.id]
																	)}
																</div>
															</TableCell>
														</TableRow>
													)
											)}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</Grid>
					</Grid>
				</Grid>
			}
			actions={
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'right',
						gap: '12px',
					}}
				>
					{/* <StandardButton
						value="Add as example"
						type="disabled"
						// handleClick={handleSave}
						close
					/> */}
					<StandardButton value="Close" handleClick={handleClose} close />
				</div>
			}
		/>
	);
};

PredictionViewModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	currentRowId: PropTypes.string,
	setCurrentRowId: PropTypes.func,
	currentCellId: PropTypes.string,
	setCurrentCellId: PropTypes.func,
	setSelectedRows: PropTypes.any,
	allColumns: PropTypes.array,
	rows: PropTypes.array,
	styles: PropTypes.any,
};
