export const styles = {
	dialogContentContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},

	fieldTypeColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		minWidth: '50%',
		fontWeight: 'bold',
		padding: '0 15px',
	},
	fieldTypeElement: {
		marginTop: '6px',
		width: '100%',
		maxHeight: '250px',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	fieldTypeRow: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '12px',
		width: '50%',
	},
};
