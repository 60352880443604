import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { ConfusionMatrix } from '../../../../../../Components/Shared/Charts/ConfusionMatrix';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import StandardBarChart from '../../../../../../Components/Shared/Charts/StandardBarChart';

// Consts
import StandardPieChart from '../../../../../../Components/Shared/Charts/StandardPieChart';
import { colors } from '../../../../../../consts/colors';
import { CustomTooltip } from '../../../../../../Components/Shared/CustomTooltip';

export const MetricsModal = ({ open, setOpen, selectedModel }) => {
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const [confusionMatrixTab, setConfusionMatrixTab] = useState('complete');
	const [contentTableTab, setContentTableTab] = useState('complete');

	const handleClose = () => {
		setOpen(!open);
	};

	const handleChangeConfusionMatrixTab = (tab) => {
		setConfusionMatrixTab(tab);
	};

	const handleChangeContentTableTab = (tab) => {
		setContentTableTab(tab);
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Stats and Metrics:"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						alignItems: 'start',
						padding: '0 !important',
						margin: '0 !important',
						gap: 12,
					}}
				>
					{!selectedModel.training_stats.examples && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							Create some tests first to get stats and metrics from them.
						</Grid>
					)}
					{selectedModel.training_stats.examples.num_examples !== 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<h3
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span style={{ paddingRight: '6px' }}>
										Anomaly Detection Percentage
									</span>
									<CustomTooltip title="Percentage of training examples labeled as anomalies vs. normal.">
										<HelpIcon
											sx={{
												transform: 'scale(1) !important',
											}}
										/>
									</CustomTooltip>
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<StandardPieChart
									data={[
										{
											name: 'Without anomalies',
											value:
												selectedModel.training_stats.examples.num_examples -
												selectedModel.training_stats.examples.num_anomalous,
										},
										{
											name: 'With anomalies',
											value:
												selectedModel.training_stats.examples.num_anomalous,
										},
									]}
								/>
							</Grid>
						</Grid>
					)}
					{selectedModel.training_stats?.examples?.num_anomalies?.length >
						0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Anomalies by Type</span>
								<CustomTooltip title="Counts of each anomaly type in the training data, where each bar represents the count of a specific anomaly type.">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							<Grid
								container
								sx={{
									height: '200px',
								}}
							>
								<StandardBarChart
									data={anomalyTypesState.map((anomaly) => ({
										name: anomaly.name,
										value:
											selectedModel.training_stats.examples.num_anomalies.find(
												(anom) => anom.anomaly_type === anomaly.uuid
											)?.count || 0,
									}))}
									anomalyColor={anomalyTypesState.map(
										(anomaly) => anomaly.color
									)}
								/>
							</Grid>
						</Grid>
					)}
					{selectedModel.training_stats?.metrics?.acc_anomalies?.length > 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Performance table</span>
								<CustomTooltip title="Displays how often the AI release correctly identifies each anomaly type during training. It includes the number of examples for each type and the percentage of these that were accurately classified as true positives (correctly identified anomalies).">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							{contentTableTab === 'complete' && (
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell
													sx={{
														borderBottom: '1px solid gray',
													}}
												/>
												<TableCell
													sx={{
														border: '1px solid gray',
														fontWeight: 'bold',
														backgroundColor: '#e0e0e0',
													}}
													align="left"
												>
													Count
												</TableCell>
												<TableCell
													sx={{
														border: '1px solid gray',
														fontWeight: 'bold',
														backgroundColor: '#e0e0e0',
													}}
													align="left"
												>
													Accuracy (%)
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedModel.training_stats.metrics.acc_anomalies.map(
												(anomaly) => (
													<TableRow key={anomaly.anomaly_type}>
														<TableCell
															sx={{
																border: '1px solid gray',
																fontWeight: 'bold',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<div
																	style={{
																		width: '15px',
																		height: '15px',
																		marginRight: '10px',
																		backgroundColor: anomalyTypesState.find(
																			(anom) =>
																				anom.uuid === anomaly.anomaly_type
																		)?.color,
																	}}
																/>
																{
																	anomalyTypesState.find(
																		(anom) => anom.uuid === anomaly.anomaly_type
																	)?.name
																}
															</div>
														</TableCell>
														<TableCell
															sx={{ border: '1px solid gray' }}
															align="left"
														>
															{selectedModel.training_stats.examples.num_anomalies.find(
																(anom) =>
																	anom.anomaly_type === anomaly.anomaly_type
															)?.count || 'N/A'}
														</TableCell>
														<TableCell
															sx={{ border: '1px solid gray' }}
															align="left"
														>
															{`${(anomaly.accuracy * 100).toFixed(2)} %`}
														</TableCell>
													</TableRow>
												)
											)}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</Grid>
					)}
					{selectedModel.training_stats?.metrics?.cm_anomalies &&
						Object.keys(selectedModel.training_stats?.metrics?.cm_anomalies)
							.length > 0 && (
							<Grid
								container
								sx={{
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								{confusionMatrixTab === 'grouped' ? (
									<h3
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<span style={{ paddingRight: '6px' }}>
											Grouped Confusion Matrix
										</span>
										<CustomTooltip title="Compares AI release's training predictions to actual labels, with anomalies and normal examples. Columns show predictions, rows are true labels, highlighting accuracy in identifying anomalies vs. normal instances.">
											<HelpIcon
												sx={{
													transform: 'scale(1) !important',
												}}
											/>
										</CustomTooltip>
									</h3>
								) : (
									<h3
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<span style={{ paddingRight: '6px' }}>
											Complete Confusion Matrix
										</span>
										<CustomTooltip title="Shows AI release's predictions vs. actual labels during training. Columns indicate predictions, rows are true labels, with cell values counting correct and incorrect classifications.">
											<HelpIcon
												sx={{
													transform: 'scale(1) !important',
												}}
											/>
										</CustomTooltip>
									</h3>
								)}
								<Grid
									container
									sx={{
										width: { xs: '100%', md: '50%' },
										marginBottom: '12px',
										'& > *': {
											padding: '6px 12px',
										},
										justifyContent: 'center',
									}}
								>
									<Grid
										item
										sx={{
											fontWeight: 'bold',
											cursor: 'pointer',
											color:
												confusionMatrixTab === 'complete'
													? colors.buttonPrimaryColor
													: 'black',
											borderBottom:
												confusionMatrixTab === 'complete'
													? `5px solid ${colors.buttonPrimaryColor}`
													: 'none',
										}}
										onClick={() => handleChangeConfusionMatrixTab('complete')}
									>
										Complete
									</Grid>

									<Grid
										item
										sx={{
											fontWeight: 'bold',
											cursor: 'pointer',
											color:
												confusionMatrixTab === 'grouped'
													? colors.buttonPrimaryColor
													: 'black',
											borderBottom:
												confusionMatrixTab === 'grouped'
													? `5px solid ${colors.buttonPrimaryColor}`
													: 'none',
										}}
										onClick={() => handleChangeConfusionMatrixTab('grouped')}
									>
										Grouped
									</Grid>
								</Grid>
								<Grid item xs={12}>
									{confusionMatrixTab === 'complete' && (
										<ConfusionMatrix
											sideBar={false}
											data={Object.keys(
												selectedModel.training_stats.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														selectedModel.training_stats.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)
												.map((row) =>
													Object.keys(
														selectedModel.training_stats.metrics.cm_anomalies[
															row
														]
													)
														.filter((x) => x !== 'normal')
														.concat(
															Object.keys(
																selectedModel.training_stats.metrics
																	.cm_anomalies
															).filter((x) => x === 'normal')
														)
														.map(
															(col) =>
																selectedModel.training_stats.metrics
																	.cm_anomalies[row][col]
														)
												)}
											labels={Object.keys(
												selectedModel.training_stats.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														selectedModel.training_stats.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)}
											anomaliesColor={Object.keys(
												selectedModel.training_stats.metrics.cm_anomalies
											)
												.filter((x) => x !== 'normal')
												.concat(
													Object.keys(
														selectedModel.training_stats.metrics.cm_anomalies
													).filter((x) => x === 'normal')
												)
												.map(
													(anomaly) =>
														anomalyTypesState.find(
															(anom) =>
																anom.name?.toLowerCase() === anomaly ||
																anom.display_name?.toLowerCase() === anomaly
														)?.color
												)}
										/>
									)}
									{confusionMatrixTab === 'grouped' && (
										<ConfusionMatrix
											sideBar={false}
											data={[
												[
													selectedModel.training_stats.metrics.cm_anomalous
														.true_positives,
													selectedModel.training_stats.metrics.cm_anomalous
														.false_negatives,
												],
												[
													selectedModel.training_stats.metrics.cm_anomalous
														.false_positives,
													selectedModel.training_stats.metrics.cm_anomalous
														.true_negatives,
												],
											]}
											grouped
										/>
									)}
								</Grid>
							</Grid>
						)}
				</Grid>
			}
			actions={<StandardButton value="Close" handleClick={handleClose} close />}
		/>
	);
};

MetricsModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	selectedModel: PropTypes.object,
};

/* <Grid
item
xs={12}
sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}}
>
<h3>Metrics</h3>
</Grid>
<Grid
item
xs={6}
sx={{
  display: 'flex',
}}
>
<ConfusionMatrix
  data={confusionMatrixArrayTest}
  labels={confusionMatrixTestLabels}
/>
</Grid>
<Grid item xs={6}>
<TableContainer component={Paper}>
  <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell align="right">NBL</TableCell>
        <TableCell align="right">CC</TableCell>
        <TableCell align="right">MC</TableCell>
        <TableCell align="right">Accuracy (%)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {aireleasesTableRows.map((row) => (
        <TableRow
          key={row.name}
          sx={{
            '&:last-child td, &:last-child th': {
              backgroundColor: colors.gray,
            },
          }}
        >
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.nbl}</TableCell>
          <TableCell align="right">{row.cc}</TableCell>
          <TableCell align="right">{row.mc}</TableCell>
          <TableCell align="right">{row.accuracy}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
</Grid>
<Grid
item
xs={12}
sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '24px',
}}
>
<h3>Stats</h3>
</Grid>
<Grid item xs={6}>
</Grid>
<Grid item xs={6}>
<Box sx={{ height: '200px' }}>
  <StandardBarChart data={barChartDataTest} />
</Box>
</Grid> */
