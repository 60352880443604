import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Grid } from '@mui/material';
import AnomalyChip from '../../AnomalyChip';

// Styles
import { TableContainer, TableCell, TableLabels, Scale } from './styles';

export const ConfusionMatrix = (props) => {
	const { labels, data, sideBar, grouped = false, anomaliesColor } = props;

	const [maxValue, setMaxValue] = useState(0);
	const [minValue, setMinValue] = useState(0);
	const [rowMaxValue, setRowMaxValue] = useState([]);

	useEffect(() => {
		let max = 0;
		let min = 0;
		let maxRow = 0;
		const tmpMaxRow = [];

		if (data.length > 0 && data[0].length > 0) {
			data.forEach((row) => {
				maxRow = 0;
				row.forEach((col) => {
					if (col > max) max = col;
					if (col > maxRow) maxRow = col;
					if (col < min) min = col;
				});
				tmpMaxRow.push(maxRow);
			});
		}

		setRowMaxValue(tmpMaxRow);
		setMaxValue(max);
		setMinValue(min);
	}, [data]);

	return (
		<Grid
			container
			sx={{
				padding: '1rem',
			}}
		>
			<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
				{grouped ? (
					<TableContainer>
						<tbody>
							<tr>
								<td />
								<td />
								<td
									colSpan={data[0].length + 1}
									style={{
										border: '1px solid gray',
										textAlign: 'center',
									}}
								>
									Predicted
								</td>
							</tr>
							<tr>
								<td />
								<td />
								<TableLabels
									sx={{
										writingMode: 'vertical-lr',
										transform: ' rotate(180deg)',
									}}
								>
									Anomaly
								</TableLabels>
								<TableLabels
									sx={{
										writingMode: 'vertical-lr',
										transform: ' rotate(180deg)',
									}}
								>
									Normal
								</TableLabels>
							</tr>
							<tr>
								<td
									rowSpan={data[0].length + 1}
									style={{
										writingMode: 'vertical-lr',
										transform: ' rotate(180deg)',
										border: '1px solid gray',
										textAlign: 'center',
									}}
								>
									Labeled
								</td>
								<TableLabels>Anomaly</TableLabels>
								<TableCell
									backgroundColor={data[0][0] / rowMaxValue[0]}
									style={{ cursor: 'pointer' }}
									title="True positives"
								>
									{data[0][0]}
								</TableCell>
								<TableCell
									backgroundColor={data[0][1] / rowMaxValue[1]}
									style={{ cursor: 'pointer' }}
									title="False negatives"
								>
									{data[0][1]}
								</TableCell>
							</tr>
							<tr>
								<TableLabels>Normal</TableLabels>
								<TableCell
									backgroundColor={data[1][0] / rowMaxValue[0]}
									style={{ cursor: 'pointer' }}
									title="False positives"
								>
									{data[1][0]}
								</TableCell>
								<TableCell
									backgroundColor={data[1][1] / rowMaxValue[1]}
									style={{ cursor: 'pointer' }}
									title="True negatives"
								>
									{data[1][1]}
								</TableCell>
							</tr>
						</tbody>
					</TableContainer>
				) : (
					<>
						<TableContainer>
							<tbody>
								<tr>
									<td />
									<td />
									<td
										colSpan={data[0].length + 1}
										style={{
											border: '1px solid gray',
											textAlign: 'center',
										}}
									>
										Predicted
									</td>
								</tr>
								<tr>
									<td />
									<td />
									{labels.map(
										(col, colIndex) =>
											col !== 'normal' && (
												<TableLabels
													key={colIndex}
													sx={{
														writingMode: 'vertical-lr',
														transform: ' rotate(180deg)',
													}}
												>
													{console.log(col)}
													<AnomalyChip
														tooltip
														color={anomaliesColor[colIndex]}
														name={col}
													/>
												</TableLabels>
											)
									)}
									{labels.map(
										(col, colIndex) =>
											col === 'normal' && (
												<TableLabels
													key={colIndex}
													sx={{
														writingMode: 'vertical-lr',
														transform: ' rotate(180deg)',
													}}
												>
													{col.charAt(0).toUpperCase() + col.slice(1)}
												</TableLabels>
											)
									)}
								</tr>
								<tr>
									<td
										rowSpan={data[0].length + 1}
										style={{
											writingMode: 'vertical-lr',
											transform: ' rotate(180deg)',
											border: '1px solid gray',
											textAlign: 'center',
										}}
									>
										Labeled
									</td>
								</tr>
								{data.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{row !== 'normal' && (
											<TableLabels>
												{anomaliesColor[rowIndex] && (
													<AnomalyChip
														tooltip
														color={anomaliesColor[rowIndex]}
														name={labels[rowIndex]}
													/>
												)}
												{!anomaliesColor[rowIndex] && 'Normal'}
											</TableLabels>
										)}
										{row.map((col, colIndex) => (
											<TableCell
												key={colIndex}
												backgroundColor={col / rowMaxValue[rowIndex]}
											>
												{col}
											</TableCell>
										))}
									</tr>
								))}
							</tbody>
						</TableContainer>
						{sideBar && (
							<>
								<Scale />
								<Grid>
									<Grid
										item
										sx={{ display: 'flex', alignItems: 'start', height: '50%' }}
									>
										Max: {maxValue}
									</Grid>
									<Grid
										item
										sx={{ display: 'flex', alignItems: 'end', height: '50%' }}
									>
										Min: {minValue}
									</Grid>
								</Grid>
							</>
						)}
					</>
				)}
			</Grid>
		</Grid>
	);
};

ConfusionMatrix.propTypes = {
	labels: PropTypes.array,
	data: PropTypes.array,
	sideBar: PropTypes.any,
	grouped: PropTypes.bool,
	anomaliesColor: PropTypes.array,
};
