import { v4 as uuidv4 } from 'uuid';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRandomPredictions } from '../services/getRandomPredictions';
import requestFactory from '../services/request.factory';

const tmpShapes = [
	{
		created_at: '2023-11-29T09:56:37Z',
		created_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		element: 'Image',
		id: 'Yi1IU3FXUGE',
		modified_at: '2023-11-29T09:56:42Z',
		modified_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		path: null,
		pixels: null,
		polygon: [
			{
				x: 565,
				y: 370,
			},
			{
				x: 709,
				y: 370,
			},
			{
				x: 709,
				y: 513,
			},
			{
				x: 565,
				y: 513,
			},
		],
		uuid: '410f4027-4900-4f6c-99d8-57c7477039d9',
	},
	{
		created_at: '2023-11-29T10:04:24Z',
		created_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		element: 'Image',
		id: 'cS16aEFabkE',
		modified_at: '2023-11-29T10:04:28Z',
		modified_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		path: null,
		pixels: null,
		polygon: [
			{
				x: 81,
				y: 357,
			},
			{
				x: 225,
				y: 357,
			},
			{
				x: 225,
				y: 502,
			},
			{
				x: 81,
				y: 502,
			},
		],
		uuid: '6764fd57-d990-40e5-9600-0b1b59804fd9',
	},
	{
		created_at: '2023-11-29T10:04:30Z',
		created_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		element: 'image type',
		id: 'cS16aEFabkE',
		modified_at: '2023-11-29T10:04:41Z',
		modified_by: {
			id: 'Zi1kQ2pMeXI',
			type: 'user',
			uuid: '3e60c984-400a-4af2-9dd0-ba554bd2ab70',
		},
		path: null,
		pixels: null,
		polygon: [
			{
				x: 181,
				y: 423,
			},
			{
				x: 461,
				y: 423,
			},
			{
				x: 461,
				y: 656,
			},
			{
				x: 181,
				y: 656,
			},
		],
		uuid: '73296570-5c3a-4dba-ac61-361b4d712e08',
	},
];

// GET
export const GET_PREDICTIONS = createAsyncThunk(
	'user/GET_PREDICTIONS',
	async (props) => {
		console.log('user/GET_PREDICTIONS');
		const tmpProps = [];
		let isPage = false;
		let isPerPage = false;
		let isAIModel = false;
		const { dispatch, taskId, accessToken, schemaState } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				prop !== 'schemaState' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				if (prop === 'page') isPage = true;
				if (prop === 'per_page') isPerPage = true;
				if (prop === 'ai_model') isAIModel = true;
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';
		// Set the default options
		if (isPage)
			query += `page=${tmpProps.find((prop) => prop['page'])['page']}`;
		else query += 'page=1';
		if (isPerPage)
			query += `&per_page=${
				tmpProps.find((prop) => prop['per_page'])['per_page']
			}`;
		if (isAIModel)
			query += `&ai_model=${
				tmpProps.find((prop) => prop['ai_model'])['ai_model']
			}`;
		else query += '&per_page=25';
		query += '&total_count=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query' &&
				propValue
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/prediction-logs${query}&environment=production`,
			accessToken,
			dispatch,
		});

		if (res) return { res, schemaState };
	}
);

export const GET_PREDICTION_STATS = createAsyncThunk(
	'user/GET_PREDICTION_STATS',
	async (props) => {
		console.log('user/GET_PREDICTION_STATS');

		const tmpProps = [];
		const { dispatch, taskId, accessToken } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';

		// Set the default options
		query += '&environment=production&include_metrics=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query'
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/inference-stats${query}`,
			accessToken,
			dispatch,
		});

		return res;
	}
);

// POST

export const CREATE_PREDICTION = createAsyncThunk(
	'user/CREATE_PREDICTION',
	async ({ formatedPrediction }) => {
		console.log('user/CREATE_PREDICTION');

		// const res = await requestFactory({
		// 	type: 'GET',
		// 	url: `/tasks/${taskId}/models/production`,
		// 	accessToken,
		// 	dispatch,
		// });

		// return res;

		// formatedPredictions is an object or array of objects with the values prepared to be used in to the predictions table (for getColumns and getRows funcitons)
		return {
			prediction: formatedPrediction,
		};
	}
);

export const predictionsSlice = createSlice({
	name: 'predictions',
	initialState: {
		isLoading: false,
		currentPrediction: {},
		predictions: [],
		totalPredictions: 0,
		stats: null,
	},
	reducers: {
		SET_CURRENT_PREDICTION: (state, { payload }) => {
			console.log('SET_CURRENT_PREDICTION');
			state.currentPrediction =
				state.predictions.find((prediction) => prediction.id === payload) || {};
		},
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_PREDICTIONS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_PREDICTIONS.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			const tmp = payload.res.data;
			let newTmp = [];

			if (tmp && tmp.length > 0) {
				newTmp = tmp.map((data) => {
					if (data.shapes?.length > 0) {
						return {
							...data,
							shapes: data.shapes.map((shape) => ({
								...shape,
								uuid: uuidv4(),
							})),
						};
					}
					return data;
				});

				newTmp.map((data) => {
					if (data.shapes?.length > 0) {
						data.slices = data.shapes;
					}
					return data;
				});
			}

			// state.predictions = payload.res.data;
			state.predictions = newTmp;
			state.totalPredictions = payload.res.total_count;
			state.currentPrediction = {};
		});

		builder.addCase(GET_PREDICTIONS.rejected, (state) => {
			state.isLoading = true;
		});

		builder.addCase(GET_PREDICTION_STATS.pending, (state) => {
			state.isLoading = false;
		});
		builder.addCase(GET_PREDICTION_STATS.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.stats = payload;
		});
		builder.addCase(GET_PREDICTION_STATS.rejected, (state) => {
			state.isLoading = false;
		});

		// POST

		builder.addCase(CREATE_PREDICTION.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_PREDICTION.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			const randomPredictions = getRandomPredictions();

			state.predictions = [
				...state.predictions,
				{
					...payload.prediction,
					anomaly_types: { ...randomPredictions },
				},
			];
		});

		builder.addCase(CREATE_PREDICTION.rejected, (state) => {
			state.isLoading = true;
		});
	},
});

// Action creators are generated for each case reducer function
export const { SET_CURRENT_PREDICTION } = predictionsSlice.actions;

export default predictionsSlice.reducer;
