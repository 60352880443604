/* eslint-disable no-nested-ternary */
import { v4 as uuidv4 } from 'uuid';

// Components
import { Box, createTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CustomTooltip } from '../../../../Components/Shared/CustomTooltip';

// Services
import { elementTypeToIconMap } from '../../../../services/tasks';
import { createCell } from '../Examples/cellCreation.services';

// Styles
import { colors } from '../../../../consts/colors';
import { ADD_ALERT } from '../../../../redux/alerts.slice';
import requestFactory from '../../../../services/request.factory';
import AnomalyChip from '../../../../Components/Shared/AnomalyChip';

// Icons
import AIAnomaly from '../../../../assets/media/icons/ai_anomaly.png';
import Anomaly from '../../../../assets/media/icons/anomaly.png';

export const getCategoryValues = async ({
	id,
	nodeType,
	setIsLocalLoading = null,
	allColumns,
}) => {
	if (!allColumns.length) return;
	const categories = [];
	if (setIsLocalLoading !== null) setIsLocalLoading(true);
	const nodeTypeForRequest =
		nodeType === 'metadata' ? nodeType : `${nodeType}s`;
	const res = await requestFactory(
		'get',
		`/schema/${nodeTypeForRequest}/${id}/categories`
	);
	categories.push({ field: id, categories: res });
	if (setIsLocalLoading !== null) setIsLocalLoading(false);

	return {
		id,
		res,
		categories,
	};
};

const rowStatusToIcon = {
	unlabeled: () => '',
	pending_review: (
		<FontAwesomeIcon icon={faSpinner} style={{ color: colors.red }} />
	),
	labeled: <FontAwesomeIcon icon={faCheck} style={{ color: colors.green }} />,
	rejected: <FontAwesomeIcon icon={faXmark} style={{ color: colors.red }} />,
	null: () => '',
	undefined: () => '',
};

export const getColumns = ({
	setAllColumns,
	styles,
	schemaState,
	examplesState,
	categoriesState,
	currentTaskState,
	accessToken,
	setCurrentRowId,
	dispatch,
	imagesBufferState,
	documentsBufferState,
	documentSelectedNumPages,
	onDocumentLoadSuccess,
	from,
	setOpenMetricsModal,
	testsState,
}) => {
	const tmpCols = [];

	if (Object.keys(schemaState) && Object.keys(schemaState).length > 0) {
		// AI RELEASE
		tmpCols.push({
			fieldType: 'airelease',
			field: 'airelease',
			sortable: false,
			disableColumnMenu: true,
			resizable: true,
			width: 100,
			renderCell: (params) => {
				<div>{params.row.airelease}</div>;
			},
			renderHeader: () => (
				<div
					style={{
						display: 'flex',
						width: '100px',
						justifyContent: 'center',
					}}
				>
					AI Release
				</div>
			),
		});

		// PREDICTED ANOMALY

		schemaState.anomaly_types.forEach(() => {
			tmpCols.push({
				fieldType: 'predictedAnomalies',
				field: 'predictedAnomalies',
				sortable: false,
				disableColumnMenu: true,
				resizable: true,
				width: 125,
				renderCell: (params) => {
					if (params.row.predictedAnomalies !== undefined) {
						let sorted = [];

						const tmp = params.row.predictedAnomalies;

						if (tmp) {
							const sortable = [];

							tmp.forEach((anomaly) => {
								sortable.push([anomaly.element, anomaly.value]);
							});

							sorted = sortable.sort((a, b) => b[1] - a[1]).slice(0, 5);
						}
						return (
							sorted.length > 0 && (
								<CustomTooltip
									placement="left"
									title={
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{sorted.map((value) => (
												<div>
													{value[0]} - ({(value[1] * 100).toFixed(2)}%)
												</div>
											))}
										</div>
									}
								>
									<div
										style={{
											width: '100%',
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										{sorted.length === 1 && (
											<AnomalyChip
												color={
													schemaState?.anomaly_types?.find(
														(element) => element.name === sorted[0][0]
													)?.color || '#ff0000'
												}
												name={sorted[0][0]}
											/>
										)}
										{sorted.length > 1 &&
											sorted.map((element) => (
												<div
													style={{
														width: '15px',
														height: '15px',
														// borderRadius: '50%',
														backgroundColor: schemaState?.anomaly_types?.find(
															(el) =>
																el.name === element[0] ||
																el.display_name === element[0]
														)?.color,
														border: '1px solid gray',
														marginRight: '5px',
													}}
												/>
											))}
									</div>
								</CustomTooltip>
							)
						);
					}
				},
				renderHeader: () => (
					<div
						style={{
							display: 'flex',
							width: '125px',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<img
							src={AIAnomaly}
							style={{
								width: '30px',
								height: '30px',
							}}
							alt="AI Anomaly"
						/>
						AI anomalies
					</div>
				),
			});
		});

		// LABELED ANOMALY
		tmpCols.push({
			fieldType: 'anomalies',
			field: 'anomalies',
			sortable: false,
			disableColumnMenu: true,
			resizable: true,
			width: 125,
			renderCell: (params) => {
				if (params.row.labeledAnomalies !== undefined) {
					const sorted = [];

					const tmp = params.row.labeledAnomalies;

					if (tmp) {
						tmp.forEach((anomaly) => {
							if (anomaly.value === 1)
								sorted.push([anomaly.element, anomaly.value]);
						});
					}
					return (
						sorted.length > 0 && (
							<CustomTooltip
								placement="left"
								title={
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{sorted.map((value) => (
											<div>{value[0]}</div>
										))}
									</div>
								}
							>
								<div
									style={{
										width: '100%',
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{sorted.length === 1 && (
										<AnomalyChip
											color={
												schemaState?.anomaly_types?.find(
													(element) => element.name === sorted[0][0]
												)?.color
											}
											name={sorted[0][0]}
										/>
									)}
									{sorted.length > 1 &&
										sorted.map((element) => (
											<div
												style={{
													width: '15px',
													height: '15px',
													// borderRadius: '50%',
													backgroundColor: schemaState?.anomaly_types?.find(
														(el) =>
															el.name === element[0] ||
															el.display_name === element[0]
													)?.color,
													border: '1px solid gray',
													marginRight: '5px',
												}}
											/>
										))}
								</div>
							</CustomTooltip>
						)
					);
				}
			},
			renderHeader: () => (
				<div
					style={{
						display: 'flex',
						width: '125px',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<img
						src={Anomaly}
						style={{
							width: '30px',
							height: '30px',
						}}
						alt="Anomaly"
					/>
					Anomalies
				</div>
			),
		});

		// ITERATE EACH TEST
		Object.keys(schemaState).forEach((type) => {
			// ITERATE INPUTS
			if (
				type === 'inputs' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((input) => {
					if (input.type !== 'shape')
						tmpCols.push({
							id: input.id,
							uuid: input.uuid,
							fieldType: 'input',
							valueType: input.type,
							field: input.name,
							name: input.display_name || input.name,
							sortable: true,
							disableColumnMenu: true,
							resizable: true,
							width: 125,
							renderHeader: () => (
								<CustomTooltip title={input.display_name || input.name}>
									<Box
										sx={{
											display: 'flex !important',
											alignItems: 'center',
											minWidth: '80px',
											'&>*': {
												display: 'block !important',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											},
										}}
									>
										{input.type && elementTypeToIconMap[input.type]('input')}
										<div>{input.display_name || input.name}</div>
									</Box>
								</CustomTooltip>
							),
							renderCell: (params) => {
								if (params.row[input.id] !== undefined) {
									return (
										<div
											style={{
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{createCell[input.type]({
												styles,
												cellValue:
													params.row[input.id] || params.row[input.element],
												cellName: input.name,
												imagesBufferState,
												documentsBufferState,
												documentSelectedNumPages,
												onDocumentLoadSuccess,
												categoriesState,
												params,
											})}
										</div>
									);
								}
							},
							valueGetter: (params) => {
								if (examplesState && examplesState.length > 0)
									return params.row[input.id];
							},
						});
				});
			}

			// // ITERATE OUTPUTS
			// if (
			// 	type === 'outputs' &&
			// 	schemaState[type] &&
			// 	schemaState[type].length > 0
			// ) {
			// 	schemaState[type].forEach((output) => {
			// 		if (output.type !== 'shape')
			// 			tmpCols.push({
			// 				id: output.id,
			// 				fieldType: 'output',
			// 				valueType: output.type,
			// 				field: output.name,
			// 				name: output.display_name || output.name,
			// 				sortable: true,
			// 				disableColumnMenu: true,
			// 				renderCell: (params) => {
			// 					if (params.row[output.id] !== undefined) {
			// 						return (
			// 							<div
			// 								style={{
			// 									width: '100%',
			// 									height: '100%',
			// 									display: 'flex',
			// 									alignItems: 'center',
			// 									justifyContent: 'center',
			// 								}}
			// 							>
			// 								{createCell[output.type]({
			// 									classes,
			// 									cellValue:
			// 										params.row[output.id] || params.row[output.element],
			// 									cellName: output.name,
			// 									imagesBufferState,
			// 									documentsBufferState,
			// 									documentSelectedNumPages,
			// 									onDocumentLoadSuccess,
			// 								})}
			// 							</div>
			// 						);
			// 					}
			// 				},
			// 				renderHeader: () => (
			// 					<CustomTooltip title={output.display_name || output.name}>
			// 						<Box
			// 							className={classes.headerCell}
			// 							sx={{ textOverflow: 'ellipsis' }}
			// 						>
			// 							{output.type && elementTypeToIconMap[output.type]('output')}
			// 							{output.display_name || output.name}
			// 						</Box>
			// 					</CustomTooltip>
			// 				),
			// 				valueGetter: (params) => {
			// 					if (examplesState && examplesState.length > 0)
			// 						return params.row[output.id];
			// 				},
			// 			});
			// 	});
			// }

			// ITERATE METADATA
			if (
				type === 'metadata' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((meta) => {
					if (meta.type !== 'shape')
						tmpCols.push({
							id: meta.id,
							uuid: meta.uuid,
							fieldType: 'metadata',
							valueType: meta.type,
							field: meta.name,
							name: meta.display_name || meta.name,
							sortable: true,
							disableColumnMenu: true,
							resizable: true,
							width: 125,
							renderCell: (params) =>
								createCell[meta.type]({
									styles,
									cellValue: params.row[meta.id],
									cellName: meta.name,
									imagesBufferState,
									documentsBufferState,
									onDocumentLoadSuccess,
									categoriesState,
									params,
								}),
							renderHeader: () => (
								<CustomTooltip title={meta.display_name || meta.name}>
									<Box
										sx={{
											display: 'flex !important',
											alignItems: 'center',
											minWidth: '80px',
											'&>*': {
												display: 'block !important',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											},
										}}
									>
										{meta.type && elementTypeToIconMap[meta.type]('metadata')}
										<div>{meta.display_name || meta.name}</div>
									</Box>
								</CustomTooltip>
							),
							valueGetter: (params) => {
								if (examplesState && examplesState.length > 0)
									return params.row[meta.id];
							},
						});
				});
			}
		});
	}

	setAllColumns(tmpCols);
};

export const getRows = ({
	setRows,
	schemaState,
	testsState,
	AIReleasesState,
}) => {
	const tmpRows = [];
	let tmpRowData = {};

	if (
		testsState &&
		testsState.length > 0 &&
		Object.keys(schemaState) &&
		Object.keys(schemaState).length > 0
	) {
		testsState.forEach((test) => {
			if (test) {
				tmpRowData = {
					...tmpRowData,
					id: test.element || test.id,
					uuid: test.uuid,
					airelease:
						(AIReleasesState.find((release) => release.id === test.ai_model) &&
							AIReleasesState.find((release) => release.id === test.ai_model)
								.version) ||
						'',
				};

				// INPUTS
				if (test && test.inputs && test.inputs.length > 0) {
					test.inputs.forEach((input) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.inputs &&
							schemaState.inputs.length > 0
						)
							tmp = schemaState.inputs.find(
								(element) => element.name === input.element
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpRowData = {
								...tmpRowData,
								[tmp.id]:
									typeof input.value === 'boolean'
										? `${input.value}`
										: input.value || '',
							};
					});
				}

				// METADATA
				if (test && test.metadata && test.metadata.length > 0) {
					test.metadata.forEach((meta) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.metadata &&
							schemaState.metadata.length > 0
						)
							tmp = schemaState.metadata.find(
								(element) => element.name === meta.element
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpRowData = { ...tmpRowData, [tmp.id]: meta.value || '' };
					});
				}

				// LABELED ANOMALIES
				if (test?.targets?.length > 0) {
					tmpRowData = {
						...tmpRowData,
						labeledAnomalies: test.targets,
					};
				}

				// PREDICTED ANOMALIES
				if (test?.outputs?.length > 0) {
					const tmpPredictedAnomalies = [];
					test.outputs.forEach((output) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.anomaly_types &&
							schemaState.anomaly_types.length > 0
						)
							tmp = schemaState.anomaly_types.find(
								(element) =>
									element.name === output.element &&
									element.threshold < output.value
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpPredictedAnomalies.push(output);
					});

					if (tmpPredictedAnomalies && tmpPredictedAnomalies.length > 0)
						tmpRowData = {
							...tmpRowData,
							predictedAnomalies: tmpPredictedAnomalies,
						};
				}

				if (Object.keys(tmpRowData) && Object.keys(tmpRowData).length > 0)
					tmpRows.push(tmpRowData);

				tmpRowData = {};
			}
		});
	}

	setRows(tmpRows);
};

export const createValidatedTestingObjects = (
	parsedData,
	schemaState,
	dispatch
) => {
	let tmp = {};
	let result = [];

	const createData = (element) => {
		tmp = {
			values: Object.keys(element)
				.filter((el) => el !== 'status')
				.map((el) => ({
					element: el,
					value: element[el],
				})),
		};

		// tmp = {
		// 	status: element.status,
		// 	inputs: [],
		// 	outputs: [],
		// 	metadata: [],
		// };

		const tmpValidationErrors = [];
		// todo: add element validation

		// Object.keys(element).forEach((name) => {
		// 	// INPUTS
		// 	if (schemaState.inputs && schemaState.inputs.length > 0) {
		// 		if (schemaState.inputs.some((input) => input.name === name)) {
		// 			const input = schemaState.inputs.find((input) => input.name === name);

		// 			const validated = validateElement[input.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${input.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.inputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// OUTPUTS
		// 	if (schemaState.outputs && schemaState.outputs.length > 0) {
		// 		if (schemaState.outputs.some((output) => output.name === name)) {
		// 			const output = schemaState.outputs.find(
		// 				(output) => output.name === name
		// 			);

		// 			const validated = validateElement[output.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${output.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.outputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// METADATA
		// 	if (schemaState.metadata && schemaState.metadata.length > 0) {
		// 		if (schemaState.metadata.some((meta) => meta.name === name)) {
		// 			const meta = schemaState.metadata.find((meta) => meta.name === name);

		// 			const validated = validateElement[meta.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${meta.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.metadata.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}
		// });

		if (Object.keys(tmpValidationErrors).length > 0) {
			dispatch(ADD_ALERT({ type: 'error', message: tmpValidationErrors }));
			return null;
		}

		return tmp;
	};

	if (parsedData && parsedData.length > 0) {
		parsedData.forEach((element) => {
			const tmpData = createData(element);
			result.push(tmpData);
		});
	} else {
		result = createData(parsedData);
	}

	return result;
};

// todo: check if neccesary or need to be updated
export const parseValue = (output, element) => {
	const result = output.value;

	if (output.type === 'number') {
		return parseInt(output.value);
	}

	if (element.type === 'Boolean') {
		if (output.checked) return true;
		return false;
	}

	return result;
};
