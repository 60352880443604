/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import {
	Box,
	Grid,
	Typography,
	Skeleton,
	/* Toolbar  Link */
} from '@mui/material';
import { PieChart } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import StorageIcon from '@mui/icons-material/Storage';
import CardItem from '../../../../Components/Shared/CardItem';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import StandardModal from '../../../../Components/Shared/StandardModal';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import StatusBar from '../../../../Components/Shared/StatusBar';

// Styles
import { styles } from './styles';
import { measures } from '../../../../consts/sizes';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { getLocalDateTime } from '../../../../services/date';

import requestFactory from '../../../../services/request.factory';
import StandardPieChart from '../../../../Components/Shared/Charts/StandardPieChart';
import StandardBarChart from '../../../../Components/Shared/Charts/StandardBarChart';
import { useWindowSize } from '../../../../services/hooks/useWindowResize';
import { CustomTooltip } from '../../../../Components/Shared/CustomTooltip';
import { MetricsModal } from '../Models/components/MetricsModal';

export function TaskStatus() {
	const dispatch = useDispatch();

	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const {
		currentTask: currentTaskState,
		currentTaskSettings: currentTaskSettingsState,
		stats: taskStats,
	} = useSelector((state) => state.tasks);
	const { totalExamples: totalExamplesState } = useSelector(
		(state) => state.examples
	);
	const {
		productionAIRelease: productionAIReleaseState,
		AIReleases: AIReleasesState,
	} = useSelector((state) => state.aireleases);
	const {
		info: taskInfoLoaderState,
		services: taskServiceLoaderState,
		productionRelease: taskProductionReleaseLoaderState,
	} = useSelector((state) => state.loaders.task.status);
	const { accessToken, location: userLocationState } = useSelector(
		(state) => state.user
	);

	const [servicesStatus, setServicesStatus] = useState({
		active_learning: 0,
		continual_learning: 0,
		monitoring: 0,
		inference: 0,
	});
	const [selectedModel, setSelectedModel] = useState();

	// Modals
	const [openShowProductionReleaseStats, setOpenShowProductionReleaseStats] =
		useState(false);
	const [openStatsAndMetricsModal, setOpenStatsAndMetricsModal] =
		useState(false);

	const handleCloseShowProductionReleaseStatsModal = () => {
		setOpenShowProductionReleaseStats(false);
	};

	const handleOpenStatsAndMetricsModal = (model) => {
		setOpenStatsAndMetricsModal(!openStatsAndMetricsModal);
		setSelectedModel(
			AIReleasesState.find((release) => release.uuid === model.uuid)
		);
	};

	const getServicesStatus = async () => {
		let tmpServices = servicesStatus;
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/inference`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				inference: res.status?.status_code || '',
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/monitoring`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				monitoring: res.status.status_code || '',
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/active-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				active_learning: res.status.status_code || '',
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/continual-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				continual_learning: res.status.status_code || '',
			};
		});

		setServicesStatus(tmpServices);
	};

	useEffect(() => {
		if (currentTaskState.uuid) getServicesStatus();
	}, [currentTaskSettingsState]);

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid container spacing={3}>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							// marginBottom: 3,
						}}
						type="noIcon"
						title="Status"
					>
						{!taskInfoLoaderState ? (
							<Box sx={styles.descriptionContent}>
								{currentTaskState.status && (
									<>
										{Object.keys(currentTaskState)?.length > 0 &&
											currentTaskState.status &&
											currentTaskState.status.status_code && (
												<StatusBar
													type="task"
													code={currentTaskState.status.status_code}
													name={currentTaskState.status.display_name}
													description={currentTaskState.status.description}
												/>
											)}
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<h3>Name:</h3> {`  ${currentTaskState.name}` || ''}
										</div>
										<h3>Description:</h3>
										<div style={{ display: 'flex', textAlign: 'justify' }}>
											{currentTaskState.description || ''}
										</div>
										<h3>Modified at:</h3>
										{getLocalDateTime(
											currentTaskState.modified_at,
											userLocationState
										)}
									</>
								)}
							</Box>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Name:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '50%',
										}}
									/>
								</div>
								<h3>Description:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '20%',
									}}
								/>
								<h3>Modified at:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Services status"
					>
						{!taskServiceLoaderState &&
						currentTaskSettingsState.active_learning &&
						currentTaskSettingsState.continual_learning &&
						currentTaskSettingsState.inference &&
						currentTaskSettingsState.monitoring ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<h3>Active learning:</h3>
								{servicesStatus.active_learning !== 0 ? (
									currentTaskSettingsState.active_learning.enabled ? (
										// <StatusBar status={servicesStatus.active_learning} />
										<StatusBar status={servicesStatus.active_learning} />
									) : (
										<StatusBar status="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Coninual learning:</h3>
								{servicesStatus.continual_learning !== 0 ? (
									currentTaskSettingsState.continual_learning.enabled ? (
										<StatusBar status={servicesStatus.continual_learning} />
									) : (
										<StatusBar status="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Inference:</h3>
								{servicesStatus.inference !== 0 ? (
									currentTaskSettingsState.inference.enabled ? (
										<StatusBar status={servicesStatus.inference} />
									) : (
										<StatusBar status="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Monitoring:</h3>
								{servicesStatus.monitoring !== 0 ? (
									currentTaskSettingsState.monitoring.enabled ? (
										<StatusBar status={servicesStatus.monitoring} />
									) : (
										<StatusBar status="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<h3>Active learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Coninual learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Inference:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Monitoring:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>

					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Total examples"
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: 12,
							}}
						>
							<StorageIcon sx={{ color: '#666666' }} />
							<Typography
								variant="span"
								sx={{
									fontSize: 22,
									color: '#666666',
								}}
							>
								{typeof totalExamplesState === 'number' ? (
									totalExamplesState
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '150px',
										}}
									/>
								)}
							</Typography>
						</div>
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Models */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Production AI release"
					>
						{!taskProductionReleaseLoaderState ? (
							productionAIReleaseState &&
							Object.keys(productionAIReleaseState).length > 0 ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
										}}
									>
										<h3>Version:</h3>
										{productionAIReleaseState.docker_image.tag}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
										}}
									>
										<h3>ID:</h3>
										{productionAIReleaseState.id}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<h3>Creation date:</h3>
										{getLocalDateTime(
											productionAIReleaseState.created_at,
											userLocationState
										)}
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<StandardButton
											value="Show stats and metrics"
											handleClick={() =>
												handleOpenStatsAndMetricsModal(productionAIReleaseState)
											}
											icon={<PieChart />}
										/>
									</div>
								</div>
							) : (
								<div>No production releases found</div>
							)
						) : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Version:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>ID:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Creation date:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>

					{/* Stats and metrics */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							// marginBottom: 3,
						}}
						type="noIcon"
					>
						{taskStats &&
						Object.keys(taskStats)?.length > 0 &&
						taskStats.num_predictions > 0 &&
						taskStats.num_anomalous > 0 ? (
							<>
								<Grid
									item
									xs={12}
									sx={{
										paddingBottom: '12px',
									}}
								>
									<Grid
										container
										sx={{
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<h3
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<span style={{ paddingRight: '6px' }}>
												Anomaly Detection Percentage
											</span>
											<CustomTooltip title="Percentage of training examples labeled as anomalies vs. normal.">
												<HelpIcon
													sx={{
														transform: 'scale(1) !important',
													}}
												/>
											</CustomTooltip>
										</h3>
									</Grid>
									<StandardPieChart
										data={[
											{
												name: 'Without anomalies',
												value:
													taskStats.num_predictions - taskStats.num_anomalous,
											},
											{
												name: 'With anomalies',
												value: taskStats.num_anomalous,
											},
										]}
									/>
								</Grid>
								<Grid
									container
									sx={{ display: 'flex', justifyContent: 'center' }}
								>
									<StandardButton
										value="Show more stats"
										handleClick={() => setOpenShowProductionReleaseStats(true)}
									/>
								</Grid>
							</>
						) : (
							'Not enought predictions to show stats.'
						)}
						{/* <Box className={classes.descriptionContent}>
						</Box> */}
					</CardItem>
					{/* {
						// Training
					}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							marginBottom: 3,
						}}
						title="Training"
						titleicon={<ChangeHistoryIcon />}
					>
						<Training />
					</CardItem> */}
				</Grid>

				{/* <Grid container spacing={3} sx={{ paddingLeft: 3, marginTop: 3 }}>
					<Grid item xs={12} md={4}>
					</Grid>

					<Grid item xs={6}>
						{
              // Documentation
            }
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								marginBottom: 3,
							}}
							title="Documentation"
							titleicon={<MenuBookIcon />}
						>
							<Documentation />
						</CardItem>
					</Grid>
				</Grid> */}
			</Grid>
			{/* {openShowSchema && (
				<StandardModal
					open={openShowSchema}
					setOpen={handleCloseShowSchemaModal}
					title="Production AI Release schema"
					content={
						<Box sx={{ height: '584px' }}>
							<FixedFlow tmpSchema={productionAIReleaseState.task_schema} />
						</Box>
					}
					actions={
						<StandardButton
							close
							value="Close"
							handleClick={handleCloseShowSchemaModal}
						/>
					}
				/>
			)} */}
			{openShowProductionReleaseStats && Object.keys(taskStats).length > 0 && (
				<StandardModal
					open={openShowProductionReleaseStats}
					setOpen={handleCloseShowProductionReleaseStatsModal}
					title="More stats"
					content={
						<Box
							sx={{
								height: '300px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Anomalies by Type</span>
								<CustomTooltip title="Counts of each anomaly type in the training data, where each bar represents the count of a specific anomaly type.">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							<Grid
								container
								sx={{
									height: '200px',
								}}
							>
								<StandardBarChart
									// data={anomalyTypesState.map((anomaly) => ({
									//   name: anomaly.name,
									//   value:
									//     statsAndMetricsState.num_anomalies.find(
									//       (anom) => anom.anomaly_type === anomaly.uuid
									//     )?.count || 0,
									// }))}
									data={anomalyTypesState.map((anomaly) => ({
										name: anomaly.name,
										value:
											taskStats.num_anomalies.find(
												(anom) => anom.anomaly_type === anomaly.uuid
											)?.count || 0,
									}))}
									anomalyColor={anomalyTypesState.map(
										(anomaly) => anomaly.color
									)}
								/>
							</Grid>
						</Box>
					}
					actions={
						<StandardButton
							close
							value="Close"
							handleClick={handleCloseShowProductionReleaseStatsModal}
						/>
					}
				/>
			)}
			{openStatsAndMetricsModal && selectedModel && (
				<MetricsModal
					open={openStatsAndMetricsModal}
					setOpen={setOpenStatsAndMetricsModal}
					selectedModel={selectedModel}
				/>
			)}
		</HasAccess>
	);
}
