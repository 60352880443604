import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Box } from '@mui/system';
import {
	Collapse,
	IconButton,
	List,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// Styles
import { styles } from './styles';

// Redux
import {
	DELETE_SLICE,
	GET_EXAMPLES,
} from '../../../../../../redux/examples.slice';
import { colors } from '../../../../../../consts/colors';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';

const ChipSlicesList = ({
	currentCellId,
	selectedAnomaly,
	setSelectedAnomaly,
	slicesState,
}) => {
	const dispatch = useDispatch();

	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const { accessToken } = useSelector((state) => state.user);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { uuid: currentExampleUUID } = useSelector(
		(state) => state.examples.currentExample
	);

	const [showAnomalyList, setShowAnomalyList] = useState(true);

	const handleSelectAnomaly = (anomalyUUID) => {
		setSelectedAnomaly(anomalyUUID);
	};

	const handleDeleteAnomaly = async (anomalyUUID) => {
		await dispatch(
			DELETE_SLICE({
				taskId: currentTaskState.uuid,
				exampleId: currentExampleUUID,
				accessToken,
				dispatch,
				sliceUUID: anomalyUUID,
			})
		);

		await dispatch(
			GET_EXAMPLES({ taskId: currentTaskState.uuid, accessToken, dispatch })
		);

		setSelectedAnomaly(null);
	};

	return (
		<List
			component="nav"
			subheader={
				<Box
					onClick={() => setShowAnomalyList(!showAnomalyList)}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					{showAnomalyList ? <ExpandLess /> : <ExpandMore />}
					<Typography
						variant="body1"
						component="div"
						sx={{
							width: '100%',
							fontWeight: 'bold',
							color: 'text.primary',
						}}
					>
						Slices list:
					</Typography>
				</Box>
			}
			sx={{
				mt: 2,
			}}
		>
			<Collapse in={showAnomalyList} timeout="auto" unmountOnExit>
				{slicesState && slicesState.length > 0 && (
					<Box sx={styles.slicesChipsContainer}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow
										sx={{
											borderBottom: `2px solid ${colors.lightBorderColor}`,
										}}
									>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Start index
										</TableCell>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											End index
										</TableCell>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Anomalies
										</TableCell>
										<TableCell
											sx={styles.tableTitle}
											align="center"
											width={100}
										>
											Delete
										</TableCell>
									</TableRow>
								</TableHead>
								{slicesState.map(
									(slice) =>
										slice.element === currentCellId && (
											<TableBody>
												<TableRow
													sx={{
														cursor: 'pointer',
														backgroundColor:
															selectedAnomaly === slice.uuid
																? colors.ligthBlue
																: 'none',
														'&:hover': {
															backgroundColor: colors.ligthBlue,
														},
													}}
													key={slice.uuid}
													onClick={() => handleSelectAnomaly(slice.uuid)}
												>
													<TableCell sx={styles.tableTitle} align="center">
														<div style={{ padding: '0px 12px' }}>
															{slice.start_index}
														</div>
													</TableCell>
													<TableCell sx={styles.tableTitle} align="center">
														<div style={{ padding: '0px 12px' }}>
															{slice.end_index}
														</div>
													</TableCell>
													<TableCell
														sx={{
															...styles.tableTitle,
															...styles.tableAnomalyType,
														}}
														align="center"
													>
														{slice.outputs &&
															slice.outputs.length > 0 &&
															slice.outputs.map(
																(output) =>
																	anomalyTypesState?.find(
																		(anomaly) => anomaly.name === output.element
																	)?.threshold <= output.value && (
																		<AnomalyChip
																			name={output.element}
																			color={
																				anomalyTypesState?.find(
																					(anomaly) =>
																						anomaly.name === output.element
																				)?.color
																			}
																			tooltip
																		/>
																	)
															)}
													</TableCell>
													<TableCell sx={styles.tableTitle} align="center">
														<IconButton
															style={{ margin: 0, padding: 0 }}
															onClick={() => handleDeleteAnomaly(slice.uuid)}
														>
															<DeleteForeverOutlinedIcon
																sx={{ height: '20px' }}
															/>
														</IconButton>
													</TableCell>
												</TableRow>
											</TableBody>
										)
								)}
							</Table>
						</TableContainer>
					</Box>
				)}
			</Collapse>
		</List>
	);

	// <Collapse in={showAnomalyList} timeout="auto" unmountOnExit>
	// 			{slicesState && slicesState.length > 0 && (
	// 				<Box className={classes.slicesChipsContainer}>
	// 					{slicesState.map((slice) => {
	// 						if (slice.element === currentCellId)
	// 							return (
	// 								<button
	// 									key={uuidv4()}
	// 									type="button"
	// 									id={slice.uuid}
	// 									className={`${classes.sliceChip} ${
	// 										slice.uuid === selectedAnomaly && classes.selectedSlice
	// 									}`}
	// 									style={{
	// 										backgroundColor: anomalyTypesState.find(
	// 											(anomaly) => anomaly.uuid === slice.outputs[0]?.uuid
	// 										)?.color,
	// 									}}
	// 									onClick={() => handleSelectAnomaly(slice.uuid)}
	// 								>
	// 									<div
	// 										style={{
	// 											display: 'flex',
	// 											alignItems: 'center',
	// 											justifyContent: 'center',
	// 											height: '30px',
	// 										}}
	// 									>
	// 										<div
	// 											style={{ padding: '0px 12px' }}
	// 										>{`[${slice.start_index}-${slice.end_index}]`}</div>
	// 										<div className={classes.anomalyUUID}>
	// 											{slice.outputs[0]?.element}
	// 										</div>
	// 										{/* {slice.uuid === selectedAnomaly &&
	// 										!testView &&
	// 										!predictionsView && ( */}
	// 										<div className={classes.anomalyTrashIcon}>
	// 											<IconButton
	// 												style={{ margin: 0, padding: 0 }}
	// 												onClick={() => handleDeleteAnomaly(slice.uuid)}
	// 											>
	// 												<DeleteForeverOutlinedIcon sx={{ height: '20px' }} />
	// 											</IconButton>
	// 										</div>
	// 										{/* )} */}
	// 									</div>
	// 								</button>
	// 							);
	// 						return false;
	// 					})}
	// 				</Box>
	// 			)}
	// 		</Collapse>

	// return (
	// 	<>
	// 		<h3 style={{ marginTop: 18, marginBottom: -6 }}>Slices list:</h3>
	// 		{slicesState && slicesState.length > 0 && (
	// 			<Box className={classes.slicesChipsContainer}>
	// 				{slicesState.map((slice) => {
	// 					if (slice.element === currentCellId)
	// 						return (
	// 							<button
	// 								key={uuidv4()}
	// 								type="button"
	// 								id={slice.uuid}
	// 								className={`${classes.sliceChip} ${
	// 									slice.uuid === selectedAnomaly && classes.selectedSlice
	// 								}`}
	// 								style={{
	// 									backgroundColor: anomalyTypesState.find(
	// 										(anomaly) => anomaly.uuid === slice.outputs[0]?.uuid
	// 									)?.color,
	// 								}}
	// 								onClick={() => handleSelectAnomaly(slice.uuid)}
	// 							>
	// 								<div
	// 									style={{
	// 										display: 'flex',
	// 										alignItems: 'center',
	// 										justifyContent: 'center',
	// 										height: '30px',
	// 									}}
	// 								>
	// 									<div
	// 										style={{ padding: '0px 12px' }}
	// 									>{`[${slice.start_index}-${slice.end_index}]`}</div>
	// 									<div className={classes.anomalyUUID}>
	// 										{slice.outputs[0]?.element}
	// 									</div>
	// 									{/* {slice.uuid === selectedAnomaly &&
	// 										!testView &&
	// 										!predictionsView && ( */}
	// 									<div className={classes.anomalyTrashIcon}>
	// 										<IconButton
	// 											style={{ margin: 0, padding: 0 }}
	// 											onClick={() => handleDeleteAnomaly(slice.uuid)}
	// 										>
	// 											<DeleteForeverOutlinedIcon sx={{ height: '20px' }} />
	// 										</IconButton>
	// 									</div>
	// 									{/* )} */}
	// 								</div>
	// 							</button>
	// 						);
	// 					return false;
	// 				})}
	// 			</Box>
	// 		)}
	// 	</>
	// );
};

export default ChipSlicesList;

ChipSlicesList.propTypes = {
	currentCellId: PropTypes.string,
	selectedAnomaly: PropTypes.string,
	setSelectedAnomaly: PropTypes.func,
	slicesState: PropTypes.array,
};
