import { ADD_ALERT } from '../redux/alerts.slice';

// REQUEST FACTORY FUNCTION
const requestFactory = async ({ type, url, accessToken, data, dispatch }) => {
	let res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
		method: type,
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'content-type': 'application/json',
			cors: 'no-cors',
		},
		body: data && JSON.stringify(data),
	});

	let json = null;

	if (res && res.status !== 204) {
		json = await res.json();
	}

	if (Math.floor(res.status / 5 / 100) === 1 && type === 'GET') {
		res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
				cors: 'no-cors',
			},
			body: data && JSON.stringify(data),
		});
	}

	if (res && json) {
		if (
			Math.floor(res.status / 4 / 100) === 1 ||
			Math.floor(res.status / 5 / 100) === 1
		) {
			if (json.error) {
				dispatch(ADD_ALERT({ type: 'error', message: json.error.message }));
			} else if (json.errors) {
				Object.keys(json.errors.json).forEach((error) => {
					dispatch(
						ADD_ALERT({ type: 'error', message: json.errors.json[error][0] })
					);
				});
			} else
				dispatch(
					ADD_ALERT({
						type: 'error',
						message:
							'An unexpected error has occurred. Contact contact@neuraptic.ai for support.',
					})
				);
			return;
		}

		if (res) {
			return json;
		}
	}

	return undefined;
};

export default requestFactory;
