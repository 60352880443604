const predictionsList = [
	{
		anomalies: ['setosa'],
		scores: {
			'no-anomaly': 0.25,
			setosa: 0.7381,
			virginica: 0.124,
			versicolor: 0.124,
		},
	},
	{
		anomalies: ['virginica'],
		scores: {
			'no-anomaly': 0.15,
			setosa: 0.124,
			virginica: 0.7381,
			versicolor: 0.124,
		},
	},
	{
		anomalies: ['setosa', 'versicolor'],
		scores: {
			'no-anomaly': 0.2,
			setosa: 0.7381,
			virginica: 0.124,
			versicolor: 0.863,
		},
	},
	{
		anomalies: ['setosa', 'versicolor', 'virginica'],
		scores: {
			'no-anomaly': 0.2,
			setosa: 0.7381,
			virginica: 0.634,
			versicolor: 0.863,
		},
	},
];

export function getRandomPredictions() {
	return predictionsList[Math.floor(Math.random() * predictionsList.length)];
}
