import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestFactory from '../services/request.factory';

// GET
export const GET_TESTS = createAsyncThunk('user/GET_TESTS', async (props) => {
	console.log('user/GET_TESTS');
	const tmpProps = [];
	let isPage = false;
	let isPerPage = false;
	let isAIModel = false;
	const { dispatch, taskId, accessToken, schemaState } = props;

	Object.keys(props).forEach((prop) => {
		if (
			(prop !== '' || prop !== undefined || prop !== null) &&
			prop !== 'taskId' &&
			prop !== 'accessToken' &&
			prop !== 'dispatch' &&
			prop !== 'schemaState' &&
			props[prop] !== '' &&
			props[prop] !== false
		) {
			if (prop === 'page') isPage = true;
			if (prop === 'per_page') isPerPage = true;
			if (prop === 'ai_model') isAIModel = true;
			tmpProps.push({ [prop]: props[prop] });
		}
	});

	// Create the query based on the array and set the default options
	let query = '?';
	// Set the default options
	if (isPage) query += `page=${tmpProps.find((prop) => prop['page'])['page']}`;
	else query += 'page=1';
	if (isPerPage)
		query += `&per_page=${
			tmpProps.find((prop) => prop['per_page'])['per_page']
		}`;
	if (isAIModel)
		query += `&ai_model=${
			tmpProps.find((prop) => prop['ai_model'])['ai_model']
		}`;
	else query += '&per_page=25';
	query += '&total_count=true';

	// Create the query
	tmpProps.forEach((prop) => {
		const propName = Object.keys(prop)[0];
		const propValue = prop[propName];
		if (
			propName !== 'page' &&
			propName !== 'per_page' &&
			propName !== 'total_count' &&
			propName !== 'query' &&
			propValue
		)
			query += `&${propName}=${propValue}`;
		if (propName === 'query') query += `&${propValue}`;
	});

	const res = await requestFactory({
		type: 'GET',
		url: `/tasks/${taskId}/prediction-logs${query}&environment=testing`,
		accessToken,
		dispatch,
	});

	if (res) return { res, schemaState };
});

export const GET_TESTING_STATS_AND_METRICS = createAsyncThunk(
	'user/GET_TESTING_STATS_AND_METRICS',
	async (props) => {
		console.log('user/GET_TESTING_STATS_AND_METRICS');

		const tmpProps = [];
		const { dispatch, taskId, accessToken } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';

		// Set the default options
		query += '&environment=testing&include_metrics=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query'
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/inference-stats${query}`,
			accessToken,
			dispatch,
		});

		return res;
	}
);

// POST

export const CREATE_TEST = createAsyncThunk(
	'user/CREATE_TEST',
	async ({ taskId, accessToken, dispatch, newTests }) => {
		console.log('user/CREATE_TEST');

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/test`,
			accessToken,
			dispatch,
			data: newTests,
		});

		return res;
	}
);

export const testingSlice = createSlice({
	name: 'testing',
	initialState: {
		isLoading: false,
		currentTest: {},
		tests: [],
		totalTests: 0,
		statsAndMetrics: null,
	},
	reducers: {
		SET_CURRENT_TEST: (state, { payload }) => {
			console.log('SET_CURRENT_TEST');
			state.currentTest = payload;
		},
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_TESTS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_TESTS.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			const tmp = payload.res.data;
			let newTmp = [];

			if (tmp && tmp.length > 0) {
				newTmp = tmp.map((data) => {
					if (data.shapes?.length > 0) {
						return {
							...data,
							shapes: data.shapes.map((shape) => ({
								...shape,
								uuid: uuidv4(),
							})),
						};
					}
					return data;
				});

				newTmp.map((data) => {
					if (data.shapes?.length > 0) {
						data.slices = data.shapes;
					}
					return data;
				});
			}

			// state.tests = payload.res.data;
			state.tests = newTmp;
			state.totalTests = payload.res.total_count;
		});
		builder.addCase(GET_TESTS.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(GET_TESTING_STATS_AND_METRICS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(
			GET_TESTING_STATS_AND_METRICS.fulfilled,
			(state, { payload }) => {
				state.isLoading = false;
				state.statsAndMetrics = payload;
			}
		);
		builder.addCase(GET_TESTING_STATS_AND_METRICS.rejected, (state) => {
			state.isLoading = false;
		});

		// POST

		builder.addCase(CREATE_TEST.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_TEST.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			console.log(payload);

			state.tests = payload.data;
			state.totalTests = payload.total_count;
		});
		builder.addCase(CREATE_TEST.rejected, (state) => {
			state.isLoading = true;
		});
	},
});

// Action creators are generated for each case reducer function
export const { SET_CURRENT_TEST } = testingSlice.actions;

export default testingSlice.reducer;
