/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Box, FormControl, Grid } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';

import StandardModal from '../../../../../../Components/Shared/StandardModal';
import AnomalyChip from '../../../../../../Components/Shared/AnomalyChip';
import { colors } from '../../../../../../consts/colors';

const SelectTimeSeriesTargetModal = ({
	onClose,
	open,
	selectedAnomaly,
	setSelectedAnomaly,
}) => {
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const [isLoading, setIsLoading] = useState(false);

	const [currentShape, setCurrentShape] = useState({});

	useEffect(() => {
		setSelectedAnomaly(null);
	}, []);

	useEffect(() => {
		if (currentShape !== '') {
			setCurrentShape();
			// currentShapesState.find((shape) => shape.id === selectedShape)
			// setSelectedOutputs(
			// 	anomalyTypesState.map((anomaly) => ({
			// 		element: anomaly.name,
			// 		value:
			// 			currentShapesState
			// 				.find((shape) => shape.id === selectedShape)
			// 				?.outputs?.find((output) => output.element === anomaly.name)
			// 				?.value || 0,
			// 	}))
			// );
		}
	}, [
		// selectedShape,
		currentShape,
	]);

	const handleCancel = () => {
		onClose(false);
		setIsLoading(false);
	};

	const handleOk = () => {
		if (selectedAnomaly && Object.keys(selectedAnomaly).length > 0) {
			setIsLoading(true);
			onClose(false);
		}
	};

	const handleChangeAnomaly = ({ e }) => {
		const { checked, name } = e.target;

		setSelectedAnomaly({ name, value: checked ? 1.0 : 0.0 });
	};

	return (
		<StandardModal
			maxWidth="sm"
			open={open}
			setOpen={onClose}
			title="Select anomaly"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center',
						'& > *': {
							minWidth: '100px',
						},
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<FormControl>
							<RadioGroup
								name="radio-buttons-group"
								onChange={(e) =>
									handleChangeAnomaly({
										e,
									})
								}
							>
								{anomalyTypesState.map((anomaly) => (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<FormControlLabel
											name={anomaly.name}
											value={anomaly.name}
											control={
												<Radio
													checked={selectedAnomaly?.name === anomaly.name}
												/>
											}
										/>
										<AnomalyChip
											tooltip
											name={anomaly.name}
											color={
												selectedAnomaly?.name === anomaly.name &&
												selectedAnomaly?.value > 0
													? anomaly.color
													: colors.ligthGray
											}
										/>
									</div>
								))}
							</RadioGroup>
						</FormControl>
					</Box>
				</Grid>
			}
			actions={
				<>
					<StandardButton handleClick={handleOk} value="Create" />
					<StandardButton close handleClick={handleCancel} value="Cancel" />
				</>
			}
		/>
	);
};

SelectTimeSeriesTargetModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedAnomaly: PropTypes.any,
	setSelectedAnomaly: PropTypes.func,
};

export default SelectTimeSeriesTargetModal;
