import { Box, Checkbox, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '../../../../../consts/colors';
import AnomalyChip from '../../../../../Components/Shared/AnomalyChip';
// import { UPDATE_EXAMPLE } from '../../../../../redux/examples.slice';

export const AnomalyEditor = ({
	currentValue,
	setCurrentValue,
	disabled = false,
}) => {
	const { schema: schemaState } = useSelector((state) => state);
	const { currentExample: currentExampleState } = useSelector(
		(state) => state.examples
	);

	useEffect(() => {
		if (currentExampleState) setCurrentValue(currentExampleState.outputs);
		else setCurrentValue([]);
	}, [currentExampleState]);

	const handleChange = ({ e }) => {
		const { checked, name } = e.target;

		if (currentValue?.length === 0) {
			setCurrentValue([{ element: name, value: checked ? 1 : 0 }]);
		} else {
			setCurrentValue(
				currentValue.map((value) => {
					if (value.element === name)
						return { element: name, value: checked ? 1 : 0 };
					return value;
				})
			);
		}

		console.log(
			currentValue.map((value) => {
				if (value.element === name)
					return { element: name, value: checked ? 1 : 0 };
				return value;
			})
		);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				'& > *': {
					minWidth: '100px',
				},
			}}
		>
			{schemaState.anomaly_types?.map((anomaly) => (
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<FormControl>
						<Checkbox
							id="Anomaly"
							name={anomaly.name}
							disabled={disabled}
							color="primary"
							checked={
								currentValue?.length > 0 &&
								currentValue.find(
									(value) => value.element === anomaly.name && value.value === 1
								)
							}
							onChange={(e) =>
								handleChange({
									e,
								})
							}
						/>
					</FormControl>
					<AnomalyChip
						tooltip
						name={anomaly.name}
						color={
							currentValue?.length > 0 &&
							currentValue.find(
								(value) => value.element === anomaly.name && value.value === 1
							)
								? anomaly.color
								: colors.ligthGray
						}
					/>
				</Box>
			))}
		</Box>
	);
};

AnomalyEditor.propTypes = {
	currentValue: PropTypes.any,
	setCurrentValue: PropTypes.func,
	disabled: PropTypes.bool,
};
