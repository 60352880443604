import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestFactory from '../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from './alerts.slice';
import { SCHEMA_NODE_DELETED } from '../AlertsList/projectSettingsAlerts';

// GET

export const GET_INPUTS = createAsyncThunk(
	'schema/GET_INPUTS',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_INPUTS');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/inputs`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_ANOMALIES = createAsyncThunk(
	'schema/GET_ANOMALIES',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_ANOMALIES');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/anomaly-types`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_METADATA = createAsyncThunk(
	'schema/GET_METADATA',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_METADATA');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/metadata-fields`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_INPUT_CATEGORIES = createAsyncThunk(
	'schema/GET_INPUT_CATEGORIES',
	async ({ accessToken, taskId, inputId, inputName, page = 1, dispatch }) => {
		console.log('schema/GET_INPUT_CATEGORIES');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/inputs/${inputId}/categories?page=${page}&per_page=4&total_count=true`,
			accessToken,
			dispatch,
		});

		if (res) return { inputId, inputName, res };
	}
);

// POST

export const CREATE_SCHEMA_ELEMENT = createAsyncThunk(
	'schema/CREATE_SCHEMA_ELEMENT',
	async ({ element, taskId, elementType, accessToken, dispatch }) => {
		console.log('schema/CREATE_SCHEMA_ELEMENT');

		let tmpElementType = elementType;

		if (elementType === 'anomaly_types') tmpElementType = 'anomaly-types';
		if (elementType === 'metadata_fields') tmpElementType = 'metadata-fields';

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/${tmpElementType}`,
			data: element,
			accessToken,
			dispatch,
		});

		if (res) {
			return {
				res,
				elementType,
			};
		}
	}
);

export const CREATE_INPUT_CATEGORY = createAsyncThunk(
	'schema/CREATE_INPUT_CATEGORY',
	async ({ taskId, elementId, category, accessToken, dispatch }) => {
		console.log('schema/CREATE_INPUT_CATEGORY');
		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/inputs/${elementId}/categories`,
			data: category,
			accessToken,
			dispatch,
		});

		return { elementId, res };
	}
);

// DELETE

export const DELETE_SCHEMA_ELEMENT = createAsyncThunk(
	'schema/DELETE_SCHEMA_ELEMENT',
	async ({
		elementType,
		elementId,
		taskId,
		dispatch,
		accessToken,
		schemaState,
	}) => {
		console.log('schema/DELETE_SCHEMA_ELEMENT');

		let tmpElementType = elementType;

		if (elementType === 'anomaly_types') tmpElementType = 'anomaly-types';
		if (elementType === 'metadata_fields') tmpElementType = 'metadata-fields';

		requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/${tmpElementType}/${elementId}`,
			accessToken,
			dispatch,
		});

		return {
			elementId,
			elementType: tmpElementType,
			schemaState,
		};
	}
);

export const DELETE_INPUT_CATEGORY = createAsyncThunk(
	'schema/DELETE_INPUT_CATEGORY',
	async ({ elementId, taskId, categoryId, dispatch, accessToken }) => {
		console.log('schema/DELETE_INPUT_CATEGORY');
		requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/inputs/${elementId}/categories/${categoryId}`,
			accessToken,
			dispatch,
		});

		return {
			elementId,
			categoryId,
		};
	}
);

export const schemaSlice = createSlice({
	name: 'schema',
	initialState: {
		isLoading: true,
		inputs: [],
		anomaly_types: [],
		metadata_fields: [],
		categories: [],
	},
	reducers: {
		RESET_ALL_CATEGORIES: (state) => {
			console.log('schema/RESET_CATEGORIES');
			state.categories = [];
		},
		DELETE_CATEGORY: (state, { payload }) => {
			console.log('schema/DELETE_CATEGORY');
			state.categories = state.categories.map((category) => {
				if (category.id === payload.elementId) {
					return {
						...category,
						categories: category.categories.filter(
							(cat) => cat.uuid !== payload.categoryId
						),
					};
				}
				return category;
			});
		},
	},
	extraReducers: {
		// GET

		[GET_INPUTS.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_INPUTS.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.inputs = payload;
		},
		[GET_INPUTS.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_ANOMALIES.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_ANOMALIES.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.anomaly_types = payload;
		},
		[GET_ANOMALIES.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_METADATA.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_METADATA.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.metadata_fields = payload;
		},
		[GET_METADATA.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_INPUT_CATEGORIES.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_INPUT_CATEGORIES.fulfilled]: (state, { payload }) => {
			state.isLoading = false;

			if (state.categories?.length > 0) {
				if (
					state.categories.find((category) => category.id === payload.inputId)
				) {
					state.categories = state.categories.map((category) => {
						if (category.id === payload.inputId)
							return {
								id: payload.inputId,
								name: payload.inputName,
								total_count: payload.res.total_count,
								categories: payload.res.data,
							};
						return category;
					});
				} else {
					state.categories = [
						...state.categories,
						{
							id: payload.inputId,
							name: payload.inputName,
							total_count: payload.res.total_count,
							categories: payload.res.data,
						},
					];
				}
			} else {
				state.categories = [
					{
						id: payload.inputId,
						name: payload.inputName,
						total_count: payload.res.total_count,
						categories: payload.res.data,
					},
				];
			}
		},
		[GET_INPUT_CATEGORIES.rejected]: (state) => {
			state.isLoading = false;
		},

		// POST

		[CREATE_SCHEMA_ELEMENT.pending]: (state) => {
			state.isLoading = true;
		},
		[CREATE_SCHEMA_ELEMENT.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.res.type === 'category') {
				state.categories = [
					...state.categories,
					{ id: payload.res.id, name: payload.res.name, categories: [] },
				];
			}

			if (payload && state.elementType)
				state[payload.elementType] = state[payload.elementType].concat(
					payload.res
				);
			else state[payload.elementType] = [payload.res];
		},
		[CREATE_SCHEMA_ELEMENT.rejected]: (state) => {
			state.isLoading = false;
		},

		[CREATE_INPUT_CATEGORY.pending]: (state) => {
			state.isLoading = true;
		},
		[CREATE_INPUT_CATEGORY.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const tmpCat = state.categories.find(
				(cat) => cat.id === payload.elementId
			);
			let newCategories;
			if (tmpCat)
				newCategories = state.categories.map((cat) => {
					const newCat = cat;
					if (cat.id === payload.elementId) {
						newCat.total_count += 1;
						newCat.categories = [...newCat.categories, payload.res];
						return newCat;
					}
					return cat;
				});
			// else
			//   newCategories = [...state.categories[payload.nodeType].map((cat) => {
			// 	const newCat = cat;
			// 	if (cat.id === payload.nodeId) {
			// 		newCat.total_count += 1;
			// 		newCat.categories = [...newCat.categories, payload.res];
			// 		return newCat;
			// 	}
			// 	return cat;
			// });

			state.categories = newCategories;
		},
		[CREATE_INPUT_CATEGORY.rejected]: (state) => {
			state.isLoading = false;
		},

		// DELETE

		[DELETE_SCHEMA_ELEMENT.pending]: (state) => {
			state.isLoading = true;
		},
		[DELETE_SCHEMA_ELEMENT.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const tmpInputs = payload.schemaState.inputs.filter(
				(anomaly) => anomaly.uuid !== payload.elementId
			);
			const tmpAnomalies = payload.schemaState.anomaly_types.filter(
				(anomaly) => anomaly.uuid !== payload.elementId
			);
			const tmpMetadata = payload.schemaState.metadata_fields.filter(
				(anomaly) => anomaly.uuid !== payload.elementId
			);

			if (payload.elementType === 'inputs') {
				state.inputs = tmpInputs;
			}
			if (payload.elementType === 'anomaly-types') {
				state.anomaly_types = tmpAnomalies;
			}
			if (payload.elementType === 'metadata-fields') {
				state.metadata_fields = tmpMetadata;
			}
		},
		[DELETE_SCHEMA_ELEMENT.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export const { DELETE_CATEGORY, RESET_ALL_CATEGORIES } = schemaSlice.actions;

export default schemaSlice.reducer;
