import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import StandardBarChart from '../../../../../../Components/Shared/Charts/StandardBarChart';

// Styles

// Consts
import StandardPieChart from '../../../../../../Components/Shared/Charts/StandardPieChart';
import { CustomTooltip } from '../../../../../../Components/Shared/CustomTooltip';

export const MetricsModal = ({ open, setOpen }) => {
	const { stats: statsState } = useSelector((state) => state.predictions);
	const { anomaly_types: anomalyTypesState } = useSelector(
		(state) => state.schema
	);

	const handleClose = () => {
		setOpen(!open);
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Stats:"
			content={
				<Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						alignItems: 'start',
						padding: '0 !important',
						margin: '0 !important',
						gap: 12,
					}}
				>
					{statsState.num_predictions === 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							Create some predictions first to get stats from them.
						</Grid>
					)}
					{statsState.num_predictions !== 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<h3
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<span style={{ paddingRight: '6px' }}>
										Anomaly Detection Percentage
									</span>
									<CustomTooltip title="Accumulated anomaly vs. normal detection rates from ongoing inferences.">
										<HelpIcon
											sx={{
												transform: 'scale(1) !important',
											}}
										/>
									</CustomTooltip>
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<StandardPieChart
									data={[
										{
											name: 'Without anomalies',
											value:
												statsState.num_predictions - statsState.num_anomalous,
										},
										{
											name: 'With anomalies',
											value: statsState.num_anomalous,
										},
									]}
								/>
							</Grid>
						</Grid>
					)}
					{statsState?.num_anomalies?.length > 0 && (
						<Grid
							container
							sx={{
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<span style={{ paddingRight: '6px' }}>Anomalies by Type</span>
								<CustomTooltip title="Accumulated counts of each anomaly type detected in inferences, where each bar represents the count of a specific anomaly type.">
									<HelpIcon
										sx={{
											transform: 'scale(1) !important',
										}}
									/>
								</CustomTooltip>
							</h3>
							<Grid
								container
								sx={{
									height: '200px',
								}}
							>
								<StandardBarChart
									data={anomalyTypesState.map((anomaly) => ({
										name: anomaly.name,
										value:
											statsState.num_anomalies.find(
												(anom) => anom.anomaly_type === anomaly.uuid
											)?.count || 0,
									}))}
									anomalyColor={anomalyTypesState.map(
										(anomaly) => anomaly.color
									)}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			}
			actions={<StandardButton value="Close" handleClick={handleClose} close />}
		/>
	);
};

MetricsModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};
