import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// MUI Pro License
import { LicenseInfo } from '@mui/x-license-pro';

// Auth provider
// import { Auth0Provider } from '@auth0/auth0-react';
import { NeuAuthProvider } from '@neuraptic/neuauth-react';

// Roles & permission management
import { PermifyProvider } from '@permify/react-role';

// Redux
import store from './redux/store';

// Pages
import App from './App';

// Styles
import './consts/colors.css';
import './index.css';

// Other
import reportWebVitals from './reportWebVitals';
import { LoaderStateManager } from './services/hoc/LoaderStateManager';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<NeuAuthProvider
				domain={process.env.REACT_APP_AUTH_DOMAIN}
				clientId={process.env.REACT_APP_ENAIA_CLIENT_ID}
				redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
				scope={process.env.REACT_APP_AUTH_SCOPE}
				audience={process.env.REACT_APP_AUTH_AUDIENCE}
				redirectUriOnLogin={`${window.location.origin}/dashboard`}
				redirectUriOnLogout={`${window.location.origin}/signin`}
			>
				{/* <Auth0Provider
					domain={process.env.REACT_APP_AUTH0_DOMAIN}
					clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
					redirectUri={`${window.location.origin}`}
					audience={process.env.REACT_APP_AUTH0_AUDIENCE}
					cacheLocation="memory"
				> */}
				<PermifyProvider>
					<LoaderStateManager>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							{/* <React.StrictMode> */}
							<App />
							{/* </Auth0Provider> */}
							{/* </React.StrictMode> */}
						</LocalizationProvider>
					</LoaderStateManager>
				</PermifyProvider>
			</NeuAuthProvider>
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
