import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { styles } from './styles';

const Section = ({ title, children }) => (
	<div style={styles.sectionContainer}>
		<div style={styles.sectionTitle}>{title}</div>
		<div style={styles.sectionContent}>{children}</div>
	</div>
);

Section.propTypes = {
	children: PropTypes.object,
	title: PropTypes.any,
};

export default Section;
