import { configureStore } from '@reduxjs/toolkit';

// Reducers
import userReducer from './user.slice';
import alertsReducer from './alerts.slice';
import organizationReducer from './organization.slice';
import tasksReducer from './tasks.slice';
import loadersReducer from './loaders.slice';
import schemaReducer from './schema.slice';
import queryReducer from './queries.slice';
import examplesReducer from './examples.slice';
import aireleasesReducer from './aireleases.slice';
import testingReducer from './testing.slice';
import predictionsReducer from './predictions.slice';

export default configureStore({
	reducer: {
		loaders: loadersReducer,
		alerts: alertsReducer,
		query: queryReducer,
		user: userReducer,
		organization: organizationReducer,
		tasks: tasksReducer,
		schema: schemaReducer,
		examples: examplesReducer,
		aireleases: aireleasesReducer,
		tests: testingReducer,
		predictions: predictionsReducer,
	},
});
