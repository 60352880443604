import { colors } from '../../../../consts/colors';

export const styles = {
	tableContainer: {
		height: '500px',
	},
	editTable: {
		width: '100%',
		display: 'flex',
	},
	examplesFound: {
		width: '70%',
		display: 'flex',
		gap: 12,
		alignItems: 'center',
		justifyContent: 'left',
	},
	examplesFoundTitle: {
		p: '6px',
		textAlign: 'center',
		borderRadius: '4px',
		fontSize: '13px',
		fontWeight: 600,
		letterSpacing: '0.14px',
		color: '#1492E6',
	},
	examplesFoundCount: {
		letterSpacing: '0.14px',
		color: '#545454',
		opacity: '0.52',
		fontSize: '13px',
		fontWeight: 600,
	},
	tableButtons: {
		width: '30%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'right',
		gap: 12,
	},
	rowCell: {
		display: 'block',
		letterSpacing: '0.13px',
		fontSize: '13px !important',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	// Custom cells
	GenericFile: {},
	ImageFile: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *:first-child': {
			width: '100%',
			height: '100%',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		},
	},

	searchContainer: {
		position: 'relative',
		width: '100%',
	},
	searchIconWrapper: {
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '12px',
	},
	styledInputBase: {
		color: 'inherit',
		width: '100%',
		padding: '12px',
		paddingLeft: '36px',
		border: `1px solid ${colors.gray}`,
		backgroundColor: colors.ligthGray,
	},
	highlight: {
		backgroundColor: '#ecc85c',
	},
};
