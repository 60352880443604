/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

// Styles
import { styles } from './styles';

// Consts
import { colors } from '../../../consts/colors';
import { measures } from '../../../consts/sizes';

// Services
import { getLocalDateTime } from '../../../services/date';
import requestFactory from '../../../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from '../../../redux/alerts.slice';

function PageTitle({ title, userId, projectId, organizationId, task, tabs }) {
	const dispatch = useDispatch();

	const { accessToken } = useSelector((state) => state.user);
	const { info: currentOrganizationInfo } = useSelector(
		(state) => state.organization
	);
	const { displayedUsers: organizationUsersState } = useSelector(
		(state) => state.organization.users
	);
	const { apps: organizationAppsState } = useSelector(
		(state) => state.organization
	);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { location: userLocationState } = useSelector((state) => state.user);

	const [modifiedByInfo, setModifiedByInfo] = useState({});

	const getModifiedByInfo = async () => {
		if (
			currentTaskState.modified_by &&
			Object.keys(currentTaskState.modified_by).length > 0
		) {
			if (currentTaskState.modified_by.type === 'user') {
				const user = await requestFactory({
					type: 'GET',
					url: `/organizations/${currentOrganizationInfo.id}/users/${currentTaskState.modified_by.id}`,
					accessToken,
					dispatch,
				});
				if (user) setModifiedByInfo(user);
			} else {
				const app = await requestFactory({
					type: 'GET',
					url: `/organizations/${currentOrganizationInfo.id}/apps/${currentTaskState.modified_by.id}/api-key`,
					accessToken,
					dispatch,
				});
				if (app) setModifiedByInfo(app);
			}
		}
	};

	useEffect(() => {
		getModifiedByInfo();
	}, [currentTaskState]);

	const handleCopyTaskId = (uuid) => {
		navigator.clipboard.writeText(uuid);
		dispatch(ADD_ALERT({ type: 'success', message: 'Task UUID copied' }));
		setTimeout(() => {
			dispatch(REMOVE_ALERT('Task UUID copied'));
		}, 2000);
	};

	return (
		<Grid
			item
			xs={12}
			sm={12}
			md={12}
			sx={{
				px: {
					xs: 1,
					sm: 4,
					md: measures.mdGeneralMargin,
					lg: measures.lgGeneralMargin,
				},
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: colors.ligtherGray,
				boxShadow: `0px 2px 10px 2px ${colors.gray}, 0px -2px 10px 2px ${colors.gray}`,
			}}
		>
			<Grid container>
				<Grid item xs={12} sx={styles.pageTitleContainer}>
					{title}
				</Grid>
				{userId && <div style={styles.title}>User ID: {userId}</div>}
				{projectId && <div style={styles.title}>Project ID: {projectId}</div>}
				{organizationId && (
					<div style={styles.title}>Organization ID: {organizationId}</div>
				)}
				{task && currentTaskState.modified_at && (
					<Grid item xs={12}>
						<div style={styles.titleTaskContainer}>
							<div style={styles.titleTaskId}>
								Task ID:
								{Object.keys(currentTaskState).length > 0 ? (
									<>
										{` ${task.uuid}`}
										<IconButton
											sx={{ '& > *': { fontSize: '1rem' } }}
											edge="end"
											aria-label="copy api key"
											onClick={() => handleCopyTaskId(task.uuid)}
										>
											<ContentCopyOutlinedIcon />
										</IconButton>
									</>
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '24px',
											width: '200px',
											marginLeft: '5px',
										}}
									/>
								)}
							</div>
						</div>
						<div
							style={{
								...styles.titleCreatedAt,
								fontSize: 'small',
							}}
						>
							<div style={styles.titleCreatedAt}>
								<AccessTimeOutlinedIcon
									fontSize="x-small"
									sx={{ margin: '0 5px' }}
								/>
								Created at:
								{Object.keys(currentTaskState).length > 0 ? (
									`  ${getLocalDateTime(task.created_at, userLocationState)}`
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '24px',
											width: '200px',
											marginLeft: '5px',
										}}
									/>
								)}
							</div>
							<QueryBuilderOutlinedIcon
								fontSize="x-small"
								sx={{ margin: '0 5px' }}
							/>
							{currentTaskState && currentTaskState.modified_by ? (
								Object.keys(currentTaskState).length > 0 &&
								modifiedByInfo &&
								Object.keys(modifiedByInfo).length > 0 ? (
									`Last modification by ${
										currentTaskState.modified_by.type === 'user'
											? modifiedByInfo.first_name
											: `APP`
										// todo: add app name
									} ${
										currentTaskState.modified_by.type === 'user'
											? modifiedByInfo.last_name
											: ''
									} at ${getLocalDateTime(
										currentTaskState.modified_at,
										userLocationState
									)}`
								) : Object.keys(modifiedByInfo).length === 0 ? (
									`Last modification at ${getLocalDateTime(
										currentTaskState.modified_at,
										userLocationState
									)}`
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '24px',
											width: '250px',
										}}
									/>
								)
							) : (
								`Last modification at ${getLocalDateTime(
									currentTaskState.modified_at,
									userLocationState
								)}`
							)}
						</div>
					</Grid>
				)}
			</Grid>
			{tabs && (
				<div style={styles.tabsContainer}>
					{tabs.tabsArray.map((element) =>
						element.comingSoon ? (
							<Tooltip key={uuidv4()} title="Coming soon">
								<button
									type="button"
									style={
										tabs.currentTab === element.linkTo
											? {
													color: element.comingSoon && '#afafaf',
													...styles.tabElement,
													...styles.current,
											  }
											: {
													color: element.comingSoon && '#afafaf',
													...styles.tabElement,
											  }
									}
								>
									{element.label}
								</button>
							</Tooltip>
						) : (
							<button
								key={uuidv4()}
								type="button"
								onClick={() => tabs.setCurrentTab(element.linkTo)}
								style={
									tabs.currentTab === element.linkTo
										? { ...styles.tabElement, ...styles.current }
										: styles.tabElement
								}
							>
								{element.label}
							</button>
						)
					)}
				</div>
			)}
		</Grid>
	);
}

PageTitle.propTypes = {
	title: PropTypes.string,
	userId: PropTypes.string,
	projectId: PropTypes.string,
	organizationId: PropTypes.string,
	task: PropTypes.object,
	tabs: PropTypes.object,
};

export default PageTitle;
